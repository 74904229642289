//앱 설비제어
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../../component/Layout"
import { createTheme, makeStyles, ThemeProvider, withStyles } from "@material-ui/core/styles"
import { Dialog, Button } from "@material-ui/core"
import { ArrowBackIos, Stop, ArrowForwardIos } from "@material-ui/icons"

import { withTranslation } from "react-i18next"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"
import Radio from "../../component/common/Radio"
import InputBox from "../../component/common/InputBox"
import Switch from "../../component/common/Switch"
import EquipContainer from "../../../containers/EquipContainer"
import Footer from "../mainMenu/Footer"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" // 로딩화면
import CircularIndeterminateSecond from "../../component/common/CircularIndeterminateSecond" // 로딩화면2

import CommonContainer from "../../../containers/CommonContainer"

import { SkyButton } from "../../component/common/Button"
// import {BallTriangle} from "react-loader-spinner";

const EquipCtrl = (props) => {
  const {
    t,
    getEquipControlList,
    equipControlList,
    equipOnOffControlList,
    postEquipControlAutoAMod,
    postEquipSettingMod,
    getWorkCenterList,
    workCenterList,
    ctrlEquipControlSwitchMod,

    _equipControlStatus,
    getEquipControlStatus,
    initEquipControlStatus,

    // 다국어 관련 - 차건담 2023.04.03
    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
    
    // 선택한 작업장 정보 값 유지 관련 - 차건담 2023.06.29
    keepWorkCenter,
    _selectedWorkCenter,
    
  } = props

  const [tabIndex, setTabIndex] = useState(0) //workcenterseq
  const [settingChange, setSettingChange] = useState(false) //변경클릭시 화면전환을 위한 useState
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const [allChangeModeLoading, setAllChangeModeLoading] = useState(true) // 자동수동-로딩중

  const [farmSeqGG, setFarmSeqGG] = useState(0) //임시 곤충농장 ALL자동 수동버튼 보이게하는 변수

  // 1018. 작동방식 / 10180001 On/Off , 10180002 정/역
  // 1020. 제어구분 / 1 자동 2 수동

  let instCycle = null

  useEffect(() => {

    getData()
    getTranslationWords() // 다국어 가져오는 함수 - 차건담 2023.04.03
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/main", { replace: true })
    })
    setLoading2(true) //작업장 변경시 로딩중
    if( instCycle === null ) instCycle = setInterval(getMainDataListCycle, 3000); 

    return async () => { clearInterval(instCycle); }

  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.03

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    // setLoading2(true) //작업장 변경시 로딩중
    if( instCycle === null ) instCycle = setInterval(getMainDataListCycle, 3000); 

    return async () => { clearInterval(instCycle); }

  }, [tabIndex])

  useEffect(() => {
    
    if ( workCenterList.length > 0) {
      //setTabIndex( workCenterList[0].workCenterSeq );
      setTabIndex(_selectedWorkCenter)
      getMainDataList( workCenterList[0].workCenterSeq );

      // console.log("@@@@@@@@@@@@@@@@@@@workCenterList[0].farmSeq", workCenterList[0].farmSeq)
      // console.log("@@@@@@@@@@@@@@@@@@@workCenterList", workCenterList)
      setFarmSeqGG(workCenterList[0].farmSeq)
    }
    
  }, [workCenterList])

  useEffect(() => {
    if(_selectedWorkCenter != '' && _selectedWorkCenter != null && _selectedWorkCenter != undefined){
      getMainDataList( _selectedWorkCenter );
      setTabIndex( _selectedWorkCenter );
    }
  },[_selectedWorkCenter])

  const getData = async () => {

    // const result = 

    await getWorkCenterList();

    // if (result && workCenterList.length > 0) {
    //   setTabIndex(workCenterList[0].workCenterSeq)
    // }

  }

  const getMainDataListCycle = () => {

    console.log('[main] data loading...',tabIndex)

    // if( workCenterSeq > 0 ) 
    // setSettingChange(true)
    getMainDataList(tabIndex)
    setLoading2(false) // 작업장 변경시 로딩
  }

  const getMainDataList = async (workCenterSeq) => {
    
    await getEquipControlList( workCenterSeq ) //{ workCenterSeq } )

  }

  const onClickWorkCenter = async ( value ) => {

    // if (workCenterList.length > 0) {
    //   await getEquipControlList(tabIndex)
    // }
    keepWorkCenter(value)
    // getMainDataList( value );
    // setTabIndex( value );
  }

  // // 자동 일괄 전환버튼 
  // const allpostAutoSett = async (kind) => {
  //   let i = 0;
  
  //   async function runLoop(kind) {
  //     if (i >= kind.length) {
  //       setAllChangeModeLoading(true)
  //       return;
  //     }

  //     setAllChangeModeLoading(false)  
  //     await postEquipControlAutoAMod({
  //       equipSeq: kind[i].equipSeq,
  //       controlKind: 10200001,
  //     });

  //     console.log('변수값 길이', kind.length);
  //     console.log('변수값', kind[i].equipSeq);
  //     i++; // 인덱스 증가
  
  //     setTimeout(() => {runLoop(kind); // 재귀 호출
  //     }, 2000); // 2초 후에 실행
      
  //   }
    
  //   await runLoop(kind); // 초기 호출

  // }

  // // 수동 일괄 전환버튼
  // const allpostManualSett = async (kind) => {
  //   let v = 0;
  
  //   async function runLoop(kind) {
  //     if (v >= kind.length) {
  //       setAllChangeModeLoading(true)
  //       return;
  //     }

  //     setAllChangeModeLoading(false)  
  //     await postEquipControlAutoAMod({
  //       equipSeq: kind[v].equipSeq,
  //       controlKind: 10200002,
  //     });

  //     console.log('변수값 길이', kind.length);
  //     console.log('변수값', kind[v].equipSeq);
  //     v++; // 인덱스 증가
  
  //     setTimeout(() => {runLoop(kind); // 재귀 호출
  //     }, 2000); // 2초 후에 실행
  //   }
  
  //   await runLoop(kind); // 초기 호출
  // }

  // 자동 일괄 전환버튼 
  const allpostAutoSett = async (kind) => {
      
    await postEquipControlAutoAMod({
      equipSeq: -1, //설비 전체 명령 정지값
      controlKind: 10200001, // 자동
    });

    let equipLength = kind.length // 사용중인 설비 갯수

    // 전환시 화면 로딩 퍼즈
    if (!loading) { //&& result) {
      setLoading(true) // 로딩중
      setTimeout(() => { 
        setLoading(false) // 2초후 로딩 해제
        setSettingChange(true) 
      }, 2000*equipLength)
    }

}

// 수동 일괄 전환버튼 
const allpostManualSett = async (kind) => {
    await postEquipControlAutoAMod({
      equipSeq: -1, //설비 전체 명령 정지값
      controlKind: 10200002, // 수동
    });
    
    let equipLength = kind.length // 사용중인 설비 갯수

    // 전환시 화면 로딩 퍼즈
    if (!loading) { //&& result) {
      setLoading(true) // 로딩중
      setTimeout(() => { 
        setLoading(false) // 2초후 로딩 해제
        setSettingChange(true) 
      }, 2000*equipLength)
    }

}

  // useEffect(() => {
  //   onClickWorkCenter()
  //   setSettingChange(false)
  // }, [tabIndex, settingChange, _equipControlStatus])

  // useEffect(() => {
  //   onClickWorkCenter()
  //   const timer = setInterval(async () => {
  //     if (workCenterList.length > 0) {
  //       await getEquipControlList(tabIndex)
  //     }
  //   }, 120000)
  //   return () => clearInterval(timer)
  // }, [tabIndex])

  // 작업장 슬라이스 기능 파트 start ----------------------------------------------------
  const [startX, setStartX] = useState(null); // 터치 시작점
  const [moveControl, setMoveControl] = useState(0); // 연속적인 함수발동 정지

  const SWIPE_THRESHOLD = 90; // 최소 스와이프 거리
  const windowHeight = window.innerHeight; //화면 총길이

  const topReservedHeight = windowHeight * 0.1; // 상단 10% 영역
  const bottomReservedHeight = windowHeight * 0.15; // 하단 15% 영역

  const minY = topReservedHeight; // 상단 10% 영역
  const maxY = windowHeight - bottomReservedHeight; // 하단 15% 영역

  const handleTouchStart = (event) => { 
    // console.log("@@@@@@@@@startX111::",startX)
    const touchY = event.touches[0].pageY;
  
    if (touchY > minY && touchY < maxY && loading == false ) {
      setStartX(event.touches[0].pageX);
    }
  
    if(loading == true){ //로딩중인 순간 터치시작점 리셋
    setStartX(null);
    }
    
  };

  const handleTouchEnd = (event) => {
    if (startX !== null && loading == false) {
      const endX = event.changedTouches[0].pageX; //터치 종료위치
      const distance = Math.abs(endX - startX);   //터치 시작점 & 종료시점 거리차이

      // console.log("@@@@@@@@@startX222::",startX)
      // console.log("@@@@@@@@@endX2222::"  ,endX)
      // console.log("@@@@@@@@@tabIndex::"  ,tabIndex) //tabIndex == workCenterSeq

      const workCenterListIndex = workCenterList.findIndex(item => item.workCenterSeq === tabIndex); //현재 작업장인덱스
      const maxIndex = workCenterList.length-1; // 작업장 인덱스 최대값
      
      if (distance > SWIPE_THRESHOLD && moveControl === 0 && loading == false) {
        if (startX > endX) {
          // 오른쪽에서 왼쪽으로 스와이프 (swipeCount 증가)
          setMoveControl(1);
          onClickWorkCenter(workCenterList[maxIndex == workCenterListIndex ? 0 : workCenterListIndex+1].workCenterSeq)
        } else {
          // 왼쪽에서 오른쪽으로 스와이프 (swipeCount 감소)
          setMoveControl(1);
          onClickWorkCenter(workCenterList[0 == workCenterListIndex ? maxIndex : workCenterListIndex-1].workCenterSeq)
        }
        // 터치가 끝났으므로 초기화
        setStartX(null);
        setMoveControl(0);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [startX, loading]);
  // 작업장 슬라이스 기능 파트 end ------------------------------------------------------

  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("equipControl")}
    >
      <CircularIndeterminate open={loading} />
      {/* 설비작동 or all변경시 로딩 */}
      <CircularIndeterminateSecond open={loading2} />
      {/* 작업장 변경시 로딩 */}
      
      <Container>
      <FlexBox style={{display: farmSeqGG == 506 || farmSeqGG == 520 ? "flex" : "none"}}>  
      <SkyButton style={{ marginBottom: "10px", marginRight: "10px", pointerEvents: allChangeModeLoading ? "auto" : "none"}} onClick={() => allpostAutoSett(equipControlList.concat(equipOnOffControlList))}>{dictCk("AllAuto")}</SkyButton> 
      <SkyButton style={{ marginBottom: "10px", marginRight: "10px", pointerEvents: allChangeModeLoading ? "auto" : "none"}} onClick={() => allpostManualSett(equipControlList.concat(equipOnOffControlList))}>{dictCk("AllManual")}</SkyButton>
      {/* <div style={{ display: allChangeModeLoading ? "none" : "flex", marginBottom: "10px" , }}> <BallTriangle height={40} width={50} /> </div> */}
      </FlexBox>

        <div style={{ display: "flex" }}>
          <DefaltFont color="#3e7347">{dictCk("forRev")}</DefaltFont>
        </div>
        <CtrlSection>
          {/* 정역파트 start */}
          {equipControlList.map((i, idx) => (
            <div key={idx}>
              <PreRevComponent
                data={i}
                postData={postEquipControlAutoAMod}
                postSettData={postEquipSettingMod}
                t={t}
                setSettingChange={setSettingChange}
                ctrlEquitBtn={ctrlEquipControlSwitchMod}
                loading={loading}
                setLoading={setLoading}
                getStatus={getEquipControlStatus}
                dataStatus={_equipControlStatus}
                initStatus={initEquipControlStatus}
                dict={dict}
                dictCk={dictCk}
                allChangeModeLoading={allChangeModeLoading}
              />
            </div>
          ))}
          {/* 정역파트 end */}
        </CtrlSection>
        
        {/* on/dff파트 start */}
        <div style={{ display: "flex" }}>
          <DefaltFont color="#3e7347">{dictCk("onOff")}</DefaltFont>
        </div>
        <CtrlSection>
          {equipOnOffControlList.map((item, index) => (
            <div key={index}>
              <TurnOnOffComponent
                onOffdata={item}
                postData={postEquipControlAutoAMod}
                postSett={postEquipSettingMod}
                t={t}
                setSettingChange={setSettingChange}
                dict={dict}
                dictCk={dictCk}
                allChangeModeLoading={allChangeModeLoading}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          ))}
        </CtrlSection>
        {/* on/dff파트 end */}  
      </Container>
      <Footer workCenterList={workCenterList} value={tabIndex} setValue={onClickWorkCenter} />
    </Layout>
  )
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(40)}px;
  background-color: #f6f6f6;
`
const CtrlSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: auto; */
  border: "1px solid #c6c6c6";
  border-radius: ${WidthToDP(9)}px;
  padding: ${WidthToDP(32)}px ${WidthToDP(40)}px;
  background-color: #f1f1f1;
  margin-top: ${WidthToDP(16)}px;
  margin-bottom: ${WidthToDP(40)}px;
`
const DefaltFont = styled.p`
  font-size: ${WidthToDP(30)}px;
  line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(45)}px`)};
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  color: ${(props) => (props.color ? props.color : "#555555")};
`
const CustomBtn = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${WidthToDP(100)}px;
  width: ${WidthToDP(280)}px;
  border-radius: ${WidthToDP(4)}px;
  background-color: ${(props) => (props.emty ? "" : "#52935d")};
  border: ${(props) => (props.emty ? "1px solid #3e7347" : "none")};
  color: ${(props) => (props.emty ? "#3e7347" : "#ffffff")};
  font-weight: 600;
  /* margin-right: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
  margin-left: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)}; */
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: `${WidthToDP(10)}px`,
  },
}))

const CustomMUIBtn = styled(Button)`
  background: ${(props) =>
    props.radioIndex == 0 && props.isNotUseOpenPer == 0 ? "none" : props.clickBtn == props.btnType && props.manualUnconditionalStop == 0 ? `linear-gradient(45deg, ${props.color} 30%, ${props.color} 90%)` : `none`};
  border: ${(props) =>
    props.radioIndex == 0 && props.isNotUseOpenPer == 0 ? `1px solid rgba(0, 0, 0, 0.26)` : props.clickBtn == props.btnType && props.manualUnconditionalStop == 0 ? `1px solid ${props.color}` : `1px solid #52935d`};
  color: ${(props) => (props.clickBtn == props.btnType ? `#FFFFFF` : `#52935d`)};
  height: 48;
`

// 정역파트 start ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const PreRevComponent = (props) => {
  const classes = useStyles()
  const { 
    t, data, postData, postSettData, setSettingChange, 
    ctrlEquitBtn, loading, setLoading, getStatus, dataStatus, 
    initStatus, allChangeModeLoading,
    
    // 다국어 관련 - 차건담 2023.04.03
    dict, dictCk
  } = props
  const [inputValue, setInputValue] = useState(0) // 설비 기본설정 정역
  const [inputCurrValue, setInputCurrValue] = useState(0)
  const [radioIndex, setRadioIndex] = useState(0) // 0:자동, 1:수동
  const [clickBtn, setClickBtn] = useState(10190003) // 10190001:열림, 10190002:닫힘, 10190003:정지
  const [onbtn, setOnBtn] = useState(false) //interval 제어

  const [manualUnconditionalStop, setManualUnconditionalStop] = useState(0) // 수동전환시 무조건 정지 후 가동 


  let instCycle = null
  let worktimeCalc = null
  let timer = null
  let dupClickLoading = null

  const stillWorkingEquit = async () => {
    // let result = await getStatus({ equipSeq: data.equipSeq })
    // if (result) setOnBtn(true)
  }

  useEffect(() => {
    if (data) {
      setInputCurrValue(data.openPer)
      setRadioIndex(data.controlKind == 10200001 ? 0 : 1)
      setClickBtn(data.workStatus)
      //화면이 갱신되고 아직 작동중인 설비가 있을 경우
      if (data.workStatus == 10190003 && data.isUpdated == 0) {
        stillWorkingEquit()
      }

      if(data.controlKind == 10200002){
        setManualUnconditionalStop(0) //수동전환시 dis처리 해제
      }

    }
  }, [data])

  useEffect(() => {
    if (onbtn && dataStatus && dataStatus.isUpdated == 1) {
      setOnBtn(false)
    }
  }, [dataStatus])

  useEffect(() => {
    // if (!onbtn && dataStatus && dataStatus.isUpdated == 1 && dataStatus.workStatus == 10190003) {
    //   refresh()
    // } else
    // if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190001) {
    //   worktimeCalc = (data.workTime * (1 - data.openPer) + 10) * 1000
    //   instCycle = setInterval(async () => {
    //     await getStatus({ equipSeq: data.equipSeq })
    //     console.log("status:open, wait stop, request 2second====")
    //   }, 2000)
    // } else if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190002) {
    //   worktimeCalc = (data.workTime * data.openPer + 10) * 1000
    //   instCycle = setInterval(async () => {
    //     await getStatus({ equipSeq: data.equipSeq })
    //     console.log("status:close, wait stop, request 2seconde====")
    //   }, 2000)
    // } else if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190003) {
    //   instCycle = setInterval(async () => {
    //     await getStatus({ equipSeq: data.equipSeq })
    //     console.log("status:wait stop, request 0.5seconde========")
    //   }, 500)
    // }
    // return async () => {
    //   console.log("clear======")
    //   clearTimeout(timer)
    //   clearTimeout(dupClickLoading)
    //   clearInterval(instCycle)
    //   await initStatus()
    // }
  }, [onbtn])

  //설정버튼은 따로 함수로 빼지 않음
  //수동,자동
  const postAutoSett = async (kind) => {
    let result = await postData({
      equipSeq: data.equipSeq,
      controlKind: kind,
    })
    if (result) setRadioIndex(radioIndex == 0 ? 1 : 0)

    if(kind == 10200002){ setManualUnconditionalStop(1) 
    } // 여기서 수동전환 할때 dis처리

    // 전환시 화면 로딩 퍼즈
    if (!loading) { //&& result) {
      setLoading(true) // 로딩중
      setTimeout(() => { 
        setLoading(false) // 2초후 로딩 해제
        setSettingChange(true) 
      }, 2000)
    }

  }

  // 정역정지버튼
  const postCtrlEquit = async (type) => {
    // //중복클릭방지
    // if (onbtn && dataStatus && dataStatus.isUpdated == 0 && data.workStatus == clickBtn) {
    //   setLoading(true)
    //   dupClickLoading = setTimeout(() => {
    //     setLoading(false)
    //   }, 1200)
    // } else {

      let result = false

      if (!loading) { //&& result) {
        setLoading(true)
        setTimeout(() => { 
          setLoading(false) 
          setSettingChange(true) 
        }, 2000)
      }
      
      result = await ctrlEquitBtn({ equipSeq: data.equipSeq, workStatus: type })
      
      if (result) {
        // const result2 = await getStatus({ equipSeq: data.equipSeq })
        // if (result2) {
          setClickBtn(type)
          setOnBtn(true)
        // }
      }

    // }
  }

  return (
  <div style={{ display: "flex", pointerEvents: allChangeModeLoading ? "auto" : "none"}}>
    <div style={{ marginBottom: `${WidthToDP(24)}px` }}>
      <section>
        <DefaltFont>{data.equipName}</DefaltFont>
      </section>
      {/* 정지릴레이 모드 체크시 자동 수정 전환금지 */}
      <section style={{ display: "flex", alignItems: "center", height: `${WidthToDP(103)}px`, width: "100%", pointerEvents: data.isUseStControl == 1 ? "none" : "auto" }}>
        <Radio
          style={{ marginRight: `${WidthToDP(56)}px` }}
          text={dictCk("auto")}
          idx={0}
          check={radioIndex}
          setCheck={() => {
            postAutoSett(10200001)
          }}
        />
        <Radio
          style={{}}
          text={dictCk("manual")}
          idx={1}
          check={radioIndex}
          setCheck={() => {
            postAutoSett(10200002)
          }}
        />
      </section>
    {/* 수동조작모드 이벤트 작동안하게  */}
    {/* <div style={{ display: "flex", pointerEvents: data.isNotUseManual == 1 ? "none" : allChangeModeLoading ? "auto" : "none" }}>   */}
      <div style={{ height: `${WidthToDP(160)}px`, display: "flex", alignItems: "center", pointerEvents: data.isNotUseManual == 1 ? "none" : data.isNotUseOpenPer == 1 && radioIndex == 0 ? "none" : allChangeModeLoading ? "auto" : "none" }}>
        <div style={{ height: `${WidthToDP(90)}px`, display: "flex", width: `${WidthToDP(250)}px`, justifyContent: "space-between" }}>
          <CustomMUIBtn
            clickBtn={clickBtn}
            radioIndex={radioIndex}
            isNotUseOpenPer={data.isNotUseOpenPer}
            btnType={10190002}
            onClick={() => postCtrlEquit(10190002)}
            color="#fa5b59"
            // disabled={radioIndex == 1 ? false : true}
            disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
            manualUnconditionalStop={manualUnconditionalStop}
            className={classes.root}
          >
            {data.isNotUseOpenPer == 1 ? dictCk("난방") : dictCk("close")}
          </CustomMUIBtn>
          <CustomMUIBtn
            clickBtn={clickBtn}
            radioIndex={radioIndex}
            isNotUseOpenPer={data.isNotUseOpenPer}
            btnType={10190003}
            onClick={() => postCtrlEquit(10190003)}
            color="#52935d"
            // disabled={radioIndex == 1 ? false : true}
            disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
            manualUnconditionalStop={manualUnconditionalStop}
            className={classes.root}
          >
            {dictCk("stop")}
          </CustomMUIBtn>
          <CustomMUIBtn
            clickBtn={clickBtn}
            radioIndex={radioIndex}
            isNotUseOpenPer={data.isNotUseOpenPer}
            btnType={10190001}
            onClick={() => postCtrlEquit(10190001)}
            color="#8cccfb"
            // disabled={radioIndex == 1 ? false : true}
            disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
            manualUnconditionalStop={manualUnconditionalStop}
            className={classes.root}
          >
            {data.isNotUseOpenPer == 1 ? dictCk("냉방") : dictCk("open")}
          </CustomMUIBtn>
        </div>
      </div>

      {/* 오픈퍼조작모드 안보이게 - 생산설비 전환설비 OR 정지릴레이모드 체크시  */}
      <div style={{display: data.isNotUseOpenPer == 1 || data.isUseStControl == 1 ? "none" : "flex", pointerEvents: data.isNotUseManual == 1 ? "none" : allChangeModeLoading ? "auto" : "none" }}>
        <section style={{ display: "flex", alignItems: "center", width: `${WidthToDP(262)}px` }}>
          <InputBox
            style={{ width: `${WidthToDP(169)}px`, marginRight: `${WidthToDP(16)}px` }}
            type="number"
            disabled={true}
            value={inputCurrValue}
          />
          <DefaltFont>{dictCk("per")}</DefaltFont>
        </section>

        <section style={{ display: "flex", alignItems: "center" }}>
          <InputBox
            style={{ width: `${WidthToDP(169)}px`, marginRight: `${WidthToDP(16)}px` }}
            type="number"
            maxLength="3"
            disabled={radioIndex == 0 ? true : false}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <DefaltFont>{dictCk("per")}</DefaltFont>
        </section>
      </div>

      <div style={{ display: data.isNotUseOpenPer == 1 ? 'none': 'flex', height: `${WidthToDP(164)}px`, alignItems: "center", pointerEvents: data.isNotUseManual == 1 ? "none" : allChangeModeLoading ? "auto" : "none" }}>
        <CustomBtn
          emty={radioIndex == 0 ? true : false}
          onClick={async () => {
            
            let result = false

            if (radioIndex == 1) {
              // result = await 
              postSettData({
                equipSeq: data.equipSeq,
                workStatus: clickBtn,
                openPer: inputValue,
              })
            }

            //여러번 클릭금지
            if (!loading) {// && result) {
              setLoading(true)
              // timer = 
              setTimeout(() => {
                setLoading(false)
                setSettingChange(true)
                setInputValue(0)
              }, 2000)
            }

            
            
          }}
        >
          {dictCk("settings")}
        </CustomBtn>
      </div>
      </div>
    
    {/* 수동조작막는 div */}
    {/* </div>   */}

  </div>  
  )
}
// 정역파트 end ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ

// on/off파트 start ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const TurnOnOffComponent = (props) => {
  const { t, onOffdata, postData, postSett, allChangeModeLoading, setSettingChange,
          loading, setLoading,
    // 다국어 관련 - 차건담 2023.04.03
    dict, dictCk 
  } = props

  const [OnOffRadio, setOnOffRadio] = useState(0)
  const [OnOffSwitch, SetOnOffSwitch] = useState(false)

  //수동,자동
  const postOnOffSett = async () => {
    let result = false
    if (OnOffRadio == 1) {
      result = await postData({
        equipSeq: onOffdata.equipSeq,
        controlKind: 10200001,
      })
    } else {
      result = await postData({
        equipSeq: onOffdata.equipSeq,
        controlKind: 10200002,
      })
    }
    if (result) setOnOffRadio(OnOffRadio == 1 ? 0 : 1)

    // 전환시 화면 로딩 퍼즈
    if (!loading) { //&& result) {
      setLoading(true) // 로딩중
      setTimeout(() => { 
        setLoading(false) // 2초후 로딩 해제
        setSettingChange(true) 
      }, 2000)
    }
  }

  const postSetting = async () => {
    // true일 때 off, false일 때 on을 보내고 set실행
    await postSett({
      equipSeq: onOffdata.equipSeq,
      workStatus: OnOffSwitch == true ? 10190002 : 10190001,
      openPer: 0,CircularIndeterminate
    })
    SetOnOffSwitch(!OnOffSwitch)
    
    // 전환시 화면 로딩 퍼즈
    if (!loading) { //&& result) {
      setLoading(true) // 로딩중
      setTimeout(() => { 
        setLoading(false) // 2초후 로딩 해제
        setSettingChange(true) 
      }, 1000)
    }
  }

  useEffect(() => {
    if (onOffdata) {
      setOnOffRadio(onOffdata.controlKind == 10200001 ? 0 : 1)
      SetOnOffSwitch(onOffdata.workStatus == 10190001 ? true : false)
    }
  }, [onOffdata])

  return (
    <>
      <section>
        <DefaltFont>{onOffdata.equipName}</DefaltFont>
      </section>
      <section style={{ display: "flex", alignItems: "center", height: `${WidthToDP(123)}px`, width: "100%", pointerEvents: allChangeModeLoading ? "auto" : "none" }}>
        <Radio style={{ marginRight: `${WidthToDP(56)}px` }} text={dictCk("auto")} idx={0} check={OnOffRadio} setCheck={postOnOffSett} />
        <Radio style={{ marginRight: `${WidthToDP(72)}px` }} text={dictCk("manual")} idx={1} check={OnOffRadio} setCheck={postOnOffSett} />
        <div style={{ display: "flex", pointerEvents: onOffdata.controlKind == 10200001 ? "none" : onOffdata.isNotUseManual == 1 ? "none" : allChangeModeLoading ? "auto" : "none" }}>  
        <Switch
          bool={OnOffRadio == 0 ? (onOffdata.workStatus == 10190001 ? true : false) : OnOffSwitch}
          setBool={() => {
            OnOffRadio == 0 ? (onOffdata.workStatus == 10190001 ? true : false) : postSetting()
          }}
        />
        </div>
      </section>
    </>
  )
}
// on/off파트 end ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
//export default CommonContainer(EquipContainer(withTranslation()(EquipCtrl, PreRevComponent, TurnOnOffComponent)))
export default CommonContainer(EquipContainer(withTranslation()(EquipCtrl)))