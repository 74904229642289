import React from "react"
import { useScrollRestoration } from "gatsby"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"

const InfiniteScrollView = (props) => {
  const { style = {}, list = [], listView = [], loadFunction = () => {} } = props
  const scrollRestoration = useScrollRestoration(`scroll-restoration`)

  return (
    <ScrollView id="scrollableDiv" {...scrollRestoration} style={{ ...style }}>
      <InfiniteScroll dataLength={list.length} next={loadFunction} hasMore={true} scrollableTarget="scrollableDiv">
        {listView}
      </InfiniteScroll>
    </ScrollView>
  )
}

const ScrollView = styled.div`
  overflow-y: scroll;
  flex: 1;
`

export default InfiniteScrollView
