import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_CROP_REPORT_LIST,
  SET_CROP_REPORT_INFO,
  SET_CROP_REPORT_SEARCH,
  SET_CROP_HIST_INFO,
  SET_AS_LIST,
  SET_AS_LIST_TOTAL,
  INIT_AS_LIST,
  SET_CROP_REPORT_LIST_TOTAL,
  INIT_CROP_REPORT_LIST,
  INIT_CROP_REPORT_INFO,
} = actions.CropAction

const { SET_SMINOR_COMBO_LIST, SET_UMINOR_COMBO_LIST, SET_CROP_COMBO_LIST, SET_CROPGROWTH_COMBO_LIST, SET_WORKCENTER_COMBO_LIST, SET_STAFF_COMBO_LIST } =
  actions.CommonAction

// default state value
const initialState = {
  cropReportList: [],
  cropReportInfo: {},
  cropReportSearch: [],
  sminorComboList: [],
  uminorComboList: [],
  cropComboList: [],
  staffComboList: [],
  cropGrowthComboList: [],
  workCenterComboList: [],
  cropHistInfo: {},
  asList: [],
  asListTotal: 0,
  cropReportListTotal: 0,
}

// handle action
export default handleActions(
  {
    [SET_CROP_REPORT_LIST]: (state, action) => ({
      ...state,
      cropReportList: [...state.cropReportList, ...action.payload],
    }),
    [SET_CROP_REPORT_INFO]: (state, action) => ({
      ...state,
      cropReportInfo: action.payload,
    }),
    [SET_CROP_REPORT_SEARCH]: (state, action) => ({
      ...state,
      cropReportSearch: action.payload,
    }),
    [SET_SMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      sminorComboList: action.payload,
    }),
    [SET_UMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      uminorComboList: action.payload,
    }),
    [SET_CROP_COMBO_LIST]: (state, action) => ({
      ...state,
      cropComboList: action.payload,
    }),
    // 재배인력 리스트 - 차건담 2023.04.04
    [SET_STAFF_COMBO_LIST]: (state, action) => ({
      ...state,
      staffComboList: action.payload,
    }),
    [SET_CROPGROWTH_COMBO_LIST]: (state, action) => ({
      ...state,
      cropGrowthComboList: action.payload,
    }),
    [SET_WORKCENTER_COMBO_LIST]: (state, action) => ({
      ...state,
      workCenterComboList: action.payload,
    }),
    [SET_CROP_HIST_INFO]: (state, action) => ({
      ...state,
      cropHistInfo: action.payload,
    }),
    [INIT_AS_LIST]: (state, action) => ({
      ...state,
      asList: [],
    }),
    [SET_AS_LIST]: (state, action) => ({
      ...state,
      asList: [...state.asList, ...action.payload],
    }),

    [SET_AS_LIST_TOTAL]: (state, action) => ({
      ...state,
      asListTotal: action.payload,
    }),
    [SET_CROP_REPORT_LIST_TOTAL]: (state, action) => ({
      ...state,
      cropReportListTotal: action.payload,
    }),
    [INIT_CROP_REPORT_LIST]: (state, action) => ({
      ...state,
      cropReportList: [],
    }),
    [INIT_CROP_REPORT_INFO]: (state, action) => ({
      ...state,
      cropReportInfo: {},
    }),
  },
  initialState
)
