// web에 있는 컴포넌트 복사하여 가져옴 - 차건담 2023.07.11
import React from "react"
import styled from "styled-components"

const RequiredMark = () => {
  return <Red>* </Red>
}

const Red = styled.span`
  color: red;
`

export default RequiredMark
