import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_DATA_LIST = "@elefarm/admin/SET_DATA_LIST"

/* action function */
export const setDataList = createAction(SET_DATA_LIST)

export const initDataList = ( actionType ) => async (dispatch) => {

  let data = {}
  data[actionType.id] = actionType.initData

  dispatch(setDataList( data ))

  return true

}

export const getDataList = ( actionType, param, options = {} ) => async (dispatch) => {

  const result = await CommonApis.getApi( actionType.uri, param, options )

  if ( options.responseType === "blob" ) return result

  if (result.code === 0) {

    let data = {}
    data[actionType.id] = result.data.dataList

    dispatch(setDataList( data ))

    return true

  } else return false

}

export const saveDataList = ( actionType, param ) => async (dispatch) => {

  const result = await CommonApis.postApi( actionType.uri, param )

  if (result.code === 0) {

    return true

  } else return false

}

/* action type */
export const SET_SMINOR_COMBO_LIST = "@elefarm/admin/SET_SMINOR_COMBO_LIST"
export const SET_UMINOR_COMBO_LIST = "@elefarm/admin/SET_UMINOR_COMBO_LIST"
export const SET_FARM_COMBO_LIST = "@elefarm/admin/SET_FARM_COMBO_LIST"
export const SET_CROP_COMBO_LIST = "@elefarm/admin/SET_CROP_COMBO_LIST"
export const SET_STAFF_COMBO_LIST = "@elefarm/admin/SET_STAFF_COMBO_LIST"
export const SET_CROPGROWTH_COMBO_LIST = "@elefarm/admin/SET_CROPGROWTH_COMBO_LIST"
export const SET_WORKCENTER_COMBO_LIST = "@elefarm/admin/SET_WORKCENTER_COMBO_LIST"

export const INIT_SELECTED_WORKCENTER = "@elefarm/admin/INIT_SELECTED_WORKCENTER"
export const SET_SELECTED_WORKCENTER = "@elefarm/admin/SET_SELECTED_WORKCENTER"

/* action function */
export const setSMinorComboList = createAction(SET_SMINOR_COMBO_LIST)
export const setUMinorComboList = createAction(SET_UMINOR_COMBO_LIST)
export const setFarmComboList = createAction(SET_FARM_COMBO_LIST)
export const setCropComboList = createAction(SET_CROP_COMBO_LIST)
export const setStaffComboList = createAction(SET_STAFF_COMBO_LIST)
export const setCropGrowthComboList = createAction(SET_CROPGROWTH_COMBO_LIST)
export const setWorkCenterComboList = createAction(SET_WORKCENTER_COMBO_LIST)

export const initSelectedWorkCenter = createAction(INIT_SELECTED_WORKCENTER)
export const setSelectedWorkCenter = createAction(SET_SELECTED_WORKCENTER)

/* action api function */
export const getSMinorComboList = () => async (dispatch) => {
  // 공통코드

  const result = await CommonApis.getComboList({ queryKind: "SMinor" })

  if (result.code === 0) {
    dispatch(setSMinorComboList(result.data.dataList))

    return true
  } else return false
}

export const getUMinorComboList = () => async (dispatch) => {
  // 사용자코드

  const result = await CommonApis.getComboList({ queryKind: "UMinor" })

  if (result.code === 0) {
    dispatch(setUMinorComboList(result.data.dataList))

    return true
  } else return false
}

export const getCropComboList = () => async (dispatch) => {
  // 사용자코드

  const result = await CommonApis.getComboList({ queryKind: "Crop" })

  if (result.code === 0) {
    dispatch(setCropComboList(result.data.dataList))

    return true
  } else return false
}

// 재배인력 리스트 가져오는 함수 - 차건담 2023.04.04
export const getStaffComboList = () => async (dispatch) => {
  // 사용자코드

  const result = await CommonApis.getComboList({ queryKind: "Staff" })

  if (result.code === 0) {
    dispatch(setStaffComboList(result.data.dataList))

    return true
  } else return false
}


export const getCropGrowthComboList = (param) => async (dispatch) => {
  const result = await CommonApis.getComboList({ queryKind: "CropGrowth", cropSeq: param })

  if (result.code === 0) {
    dispatch(setCropGrowthComboList(result.data.dataList))

    return true
  } else return false
}

export const getWorkCenterComboList = (param) => async (dispatch) => {
  const result = await CommonApis.getComboList({ queryKind: "WorkCenter", cropSeq: param })

  if (result.code === 0) {
    dispatch(setWorkCenterComboList(result.data.dataList))

    return true
  } else return false
}
export const getFarmComboList = () => async (dispatch) => {
  // 농장

  const result = await CommonApis.getComboList({ queryKind: "Farm" })

  if (result.code === 0) {
    dispatch(setFarmComboList(result.data.dataList))

    return true
  } else return false
}

// 선택한 작업장 정보 값 유지하기 위한 함수 - 차건담 2023.06.29
export const keepWorkCenter = (param) => async (dispatch) => {
  dispatch(setSelectedWorkCenter(param))
  return true
} 


// 다국어 TWord 초기화 - 차건담 2023.04.03
export const INIT_TRANSLATION_WORDS = "@elefarm/common/INIT_TRANSLATION_WORDS"
export const initTranslationWords = createAction(INIT_TRANSLATION_WORDS)

// 다국어 TWord 가져오기 - 차건담 2023.04.03
  export const SET_TRANSLATION_WORDS = "@elefarm/common/SET_TRANSLATION_WORDS"

  export const setTranslationWords = createAction(SET_TRANSLATION_WORDS)

  export const getTranslationWords = () => async (dispatch) => {
    const result = await CommonApis.getTranslationWords()
    console.log("commonAction result: ", result)
    if (result.code === 0) {    
      dispatch(setTranslationWords(result.data.TranslationWords))
      return true
    } else return false  
  }
// 품목
// 거래처
// 작업장
// 구역
// 프로그램코드
