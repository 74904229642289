import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
// 복합제어 - 목표값 조건변경
export const INIT_CONF_RESETTING_INFO = "@elefarm/envControl/INIT_CONF_RESETTING_INFO"
export const SET_CONF_RESETTING_INFO = "@elefarm/envControl/SET_CONF_RESETTING_INFO"

/* action function */
// 복합제어 - 목표값 조건변경
export const initConfReSetting = createAction(INIT_CONF_RESETTING_INFO)
export const setConfReSetting = createAction(SET_CONF_RESETTING_INFO)

/* action api function */
// 복합제어 목표값 조건변경 - 정보
export const getConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConfEquipReSetting/info", param)
    if (result.code === 0) {
        dispatch(setConfReSetting(result.data.confReSettingInfo))
        return true
    } else return false
}
// 복합제어 목표값 조건변경 - 정보추가
export const addConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.postApi_WEB("/product/envConfEquipReSetting/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 복합제어 목표값 조건변경 - 정보수정
export const editConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.postApi_WEB("/product/envConfEquipReSetting/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}