import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { 
  
  getWeatherInfo, 
  getWorkCenterList,
  getInfoMainList, 
  postSortNoMod,
  
  getInfoSensorDataList, 
  modSensorDataFlag,
  getSensorDataChartList,
  getTodayAlarmInfo,

  SENSOR_DATA_CHART_DAY, 
  SENSOR_DATA_CHART_MONTH

} = actions.MainAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  weatherInfo: state.MainReducer.weatherInfo,
  workCenterList: state.MainReducer.workCenterList,

  infoMainList: state.MainReducer.infoMainList,
  infoSensorDataList: state.MainReducer.infoSensorDataList,
  sensorDataChartList: state.MainReducer.sensorDataChartList,
  todayAlarmInfo: state.MainReducer.todayAlarmInfo,

  userInfo: state.SignReducer.userInfo,
  
  sensorDataChartDay: state.CommonReducer.sensorDataChartDay || SENSOR_DATA_CHART_DAY.initData, SENSOR_DATA_CHART_DAY,
  sensorDataChartMonth: state.CommonReducer.sensorDataChartMonth || SENSOR_DATA_CHART_MONTH.initData, SENSOR_DATA_CHART_MONTH,
  
  // weatherFcst: state.MainReducer.weatherFcst,
  // weatherNcst: state.MainReducer.weatherNcst,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getWeatherInfo: (param) => dispatch(getWeatherInfo(param)),
  getWorkCenterList: (param) => dispatch(getWorkCenterList(param)),
  getInfoMainList: (param) => dispatch(getInfoMainList(param)),
  postSortNoMod: (param) => dispatch(postSortNoMod(param)),

  getInfoSensorDataList: (param) => dispatch(getInfoSensorDataList(param)),
  modSensorDataFlag: (param) => dispatch(modSensorDataFlag(param)),
  getSensorDataChartList: (param) => dispatch(getSensorDataChartList(param)),
  getTodayAlarmInfo: (param) => dispatch(getTodayAlarmInfo(param)),
  
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
