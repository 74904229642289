import React, { useState, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
// import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format"

import { WidthToDP } from "../../../utils/util"

// const defaultStyle = {

//   position: 'relative', width: '220px', height: `${WidthToDP(100)}px`, marginRight: '30px',
// }

const InputBox = (props) => {
  const {
    style = {},
    placeholder = "",
    type = "text",
    id,
    name,
    value = "", //setValue,
    mask = "",
    disabled,
    onChange,
    useComma = true,
    maxLength = 0,
    width = "100%",
  } = props

  const [localValue, setLocalValue] = useState("")

  useEffect(() => {
    setLocalValue("")
  }, [value])

  const onChangeLocalValue = (e) => {
    const localValue = e.target.value
    const value = localValue.replaceAll(" ", "").replaceAll("-", "").replaceAll("/", "").replaceAll(":", "").replaceAll(",", "")

    setLocalValue(localValue)
    onChange({ ...e, target: { ...e.target, value } })
  }

  const onChangeValue = (e) => {
    onChange(e)
  }

  return (
    <>
      {useComma && type === "number" ? (
        <StyledInputBox
          width={width}
          style={{ textAlign: "right", ...style }}
          placeholder={placeholder}
          id={id}
          name={name}
          value={localValue == "" ? value : localValue}
          disabled={disabled}
          onChange={onChangeLocalValue}
          thousandSeparator={true}
          // isAllowed={({ floatValue }) => floatValue <= 10 ** (maxLength === 0 ? 9 : maxLength) - 1}
          maxLength={(maxLength === 0 ? 9 : maxLength)+2}
        />
      ) : mask > "" || type === "number" ? (
        <StyledInputBox
          width={width}
          style={{ ...style }}
          placeholder={placeholder}
          id={id}
          name={name}
          value={localValue == "" ? value : localValue}
          disabled={disabled}
          onChange={onChangeLocalValue}
          format={mask === "" ? "#########" : mask} //"## : ##"
        />
      ) : (
        <StyledInputBoxOrigin
          width={width}
          style={{ ...style }}
          placeholder={placeholder}
          type={type}
          id={id}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChangeValue}
          maxLength={maxLength === 0 ? 20 : maxLength}
        />
      )}
    </>
  )
}

const StyledInputBoxOrigin = styled.input`
  position: relative;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;

  :-ms-input-placeholder {
    text-align: left; /* for IE 10-11 */
  }
  ::-webkit-input-placeholder {
    text-align: left; /* for IE Edge */
  }
`

const StyledInputBox = styled(NumberFormat)`
  position: relative;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;

  :-ms-input-placeholder {
    text-align: left; /* for IE 10-11 */
  }
  ::-webkit-input-placeholder {
    text-align: left; /* for IE Edge */
  }
`

export default InputBox
