import React, { useEffect } from "react"
import { isApp } from "utils/util"
import i18n from '../lang/i18n';
import { LANGUAGE_CODE } from "../config"
import SignContainer from "../containers/SignContainer"

const PrivateRoute = ({ component: Component, location, _isLogin, userInfo, checkSignStatus, ...rest }) => {
  
  useEffect(() => {
    
    const languageCode = userInfo && userInfo.setting ? LANGUAGE_CODE[userInfo.setting.languageSeq] : 'ko'

    i18n.changeLanguage( languageCode )

    window.NativeFCMDataReceive = async (_data) => {
      if (_data.Type === "GetFCMToken") {
        checkSignStatusFunction(_data.LoginData.FCMToken, _data.LoginData.DeviceNo)
      }
    }

    if (!_isLogin) {
      // 로그인 X
      if (isApp) {
        // 앱
        window.callNative(JSON.stringify({ Type: "GetFCMToken", FunctionName: "NativeFCMDataReceive" }))
      } else {
        // 웹
        checkSignStatusFunction("", "WEB")
      }
    }
  }, [])

  // 로그인 체크 & fcm 토큰 수정 api
  const checkSignStatusFunction = async (pushToken, deviceNo) => {
    const params = {}
    params.pushToken = pushToken
    params.deviceNo = deviceNo

    await checkSignStatus(params)
  }

  if (_isLogin) return <Component location={location} {...rest} />
  else return null
}

export default SignContainer(PrivateRoute)
