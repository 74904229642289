import api from "../utils/api"

// 로그인
const postSignIn = async (data) => {
  const result = await api.post(`/signin`, { body: data })
  return result.data
}

// 로그아웃
const postSignOut = async () => {
  const result = await api.post(`/signout`, { body: {} })
  return result.data
}

// 로그인 상태 체크
const checkSignStatus = async (data) => {
  const result = await api.post(`/signin/status`, { body: data })
  return result.data
}

export default {
  postSignIn,
  postSignOut,
  checkSignStatus,
}
