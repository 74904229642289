import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
// import { Dialog } from "@material-ui/core"
// import { Line } from "react-chartjs-2"

import { WidthToDP } from "../../../utils/util"

import SEO from "../../seo"
// import Header from "../../component/Header"
import Layout from "../../component/Layout"

import MainContainer from "../../../containers/MainContainer"
import CommonContainer from "../../../containers/CommonContainer"

// import ChartBox from "../../component/common/ChartBox"
import ChartLineBox from "../../component/common/ChartLineBox"

// import icon_detail from "../../../images/icon-detail.svg"

const SenserData = (props) => {
  const {
    t,
    infoMainList,
    // getInfoSensorDataList, infoSensorDataList,
    workCenterSeq,
    sensorSeq,
    getSensorDataChartList,
    sensorDataChartList,
    
    sensorDataChartDay,
    sensorDataChartMonth,
    SENSOR_DATA_CHART_DAY,
    SENSOR_DATA_CHART_MONTH,

    open,
    close,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

    
  } = props

  const { getDataList } = props

  // const { data, state } = props.location || { data: undefined }

  // const [selectSeq, setSelectSeq] = useState(-1)
  const [hd, setHD] = useState(1)

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  // useEffect(() => {

  //   return

  // }, [])

  useEffect(() => {
    getTranslationWords()
    if (open) getData( workCenterSeq, hd )

    return () => {}
  }, [open])

  const getData = async ( workCenterSeq, timeType ) => {

    // await getSensorDataChartList({ workCenterSeq, timeType })

    if ( timeType === 1 ) {
      
      await getDataList( SENSOR_DATA_CHART_DAY, { workCenterSeq, timeType : 1 })
      getDataList( SENSOR_DATA_CHART_MONTH, { workCenterSeq, timeType : 2 })
    
    } // end if 

    const obj = document.getElementById("chatId" + sensorSeq)

    if (obj) obj.scrollIntoView()

  }

  const onClick = async (hd) => {
    setHD(hd)
    // await getSensorDataChartList({ workCenterSeq, timeType: hd })
    getData( workCenterSeq, hd )
  }

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      isBack={true}
      title={dictCk("sensorData")}
      backHandler={(e) => {
        close(0)
      }}
    >
      <Container>
        <SEO title={dictCk("sensorData")} />
        {infoMainList.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: `${WidthToDP(16)}px ${WidthToDP(33)}px ${WidthToDP(0)}px ${WidthToDP(33)}px`,
            }}
          >
            <div></div>
            <div>
              <span>{hd == 1 ? dictCk('time(24h)') : '30'.concat(dictCk('day'))}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                style={
                  hd == 1 ? { fontSize: `${WidthToDP(26)}px`, fontWeight: "bold", textDecoration: "underline" } : { fontSize: `${WidthToDP(26)}px` }
                }
                onClick={() => onClick(1)}
              >
                H
              </span>
              &nbsp;|&nbsp;
              <span
                style={
                  hd == 2 ? { fontSize: `${WidthToDP(26)}px`, fontWeight: "bold", textDecoration: "underline" } : { fontSize: `${WidthToDP(26)}px` }
                }
                onClick={() => onClick(2)}
              >
                D
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        <div style={{ overflow: "scroll", flex: 1, margin: `${WidthToDP(16)}px ${WidthToDP(33)}px ${WidthToDP(0)}px ${WidthToDP(33)}px` }}>
          {hd == 1 && infoMainList.map((row, idx) => (
            <ChartLineBox
              id={`chatId${row.sensorSeq}`}
              key={idx}
              style={{ marginBottom: `${WidthToDP(20)}px`, height: `${WidthToDP(550)}px` }}
              labels={[]}
              datas={sensorDataChartDay.filter((row2) => row2.sensorSeq == row.sensorSeq)}
            />
          ))}
          {hd == 2 && infoMainList.map((row, idx) => (
            <ChartLineBox
              id={`chatId${row.sensorSeq}`}
              key={idx}
              style={{ marginBottom: `${WidthToDP(20)}px`, height: `${WidthToDP(550)}px` }}
              labels={[]}
              datas={sensorDataChartMonth.filter((row2) => row2.sensorSeq == row.sensorSeq)}
            />
          ))}

        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
`

export default MainContainer(CommonContainer(withTranslation()(SenserData)))
