import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout from "../../component/Layout"
import InfiniteScrollView from "../../component/common/infiniteScrollView"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"

import BasicPopup from "../../popup/BasicPopup"
import AlarmInfoContainer from "../../../containers/AlarmInfoContainer"

import CommonContainer from "../../../containers/CommonContainer"

import DateBox from "../../component/common/DateBox2" // 차건담
import ComboBox from "../../component/common/ComboBox"
import moment from "moment"
import LogContainer from "../../../containers/LogContainer"
import AlertPopup from "../../popup/AlertPopup"

const EquipLogList = (props) => {
  const {
    t,

    // 다국어 관련 추가 - 차건담 2023.04.04
    initTranslationWords,
    getTranslationWords,
    _translationWords,
    replaceWord,

    _logList,
    initLogList,
    getLogList,

    getOpenPerEquipList,
    getOnOffEquipList,
    getSwitchEquipList,

    _openPerEquipList,
    _onOffEquipList,
    _switchEquipList,
    
    initEquipLogList,
    getEquipLogList,
    _equipLogList,
  } = props

  const [click, setClick] = useState("")
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [contentsPopup, setContentsPopup] = useState({ open: false, popupItem: {}, type: "" })
  const count = 50

  const [stdDate, setStdDate] = useState(moment().subtract(1, 'day').format('YYYYMMDD')); // 조회조건 시작 일시 - 차건담
  const [endDate, setEndDate] = useState(moment().subtract(0, 'day').format('YYYYMMDD')); // 조회조건 종료 일시 - 차건담

  const [openPerEquipDropIndex, setOpenPerEquipDropIndex] = useState("")
  const [onOffEquipDropIndex, setOnOffEquipDropIndex] = useState("")
  const [switchEquipDropIndex, setSwitchEquipDropIndex] = useState("")

  const [openPerEquipList, setOpenPerEquipList] = useState([])
  const [onOffEquipList, setOnOffEquipList] = useState([])
  const [switchEquipList, setSwitchEquipList] = useState([])

  const [itemList, setItemList] = useState([])

  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [equipKind, setEquipKind] = useState("")

  const getData = async () => {
    if (_logList == undefined || _logList.length <= 0 || _logList <= 0) {
      await getLogList({ page: 0, count: count, logKind: click==""?7:click, dateFr: stdDate, dateTo: endDate })
    } else {
      await getLogList({ page: Math.floor(_logList.length / count), count: count, logKind: click==""?7:click, dateFr: stdDate, dateTo: endDate })
    }
  }

  const getEquipLog = async () => { // 설비 이력 가져오기
    if(moment(endDate, 'YYYY-MM-DD').diff(moment(stdDate, 'YYYY-MM-DD'), 'days')>=7){
      setOpenPerEquipDropIndex("")
      setOnOffEquipDropIndex("")
      setSwitchEquipDropIndex("")
      return setAlertPopup({
        open:true, text:replaceWord(dictCk("limit the query period to a maximum of n.days"), "7")+" 날짜를 다시 설정해주세요."
      })
    }

    if (itemList == undefined || itemList.length <= 0 || itemList <= 0) {
      await getEquipLogList({ page: 0, count: count, equipSeq: click, equipKind: equipKind, dateFr: stdDate, dateTo: endDate })
    } else {
      await getEquipLogList({ page: Math.floor(itemList.length / count), count: count, equipSeq: click, equipKind: equipKind, dateFr: stdDate, dateTo: endDate })
    }
  }

  useEffect(() => {
    getOpenPerEquipList()
    getOnOffEquipList()
    getSwitchEquipList()

    getTranslationWords()
    return async () => {
      await initEquipLogList()
    }
  }, [])

  useEffect(() => {
    console.log("_openPerEquipList:",_openPerEquipList)
    setOpenPerEquipList(_openPerEquipList)
  }, [_openPerEquipList])

  useEffect(() => {
    console.log("_onOffEquipList:",_onOffEquipList)
    setOnOffEquipList(_onOffEquipList)
  }, [_onOffEquipList])

  useEffect(() => {
    console.log("_switchEquipList:",_switchEquipList)
    setSwitchEquipList(_switchEquipList)
  }, [_switchEquipList])

  useEffect(() => {
    if(openPerEquipDropIndex != ""){
      setOnOffEquipDropIndex("")
      setSwitchEquipDropIndex("")
      setEquipKind("1")
      setClick(openPerEquipDropIndex)
    }
  }, [openPerEquipDropIndex])

  useEffect(() => {
    if(onOffEquipDropIndex != ""){
      setOpenPerEquipDropIndex("")
      setSwitchEquipDropIndex("")
      setEquipKind("2")
      setClick(onOffEquipDropIndex)
    }
  }, [onOffEquipDropIndex])

  useEffect(() => {
    if(switchEquipDropIndex != ""){
      setOpenPerEquipDropIndex("")
      setOnOffEquipDropIndex("")
      setEquipKind("3")
      setClick(switchEquipDropIndex)
    }
  }, [switchEquipDropIndex])

  useEffect(() => {
    console.log("_equipLogList:", _equipLogList)
    setItemList(_equipLogList)
  }, [_equipLogList])

  useEffect(() => {
    setOpenPerEquipDropIndex("")
    setOnOffEquipDropIndex("")
    setSwitchEquipDropIndex("")
    setClick("")
  }, [stdDate, endDate])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.04

  const dictCk = (word) => {
    if (dict == undefined || dict == null || dict == {} || dict == "") {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    if(click != "") getEquipLog()
  }, [click])

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      if (contentsPopup.open) {
        setContentsPopup({ open: false, popupItem: {}, type: "" })
      } else {
        navigate("/app/main", { replace: true })
      }
    })
    //
  }, [contentsPopup])

  const getContents = (items, itemIndex) => {
    if (equipKind == 1) {
      return (
        <section
        style={{ overflowY: "hidden" }}
        onClick={async () => {
          setContentsPopup({ open: true, popupItem: items, type: itemIndex })
        }}
        >
          <p style={{fontSize:"15px"}}>{dictCk("operatingTime")+' '+items.dateTimeFr}</p>
          {/* <p style={{fontSize:"15px"}}>{'정지시간 > '+items.dateTimeTo}</p> */}
        </section>
      )
    } else {
      return (
        <section
        style={{ overflowY: "hidden" }}
        onClick={async () => {
          setContentsPopup({ open: true, popupItem: items, type: itemIndex })
        }}
        >
          <p style={{fontSize:"15px"}}>{dictCk("operatingTime")+' '+items.dateTimeFr}</p>
        </section>
      )
    }
    
  }

  // 전환 설비 콤보박스 리턴하는 함수 - 차건담 2023.10.25
  const getSwitchEquipComboBox = () => {
    if (switchEquipList == [] || switchEquipList.length == 0){}
    else {
      return(
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <p style={{width:"100px"}}>{dictCk('switchingEquip')}</p>
          <ComboBox // 전환 설비
            style={{width:'250px', marginLeft:"15px", marginRight:"0px"}}
            defaultValue={t('select')}
            hasDefault={true}
            items={switchEquipList.map((i) => ({ value: i.equipSeq, label: i.equipName }))}
            value={switchEquipDropIndex}
            setValue={setSwitchEquipDropIndex}
          />
        </div>
      ) 
    }
  }

  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("equipLog")}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dictCk("close")]} />

      <div style={{marginBottom: "60px"}}> {/* */}
        <Text style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "7%", color: "#8a8e8b", marginBottom: "10px", marginTop: "10px" }}>{dictCk('inquiryPeriod')}</Text>
        <div style={{ display:"flex" }}>
          <DateBox
            dateBoxId="dateBoxFrom"
            dateBoxInform={''}
            style={{
              width: "49%",
              height: "12px",
              marginLeft: "6%",
            }}
            minDate={new Date('2023-10-21')}
            stdDate={stdDate}
            setStdDate={setStdDate}
            
          />
          <DateBox
            dateBoxId="dateBoxTo"
            dateBoxInform={''}
            style={{
              width: "49%",
              height: "12px",
              marginLeft: "1%",
              //marginTop: "50px",
            }}
            minDate={new Date('2023-10-21')}
            stdDate={endDate}
            setStdDate={setEndDate}

          />
        </div>
      </div>
      <Container>
        <div style={{marginBottom: "15px"}}>
          <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "3px" }}>
            <p style={{width:"100px"}}>{dictCk("forRevEquip")}</p>
            <ComboBox // 정/역 설비
              style={{width:'250px', marginLeft:"15px", marginRight:"0px"}}
              defaultValue={t('select')}
              hasDefault={true}
              items={openPerEquipList.map((i) => ({ value: i.equipSeq, label: i.equipName }))}
              value={openPerEquipDropIndex}
              setValue={setOpenPerEquipDropIndex}
            />
          </div>
          <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: _switchEquipList.length == 0 ? "0px" : "3px" }}>
            <p style={{width:"100px"}}>{dictCk("onOffEquip")}</p>
            <ComboBox // on/off 설비
              style={{width:'250px', marginLeft:"15px", marginRight:"0px"}}
              defaultValue={t('select')}
              hasDefault={true}
              items={onOffEquipList.map((i) => ({ value: i.equipSeq, label: i.equipName }))}
              value={onOffEquipDropIndex}
              setValue={setOnOffEquipDropIndex}
            />
          </div>
          <div style={{ display: switchEquipList.length == 0 || switchEquipList == [] ? "none":"flex", width: "100%", justifyContent: "center", alignItems: "center",  }}>
            <p style={{width:"100px"}}>{dictCk('switchingEquip')}</p>
            <ComboBox // 전환 설비
              style={{width:'250px', marginLeft:"15px", marginRight:"0px"}}
              defaultValue={t('select')}
              hasDefault={true}
              items={switchEquipList.map((i) => ({ value: i.equipSeq, label: i.equipName }))}
              value={switchEquipDropIndex}
              setValue={setSwitchEquipDropIndex}
            />
          </div>
        </div>

        <InfiniteScrollView
          list={itemList}
          listView={itemList.map((item, idx) => (
            <LogTab
              key={idx}
              items={item}
              //del={handleDel}
              contentsPopup={contentsPopup} 
              setContentsPopup={setContentsPopup}
              itemIndex={idx}
              //typeData={typeData}
              t={t}
              dict={dict}
              //selectTap={selectTap}
              popup={popup}
              setPopup={setPopup}
              //getData={getData}
              getContents={getContents}
              equipKind={equipKind}
              dictCk={dictCk}
            />
          ))}
          loadFunction={() => {
            if (_logList > _logList.length && _logList.length !== 0) {
              getData()
            }
          }}
        />
      </Container>
    </Layout>
  )
}

export default CommonContainer(AlarmInfoContainer(LogContainer(withTranslation()(EquipLogList))))
'equip'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(30)}px ${WidthToDP(36)}px;
  background-color: #f6f6f6;
`

const LogTab = (props) => {
  const { t, items, getContents, equipKind, del, setContentsPopup, itemIndex, selectTap, dict, dictCk } = props

  return (
    <div
      style={{
        height: equipKind == 1 ? `${WidthToDP(180)}px` : `${WidthToDP(180)}px`,
        width: "100%",
        padding: `${WidthToDP(30)}px`,
        marginBottom: `${WidthToDP(24)}px`,
        backgroundColor: `${items.isCfm == 1 ? "#f1f1f1" : "#ffffff"}`,
        borderRadius: `${WidthToDP(9)}px`,
        border: "1px solid #dedede",
      }}
    >
      {/* 제목 */}
      <div
        style={{
          display: "flex",
          height: `${WidthToDP(45)}px`,
          marginBottom: `${WidthToDP(32)}px`,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <section style={{ height: "100%" }}>
            <TapDiv
              color={"#1eb544"}
            >
              <p style={{fontSize:"14px", fontWeight:"bold", color:"#f9f9f9"}}>{items.title}</p>
            </TapDiv>
          </section>
          <section style={{marginTop:"10px"}}>
            <div style={{ height:"30px", width:"80px", backgroundColor:"#ECECEC", borderRadius:"3px",
                          display:"flex", justifyContent:"center", alignItems:"center"}}>
              <p style={{fontSize:"12px", fontWeight:"bolder", color:"#1eb544"}}>{dictCk(items.controlKind)}</p>
              {/* <p style={{fontSize:"12px", fontWeight:"bolder", color:"#1eb544"}}>{items.controlKind}</p> */}
            </div>
          </section>
          <section style={{marginTop:"10px", marginLeft: "5px"}}>
            <div style={{ height:"30px", width:"60px", backgroundColor:"#ECECEC", borderRadius:"3px",
                          display:"flex", justifyContent:"center", alignItems:"center"}}>
              <p style={{fontSize:"12px", fontWeight:"bolder", color:"#1eb544"}}>{dictCk(items.workStatus)}</p>
              {/* <p style={{fontSize:"12px", fontWeight:"bolder", color:"#1eb544"}}>{items.workStatus}</p> */}
            </div>
          </section>
        </div>
      </div>
      {/* 내용 */}
      {getContents(items, itemIndex)}
    </div>
  )
}

const TapDiv = styled.div`
  display: flex;
  height: ${WidthToDP(67)}px;
  width: ${WidthToDP(196)}px;
  justify-content: center;
  align-items: center;
  border-radius: ${WidthToDP(6)}px;
  background-color: ${(props) => (props.color ? props.color : "#022808")};
  margin-right: ${(props) => (props.mr ? "" : `${WidthToDP(24)}px`)};
  margin-bottom: ${WidthToDP(24)}px;
  &:nth-child(4n) {
    margin-right: 0;
  }
`
const ContentFont = styled.p`
  opacity: 0.7;
  font-family: NotoSansCJKkr;
  font-size: ${(props) => (props.fs ? "" : `${WidthToDP(30)}px`)};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
const TapFont = styled.p`
  line-height: ${WidthToDP(37)}px;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
`
const DelFont = styled.p`
  line-height: ${WidthToDP(40)}px;
  opacity: 0.63;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(27)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.54px;
  color: #ff1414;
`
const ReadFont = styled.p`
  line-height: ${WidthToDP(37)}px;
  opacity: 0.5;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #555555;
`

// 차건담
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`