import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#52935d",
    animationDuration: "1000ms",
  },
}));

const CircularIndeterminateSecond = (props) => {
  const classes = useStyles();

  const { open } = props;

  return (
    open && (
      <LoadingContainer>
        <CircularProgress className={classes.root} />
      </LoadingContainer>
    )
  );
};

export default CircularIndeterminateSecond;