import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  initProductionPlanList,
  getProductionPlanMonthList,
  getProductionPlanDayList,
  addProductionPlan,
  initProductionPlan,
  getProductionPlan,
  editProductionPlan,
  delProductionPlan,
  
  initCropGrowth,
  getCropGrowth,
} = actions.ScheduleAction
const { getCropComboList, getWorkCenterComboList } = actions.CommonAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _productionPlanList: state.ScheduleReducer._productionPlanList,
  _productionPlan: state.ScheduleReducer._productionPlan,
  cropComboList: state.CropReducer.cropComboList,
  workCenterComboList: state.CropReducer.workCenterComboList,

  _cropGrowth: state.ScheduleReducer._cropGrowth,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initProductionPlanList: (param) => dispatch(initProductionPlanList(param)),
  getProductionPlanMonthList: (param) => dispatch(getProductionPlanMonthList(param)),
  getProductionPlanDayList: (param) => dispatch(getProductionPlanDayList(param)),
  addProductionPlan: (param) => dispatch(addProductionPlan(param)),
  initProductionPlan: (param) => dispatch(initProductionPlan(param)),
  getProductionPlan: (param) => dispatch(getProductionPlan(param)),
  editProductionPlan: (param) => dispatch(editProductionPlan(param)),
  delProductionPlan: (param) => dispatch(delProductionPlan(param)),
  getCropComboList: (param) => dispatch(getCropComboList(param)),
  getWorkCenterComboList: (param) => dispatch(getWorkCenterComboList(param)),

  initCropGrowth: (param) => dispatch(initCropGrowth(param)),
  getCropGrowth: (param) => dispatch(getCropGrowth(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
