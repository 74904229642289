// 로그관련 - 차건담 2023.10.12
import { createAction } from "redux-actions"
import AlarmInfoApis from "../apis/AlarmInfoApis"
import CommonApis from "../apis/CommonApis"

/* action type */
export const INIT_LOG_LIST = "@elefarm-app/alarmInfo/INIT_LOG_LIST"
export const SET_LOG_LIST = "@elefarm-app/alarmInfo/SET_LOG_LIST"

export const SET_OPENPER_EQUIP_LIST = "@elefarm-app/alarmInfo/SET_OPENPER_EQUIP_LIST"
export const SET_ONOFF_EQUIP_LIST = "@elefarm-app/alarmInfo/SET_ONOFF_EQUIP_LIST"
export const SET_SWITCH_EQUIP_LIST = "@elefarm-app/alarmInfo/SET_SWITCH_EQUIP_LIST"

export const INIT_EQUIP_LOG_LIST = "@elefarm-app/alarmInfo/INIT_EQUIP_LOG_LIST"
export const SET_EQUIP_LOG_LIST = "@elefarm-app/alarmInfo/SET_EQUIP_LOG_LIST"


/* action function */
export const initLogList = createAction(INIT_LOG_LIST)
export const setLogList = createAction(SET_LOG_LIST)

export const setOpenPerEquipList = createAction(SET_OPENPER_EQUIP_LIST)
export const setOnOffEquipList  = createAction(SET_ONOFF_EQUIP_LIST)
export const setSwitchEquipList = createAction(SET_SWITCH_EQUIP_LIST)

export const initEquipLogList = createAction(INIT_EQUIP_LOG_LIST)
export const setEquipLogList = createAction(SET_EQUIP_LOG_LIST)
/* action api function */

// 로그조회리스트
export const getLogList = (param) => async (dispatch) => {
  //const result = await CommonApis.getApi(`/log/list?page=${page}&&count=${count}&&logKind=${logKind == undefined ? null : logKind}`, param)
  const result = await CommonApis.getApi(`/log/list`, param)
  if (result.code == 0) {
    dispatch(setLogList(result.data.dataList))
    return true
  } else return false
}

// 정/역 설비 가져오기 - 2023.10.24
export const getOpenPerEquipList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/log/openPerEquip/list`, param)
  if (result.code == 0) {
    dispatch(setOpenPerEquipList(result.data.dataList))
    return true
  } else return false
}

// on/off 설비 가져오기 - 2023.10.24
export const getOnOffEquipList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/log/onOffEquip/list`, param)
  if (result.code == 0) {
    dispatch(setOnOffEquipList(result.data.dataList))
    return true
  } else return false
}

// 전환 설비 가져오기 - 2023.10.24
export const getSwitchEquipList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/log/switchEquip/list`, param)
  if (result.code == 0) {
    dispatch(setSwitchEquipList(result.data.dataList))
    return true
  } else return false
}

// 설비 작동 이력 가져오기 - 2023.10.24
export const getEquipLogList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/log/equipLog/list`, param)
  if (result.code == 0) {
    dispatch(setEquipLogList(result.data.dataList))
    return true
  } else return false
}