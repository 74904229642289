import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Header from "./Header"

import MainContainer from "../../containers/MainContainer"
import { isApp } from "../../utils/util"

const Layout = (props) => {
  const { children, getTodayAlarmInfo, todayAlarmInfo, userInfo } = props
  // header
  const { isHeader, headerBgType, isBack, backHandler, isHam, title, isAlarm, alarmHandler, alarmCount, isSearch, searchHandler } = props
  const { openSearch, inputValue, setInputValue } = props

  useEffect(() => {
    window.NativeFCMAlarmReceive = async (_data) => {
      getData()

      return "success"
    }

    if(isApp) getData()
    
  }, [])

  const getData = async () => {
    await getTodayAlarmInfo({})
  }

  return (
    <Container>
      <Header
        isHeader={isHeader}
        headerBgType={headerBgType}
        isBack={isBack}
        backHandler={backHandler}
        isHam={isHam}
        title={title || (userInfo ? userInfo.farm.farmName : "")}
        isAlarm={isAlarm}
        alarmHandler={alarmHandler}
        alarmCount={todayAlarmInfo}
        isSearch={isSearch}
        searchHandler={searchHandler}
        openSearch={openSearch}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
      <ChildContainer>{children}</ChildContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const ChildContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

Layout.propTypes = {
  isHeader: PropTypes.bool,
  headerBgType: PropTypes.number,
  isBack: PropTypes.bool,
  backHandler: PropTypes.func,
  isHam: PropTypes.bool,
  title: PropTypes.string,
  isAlarm: PropTypes.bool,
  alarmHandler: PropTypes.func,
  isSearch: PropTypes.bool,
  searchHandler: PropTypes.func,
}

export default MainContainer(Layout)
