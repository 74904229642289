import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import SampleReducer from "./SampleReducer"
import ConfigReducer from "./ConfigReducer"
import SignReducer from "./SignReducer"
import MainReducer from "./MainReducer"
import CropReducer from "./CropReducer"
import EquipReducer from "./EquipReducer"
import SettingReducer from "./SettingReducer"
import AlarmInfoReducer from "./AlarmInfoReducer"
import ScheduleReducer from "./ScheduleReducer"
import CommonReducer from "./CommonReducer"
import ChartReducer from "./ChartReducer"
import LogReducer from "./LogReducer" // 로그 관련 - 차건담 2023.10.12
import EnvControlReducer from "./EnvControlReducer" // 복합제어 - 목표값 조건변경 - 김한중 2023.10.27

export default combineReducers({
  pender: penderReducer,
  SampleReducer,
  ConfigReducer,
  SignReducer,
  MainReducer,
  CropReducer,
  EquipReducer,
  SettingReducer,
  AlarmInfoReducer,
  ScheduleReducer,
  CommonReducer,
  ChartReducer,
  LogReducer, // 로그 관련 - 차건담 2023.10.12
  EnvControlReducer, // 복합제어 - 목표값 조건변경
})
