// 센서데이터 조회에서 사용하기 위한 Tab 컴포넌트 - 차건담
import React from "react"
import { useEffect, useState, useRef } from "react"
import styled from "styled-components"

// Tab을 모바일 가로모드에서 숨기기 위해서 idName을 파라미터로 받아 적용 - 차건담 2023.07.11
const Tab = ({ style = {}, list, tabIndex, onClick, idName }) => {
  // const [tabWidth, setTabWidth] = useState()
  // const [isPortrait, setIsPortrait] = useState()

  // useEffect(()=>{
  //   console.log("window.matchMedia('(orientation: portrait)').matches")
  //   if(window.matchMedia('(orientation: portrait)').matches) {
  //     setIsPortrait(true)
  //   } else {
  //     setIsPortrait(false)
  //   }
  // }, [window.matchMedia('(orientation: portrait)').matches])

  // //let isPortrait = window.matchMedia('(orientation: portrait)').matches

  // useEffect(()=>{
  //   if(isPortrait){setTabWidth('45%')}
  //   else {setTabWidth('40%')}
  // }, [isPortrait])

  // useEffect(()=>{
  //   console.log("tabWidth:", tabWidth)
  // }, [tabWidth])

  const firstTabRef = useRef(null);
  const secondTabRef = useRef(null);

  useEffect(() => {
    const tabItem = document.getElementsByClassName('tabItem');
    const tabContainer = document.getElementById(idName)
  
    function setWidth(event){
      tabContainer.style.visibility = 'hidden'
      tabContainer.style.opacity = '0'

      for(let i=0; i<tabItem.length; i++){
        console.log("i:", i)
        tabItem[i].style.transition = 'width 0s ease'
      }

      setTimeout(() => {
        if(window.matchMedia('(orientation: portrait)').matches){
          for(let i=0; i<tabItem.length; i++){
            console.log("i:", i)
            tabContainer.style.paddingLeft = '0%'
            tabItem[i].style.marginLeft = '3.3%'
            tabContainer.style.visibility = 'visible'
            tabContainer.style.transition = 'opacity 0.7s ease'
            tabContainer.style.opacity = '0.6'
            tabContainer.style.transition = 'opacity 0.4s ease'
            tabContainer.style.opacity = '1'
            tabItem[i].style.transition = 'width 0.6s ease'
            tabItem[i].style.width = '45%'
          }
        } else {
          for(let i=0; i<tabItem.length; i++){
            tabContainer.style.paddingLeft = '10%'
            tabItem[i].style.marginLeft = '3%'
            tabContainer.style.visibility = 'visible'
            tabContainer.style.transition = 'opacity 0.7s ease'
            tabContainer.style.opacity = '0.6'
            tabContainer.style.transition = 'opacity 0.4s ease'
            tabContainer.style.opacity = '1'
            tabItem[i].style.transition = 'width 0.6s ease'
            tabItem[i].style.width = '40%'
          }
        }
      }, 50)

    }
  
    window.addEventListener('orientationchange', setWidth);

    return () => {
      window.removeEventListener('orientationchange', setWidth);
    }
  }, [])


  // useEffect(() => {
  //   let firstTab = firstTabRef.current;
  //   let secondTab = secondTabRef.current;

  //   const tabItem = document.getElementsByClassName('tabItem');

  //   function setWidth(event){
  //     if(window.matchMedia('(orientation: portrait)').matches){
  //       for(let i in tabItem){
  //         tabItem[i].style.width = '45%'
  //       }
  //     } else {
  //       for(let i in tabItem){
  //         tabItem[i].style.width = '20%'
  //       }
  //     }
  //   }

  //   if (firstTab) {
  //     console.log("여기까지 옴?")
  //     firstTab.addEventListener('orientationchange', setWidth);
  //   }
  //   if (secondTab) {
  //     secondTab.addEventListener('orientationchange', setWidth);
  //   }

  //   return () => {
  //     if (firstTab) {
  //       console.log("여기까지 옴?????????????")
  //       firstTab.removeEventListener('orientationchange', setWidth);
  //     }
  //     if (secondTab) {
  //       secondTab.removeEventListener('orientationchange', setWidth);
  //     }
  //   }
  // }, [tabIndex])

  // const checkListNameHeight = () => {
  //   const tabs = document.getElementsByClassName('tabItem')

  //   for(let i in tabs) {
  //     if(String(list[i].name) > 15) {
  //       for(let k in tabs){
  //         tabs[k].style.height = '60px'
  //       }
  //     }
  //   }
  // }

  const checkListNameHeight = () => {
    console.log("list:", list)
    for(let i in list) {
      console.log("String(list[i].name):",String(list[i].name))
      if(String(list[i].name).length > 15) {
          console.log(String(list[i].name))
          return 1
        }
    }
    return 0
  }

  return (
    <TabContainer style={{ ...style }} id={idName != undefined && idName != null ? idName:null}>
      {list.map((i, idx) => (
        <TabItem
          style={{
            height: checkListNameHeight() != 1 ? '45px' : '60px', // list의 name중 길이가 15 이상인 글자가 있으면 높이 60px로
          }}
          key={idx}
          ref={i==0 ? firstTabRef : i==1 ? secondTabRef : null}
          className={"tabItem"}
          bgColor={idName != undefined && idName != null ? tabIndex == i.seq ? "#1eb544" : "#cfcfcf4d" : tabIndex == i.seq ? "#474747" : "#cfcfcf4d"}
          color={idName != undefined && idName != null ? tabIndex == i.seq ? "#fff" : "#55555566" : tabIndex == i.seq ? "#fff" : "#55555566"}
          onClick={() => onClick(i)}
        >
          {String(i.name).length <= 15 ? i.name : String(i.name).replace(/([a-z])([A-Z])/g, "$1\n$2")/*글자가 길 경우 대문자를 기준으로 줄바꿈*/}
          {/* {i.name} */}
        </TabItem>
      ))}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`
//border-top-left-radius: 18.5px;
const TabItem = styled.button`
  background-color: ${(props) => props.bgColor || "#fff"};  
  width: 45%;
  margin-left:3.3%;
  padding: 0 19.5px;
  border-radius: 18.5px;
  color: ${(props) => props.color || "#fff"};
  font-size: 16px;
  letter-spacing: 1px;
`

export default Tab
