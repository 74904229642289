import { createAction } from "redux-actions"
import EquipApis from "../apis/EquipApis"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_EQUIP_CONTROL_LIST = "@elefarm-app/equip/SET_EQUIP_CONTROL_LIST"
export const SET_EQUIP_ONOFF_CONTROL_LIST = "@elefarm-app/equip/SET_EQUIP_ONOFF_CONTROL_LIST"

export const INIT_EQUIP_CONTROL_STATUS = "@elefarm/envControl/INIT_EQUIP_CONTROL_STATUS"
export const SET_EQUIP_CONTROL_STATUS = "@elefarm/envControl/SET_EQUIP_CONTROL_STATUS"

/* action function */
export const setEquipControlList = createAction(SET_EQUIP_CONTROL_LIST)
export const setEquipOnOffControlList = createAction(SET_EQUIP_ONOFF_CONTROL_LIST)

export const initEquipControlStatus = createAction(INIT_EQUIP_CONTROL_STATUS)
export const setEquipControlStatus = createAction(SET_EQUIP_CONTROL_STATUS)
/* action api function */

// 설비제어 리스트
export const getEquipControlList = (param) => async (dispatch) => {
  const result = await EquipApis.getEquipControlList(param)
  if (result.code == 0) {
    dispatch(setEquipControlList(result.data.dataList.equipControlForRevList))
    dispatch(setEquipOnOffControlList(result.data.dataList.equipControlOnOffList))
    return true
  } else return false
}
// 설비제어 자동/수동 수정
export const postEquipControlAutoAMod = (param) => async (dispatch) => {
  const result = await EquipApis.postEquipControlAutoAMod(param)
  if (result.code == 0) {
    return true
  } else return false
}
// 설비제어 열림/OnOff 수정
export const postEquipSettingMod = (param) => async (dispatch) => {

  // const result = await EquipApis.postEquipSettingMod(param)
  const result = await CommonApis.postApi_WEB("/product/equipControl/setting/mod", param)
  
  if (result.code == 0) {
    return true
  } else return false

}
// 설비제어 제어(스위치)
export const ctrlEquipControlSwitchMod = (param) => async (dispatch) => {

  const result = await CommonApis.postApi_WEB("/product/equipControl/switch/mod", param)
  
  if (result.code === 0) {
    return true
  } else return false
}
// 설비제어 장비작동상태
export const getEquipControlStatus = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/equipControl/status", param)
  if (result.code === 0) {
    dispatch(setEquipControlStatus(result.data))
    return true
  } else return false
}
