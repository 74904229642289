import api from "../utils/api"

// 환경설정정보
const getSettingInfo = async (data) => {
  const result = await api.get(`/info/setting/info`, {
    token: true,
  })
  return result.data
}
// 환경설정저장
const postSettingMod = async (data) => {
  const result = await api.post(`/info/setting/mod`, {
    body: data,
    token: true,
  })
  return result.data
}

export default {
  getSettingInfo,
  postSettingMod,
}
