// 파일 생성 - 차건담 | 마지막 수정 날짜 - 2023.06.13
import { createAction } from "redux-actions"
import chartApis from "../apis/ChartApis"

/* action type */
export const SET_DATA_LIST = "@elefarm/admin/SET_DATA_LIST"

/* action function */
export const setDataList = createAction(SET_DATA_LIST)

export const initDataList = ( actionType ) => async (dispatch) => {

  let data = {}
  data[actionType.id] = actionType.initData

  dispatch(setDataList( data ))

  return true
}

/* action type */
    export const SET_WORKCENTER_COMBO_LIST = "@elefarm/admin/SET_WORKCENTER_COMBO_LIST"
    export const SET_SENSORNODE_COMBO_LIST = "@elefarm/admin/SET_SENSORNODE_COMBO_LIST"
    export const SET_SENSORKIND_COMBO_LIST = "@elefarm/admin/SET_SENSORKIND_COMBO_LIST"

    export const INIT_CHART_DATA_LIST = "@elefarm/admin/INIT_CHART_DATA_LIST"
    export const SET_CHART_DATA_LIST = "@elefarm/admin/SET_CHART_DATA_LIST"

    export const INIT_COMPARE_CHART_DATA_LIST = "@elefarm/admin/INIT_COMPARE_CHART_DATA_LIST"
    export const SET_COMPARE_CHART_DATA_LIST = "@elefarm/admin/SET_COMPARE_CHART_DATA_LIST"

    export const INIT_SENSOR_MINMAX = "@elefarm/admin/INIT_SENSOR_MINMAX"
    export const SET_SENSOR_MINMAX = "@elefarm/admin/SET_SENSOR_MINMAX"

    export const INIT_TARGETVALUE_LIST = "@elefarm/admin/INIT_TARGETVALUE_LIST"
    export const SET_TARGETVALUE_LIST = "@elefarm/admin/SET_TARGETVALUE_LIST"

/* action function */
    // 조회조건 콤보박스 리스트 가져오기
        export const setWorkCenterComboList = createAction(SET_WORKCENTER_COMBO_LIST)
        export const setSensorNodeComboList = createAction(SET_SENSORNODE_COMBO_LIST)
        export const setSensorKindComboList = createAction(SET_SENSORKIND_COMBO_LIST)

    // 차트 데이터 가져오기    
        export const initChartDataList = createAction(INIT_CHART_DATA_LIST)
        export const setChartDataList = createAction(SET_CHART_DATA_LIST)

    // 비교 차트 데이터 가져오기 - 2023.06.01
        export const initCompareChartDataList = createAction(INIT_COMPARE_CHART_DATA_LIST)
        export const setCompareChartDataList = createAction(SET_COMPARE_CHART_DATA_LIST)

    // 기간내 센서데이터 최소값 최대값 가져오기    
        export const initSensorMinMax = createAction(INIT_SENSOR_MINMAX)
        export const setSensorMinMax = createAction(SET_SENSOR_MINMAX)        

    // 목표 센서값 가져오기 - 2023.06.12
        export const initTargetValueList = createAction(INIT_TARGETVALUE_LIST)
        export const setTargetValueList = createAction(SET_TARGETVALUE_LIST)         




/* action api function */
    // 조회조건 콤보박스 리스트 가져오기
        export const getWorkCenterComboList = () => async(dispatch) => {
            const result = await chartApis.getComboList({ queryKind: "WorkCenter" })

            if (result.code === 0) {
            dispatch(setWorkCenterComboList(result.data.dataList))
        
            return true
            } else return false
        }

        export const getSensorNodeComboList = () => async(dispatch) => {
            const result = await chartApis.getComboList({ queryKind: "SensorNode" })

            if (result.code === 0) {
            dispatch(setSensorNodeComboList(result.data.dataList))
        
            return true
            } else return false    
        }

        export const getSensorKindComboList = () => async(dispatch) => {
            const result = await chartApis.getComboList({ queryKind: "SensorKind" })

            if (result.code === 0) {
            dispatch(setSensorKindComboList(result.data.dataList))
        
            return true
            } else return false    
        }

    // 차트 데이터 가져오기
        export const getChartDataList = (param) => async(dispatch) => {
            const result = await chartApis.getChartData(param)
            console.log("ChartAction > getChartDataList > result", result)
            if (result.code === 0) {
            dispatch(setChartDataList(result.data.dataList))
        
            return true
            } else return false    
        }

    // 비교 차트 데이터 가져오기
        export const getCompareChartDataList = (param) => async(dispatch) => {
            const result = await chartApis.getCompareChartData(param)

            if (result.code === 0) {
            dispatch(setCompareChartDataList(result.data.dataList))
        
            return true
            } else return false    
        }       

    // 기간 내 센서데이터 최소값 최대값 가져오기
        export const getSensorMinMax = (param) => async(dispatch) => {
            const result = await chartApis.getSensorMinMax(param)

            if (result.code === 0) {
            dispatch(setSensorMinMax(result.data.dataList))
        
            return true
            } else return false    
        } 
        
    // 센서 목표값 가져오기 - 차건담 2023.06.13
    export const getTargetValueList = (param) => async(dispatch) => {
        const result = await chartApis.getTargetValueList(param)

        if (result.code === 0) {
        dispatch(setTargetValueList(result.data.dataList))
    
        return true
        } else return false    
    }
    
    // 센서 목표값 저장하기 - 차건담 2023.06.13
    export const insertTargetValueList = ( param ) => async (dispatch) => {
        console.log("목표값 param:", param)
        const result = await chartApis.insertTargetValueList( param )
      
        if (result.code === 0) {
      
          return true
      
        } else return false
    }