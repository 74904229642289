import React, { useState, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"

// import InputBox from "./InputBox"
// import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format"

const InputTelBox = (props) => {
  const {
    // placeholder,
    // type,
    id,
    name,
    value = "",
    setValue,
    // mask, maskChar=" ",
    disabled,
    style,
  } = props

  const [time, setTime] = useState("")

  useEffect(() => {
    setTime("")
  }, [value])

  const onChangeLocalValue = (e) => {
    const value = e.target.value

    setTime(value)
    setValue(value.replaceAll(" - ", "").replaceAll(" ", ""))
  }

  const limit = (val) => {
    if (val.length === 1 && val[0] > "0") {
      val = "0" + val
    }

    if (val.length > 2 && val[1] === "2") return "02"

    if (val.length === 2 && [3, 4, 5, 6].includes(Number(val[1])) === false) return "02"

    if (val.length > 2 && val[1] === "3" && [1, 2, 3].includes(Number(val[2])) === false) return "033"
    if (val.length > 2 && val[1] === "4" && [1, 2, 3, 4].includes(Number(val[2])) === false) return "044"
    if (val.length > 2 && val[1] === "5" && [1, 2, 3, 4, 5].includes(Number(val[2])) === false) return "055"
    if (val.length > 2 && val[1] === "6" && [1, 2, 3, 4].includes(Number(val[2])) === false) return "064"

    return val
  }

  const dateLimit = (value) => {
    let first = limit(value.substring(0, 3))

    let middle = first === "02" ? value.substring(2, 6) : value.substring(3, 7)
    let last = first === "02" ? value.substring(6, 10) : value.substring(7, 11)

    // console.log( first, middle. last )

    return first + (middle.length ? " - " + middle : "") + (last.length ? " - " + last : "")
  }

  return (
    <>
      <StyledInputBox width={width} style={{ ...style }} 
      
        placeholder="000 - 0000 - 0000" 
        id={id} 
        name={name} 
        value={time == "" ? value : time} 
        disabled={disabled} 
        onChange={onChangeLocalValue} 
        // format={dateLimit}//"### - #### - ####"
        format="### - #### - ####"
      />
    </>
  )
}

export default InputTelBox
