import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getSettingInfo, postSettingMod } = actions.SettingAction
const { getSMinorComboList, getUMinorComboList } = actions.CommonAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  settingInfo: state.SettingReducer.settingInfo,
  sminorComboList: state.SettingReducer.sminorComboList,
  uminorComboList: state.SettingReducer.uminorComboList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getSettingInfo: (param) => dispatch(getSettingInfo(param)),
  postSettingMod: (param) => dispatch(postSettingMod(param)),
  getSMinorComboList: (param) => dispatch(getSMinorComboList(param)),
  getUMinorComboList: (param) => dispatch(getUMinorComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
