import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout from "../../component/Layout"
import InfiniteScrollView from "../../component/common/infiniteScrollView"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"
import AlarmContentsPopup from "../../popup/AlarmContentsPopup"
import BasicPopup from "../../popup/BasicPopup"
import AlarmInfoContainer from "../../../containers/AlarmInfoContainer"

import CommonContainer from "../../../containers/CommonContainer"

const AlarmInfoList = (props) => {
  const { t, getInfoUserAlarmList, infoUserAlarmList, postInfoUserAlarmMod, getInfoUserAlarmInfo, initInfoUserAlarmList, infoUserAlarmListTotal,

    // 다국어 관련 추가 - 차건담 2023.04.04
    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord, 
  } = props

  const [click, setClick] = useState("")
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [contentsPopup, setContentsPopup] = useState({ open: false, popupItem: {}, type: "" })
  const count = 50

  const getData = async () => {
    if (infoUserAlarmList == undefined || infoUserAlarmList.length <= 0 || infoUserAlarmListTotal <= 0) {
      await getInfoUserAlarmList({ page: 0, count: count, alarmKind: click })
    } else {
      await getInfoUserAlarmList({ page: Math.floor(infoUserAlarmList.length / count), count: count, alarmKind: click })
    }
  }

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await initInfoUserAlarmList()
    }
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.04

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    getData()
  }, [click])

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      if (contentsPopup.open) {
        setContentsPopup({ open: false, popupItem: {}, type: "" })
      } else {
        navigate("/app/main", { replace: true })
      }
    })
    //
  }, [contentsPopup])

  // 화면상에서 삭제실행시
  const handleDel = async (items) => {
    let result = await postInfoUserAlarmMod({ userAlarmSeq: items.userAlarmSeq })
    if (result) {
      await initInfoUserAlarmList()
      await getInfoUserAlarmList({ page: 0, count: count, alarmKind: click })
    }
    let msg = ""
    if (result) {
      msg = dictCk("successfully deleted.")
    } else {
      msg = dictCk("temporary Error. Please try again.")
    }
    setPopup({ open: true, text: msg })
  }

  //tap
  const typeData = [
    { type: 0, subject: dictCk("total"), color: "#022808" },
    { type: 1, subject: dictCk("errorReception"), color: "#186024" },
    { type: 2, subject: dictCk("stdCropGrowth"), color: "#dc4e9f" },
    { type: 3, subject: dictCk("env"), color: "#512fc7" },
    { type: 4, subject: dictCk("pest"), color: "#0a9621" },
    { type: 5, subject: dictCk("weather"), color: "#f39000" },
    { type: 6, subject: dictCk("error"), color: "#e7450e" },
    { type: 7, subject: dictCk("warningAlarm"), color: "#b32d12" },
    // { type: 8, subject: dictCk("warningAlarm"), color: "#2596be" }, 
  ]

  const selectTap = (index) => {
    if (index >= 0 && index < typeData.length) {
        const { type, subject, color } = typeData[index];
        return (
            <TapDiv
                key={index}
                color={color}
                onClick={async () => {
                    const result = await initInfoUserAlarmList();
                    setClick(type);
                }}
            >
                <TapFont>{subject}</TapFont>
            </TapDiv>
        );
    }
    return null; // index가 범위를 벗어나면 null을 반환
  };

  // // 탭
  // const selectTap = (index) => {
  //   console.log('index : ', typeData)
  //   return (
  //     <TapDiv
  //       key={index}
  //       color={typeData[index].color}
  //       onClick={async () => {
  //         const result = await initInfoUserAlarmList()
  //         setClick(typeData[index].type)
  //       }}
  //     >
  //       <TapFont>{typeData[index].subject}</TapFont>
  //     </TapDiv>
  //   )
  // }

  // 상세팝업닫기
  const closeOpenInfo = async (item = "", items = "") => {
    if (items.userAlarmSeq > -1 && items.isCfm == 0) await getInfoUserAlarmInfo(items.userAlarmSeq)
    const result1 = await initInfoUserAlarmList()
    const result = await getInfoUserAlarmList({ page: 0, count: count, alarmKind: click })
    if (result || item == dictCk("close")) {
      setContentsPopup({ open: false, popupItem: {}, type: "" })
    }
  }

  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("alarmSearch")}
    >
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dictCk("close")]} />
      <AlarmContentsPopup
        items={contentsPopup.popupItem}
        open={contentsPopup.open}
        onClose={closeOpenInfo}
        selectedValue={[dictCk("close")]}
        typeData={typeData}
      />
      <Container>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: `${WidthToDP(35)}px` }}>
          <section style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            {[0, 1, 2, 3].map((i) => selectTap(i))}
          </section>
          <section style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            {[4, 5, 6, 7].map((i) => selectTap(i))}
          </section>
          {/* <section style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            {[8].map((i) => selectTap(i))}
          </section> */}
        </div>

        <InfiniteScrollView
          list={infoUserAlarmList}
          listView={infoUserAlarmList.map((item, idx) => (
            <AlarmTab
              key={idx}
              items={item}
              del={handleDel}
              contentsPopup={contentsPopup}
              setContentsPopup={setContentsPopup}
              itemIndex={idx}
              typeData={typeData}
              t={t}
              dict={dict}
              selectTap={selectTap}
              popup={popup}
              setPopup={setPopup}
              getData={getData}
            />
          ))}
          loadFunction={() => {
            if (infoUserAlarmListTotal > infoUserAlarmList.length && infoUserAlarmList.length !== 0) {
              getData()
            }
          }}
        />
      </Container>
    </Layout>
  )
}

export default CommonContainer(AlarmInfoContainer(withTranslation()(AlarmInfoList)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(30)}px ${WidthToDP(36)}px;
  background-color: #f6f6f6;
`

const AlarmTab = (props) => {
  const { t, items, del, setContentsPopup, itemIndex, selectTap, dict } = props

  return (
    <div
      style={{
        height: `${WidthToDP(230)}px`,
        width: "100%",
        padding: `${WidthToDP(30)}px`,
        marginBottom: `${WidthToDP(24)}px`,
        backgroundColor: `${items.isCfm == 1 ? "#f1f1f1" : "#ffffff"}`,
        borderRadius: `${WidthToDP(9)}px`,
        border: "1px solid #dedede",
      }}
    >
      {/* 제목 */}
      <div
        style={{
          display: "flex",
          height: `${WidthToDP(45)}px`,
          marginBottom: `${WidthToDP(32)}px`,
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <section style={{ height: "100%" }}>{selectTap(items.alarmKind)}</section>
          <section>
            <ContentFont fs={`${WidthToDP(25)}px`} mr={`${WidthToDP(8)}px`}>
              {items.regDateTime}
            </ContentFont>
          </section>
          <section>
            <ReadFont>{items.isCfm == 1 ? dict["read"] : ""}</ReadFont>
          </section>
        </div>
        <div style={{ display: "flex", height: "100%", alignItems: "start" }}>
          <section>
            {items.isDel == 1 ? (
              ""
            ) : (
              <button onClick={() => del(items)}>
                <DelFont>{dict["del"]}</DelFont>
              </button>
            )}
          </section>
        </div>
      </div>
      {/* 내용 */}
      <section
        style={{ overflowY: "hidden" }}
        onClick={async () => {
          setContentsPopup({ open: true, popupItem: items, type: itemIndex })
        }}
      >
        <ContentFont>{items.content}</ContentFont>
      </section>
    </div>
  )
}

const TapDiv = styled.div`
  display: flex;
  height: ${WidthToDP(47)}px;
  width: ${WidthToDP(144)}px;
  justify-content: center;
  align-items: center;
  border-radius: ${WidthToDP(6)}px;
  background-color: ${(props) => (props.color ? props.color : "#022808")};
  margin-right: ${(props) => (props.mr ? "" : `${WidthToDP(24)}px`)};
  margin-bottom: ${WidthToDP(24)}px;
  &:nth-child(4n) {
    margin-right: 0;
  }
`
const ContentFont = styled.p`
  opacity: 0.7;
  font-family: NotoSansCJKkr;
  font-size: ${(props) => (props.fs ? "" : `${WidthToDP(30)}px`)};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
const TapFont = styled.p`
  line-height: ${WidthToDP(37)}px;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
`
const DelFont = styled.p`
  line-height: ${WidthToDP(40)}px;
  opacity: 0.63;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(27)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.54px;
  color: #ff1414;
`
const ReadFont = styled.p`
  line-height: ${WidthToDP(37)}px;
  opacity: 0.5;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  color: #555555;
`
