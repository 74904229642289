import api from "../utils/api"

// 설비제어 리스트
const getEquipControlList = async (data) => {
  const result = await api.get(`/product/equipControl/list?workCenterSeq=${data}`, {
    token: true,
  })
  return result.data
}
// 설비제어 자동/수동 수정
const postEquipControlAutoAMod = async (data) => {
  const result = await api.post_WEB(`/product/equipControl/auto/mod`, {
    body: data,
    token: true,
  })
  return result.data
}
// 설비제어 열림/OnOff 수정
const postEquipSettingMod = async (data) => {
  const result = await api.post_WEB(`/product/equipControl/setting/mod`, {
    body: data,
    token: true,
  })
  return result.data
}

export default {
  getEquipControlList,
  postEquipControlAutoAMod,
  postEquipSettingMod,
}
