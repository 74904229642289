import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import btn_side_double_left_on from "../../images/btn_side_double_left_on.png"
import btn_side_double_right_on from "../../images/btn_side_double_right_on.png"
import btn_side_left_on from "../../images/btn_side_left_on.png"
import btn_side_right_on from "../../images/btn_side_right_on.png"

const Pagination = (props) => {
  const { current, setCurrent, total, count = 10, setLoading= false } = props

  // current - 현재 페이지
  // total   - 총 갯수
  // count   - 한페이지당 제한할 숫자

  // 페이지 수와 아이템 수 설정
  const pageCount = 5;     // 페이지 그룹의 크기 < 1 2 3 4 5 >
  const itemCount = count; // 한 페이지에 표시할 아이템 개수/

  // 첫 페이지로 이동하는 함수
  const toFirstPage = () => {
    setCurrent(1);
    if (current > 1) {setLoading(true)}
  }

  // 이전 페이지로 이동하는 함수
  const toPrevPage = () => {
    if (current > 1) {setCurrent(current - 1), setLoading(true)};
  }

  // 다음 페이지로 이동하는 함수
  const toNextPage = () => {
    if (current < Math.ceil(total / itemCount)) {setCurrent(current + 1), setLoading(true)};
  }

  // 마지막 페이지로 이동하는 함수
  const toLastPage = () => {
    setCurrent(Math.ceil(total / itemCount));
    if (current < Math.ceil(total / itemCount)) {setLoading(true)}
  }

  // 페이지 숫자를 렌더링하는 함수
  const renderPages = () => {
    let firstNum = 1;

    // 현재 페이지가 5보다 크면, 페이지 그룹의 시작 번호를 조정
    if (current > 5) firstNum += Math.floor((current - 1) / pageCount) * pageCount;

    // pageCount만큼 반복하며 페이지 숫자 버튼 생성
    return Array(pageCount)
      .fill(0)
      .map((pagerItem, pagerIndex) => {
        if (firstNum + pagerIndex <= Math.ceil(total / itemCount)) {
          return (
            <PagerCircleButton
              key={pagerIndex}
              color={current === firstNum + pagerIndex ? "#CAEDFF" : "#ffffff"}
              onClick={() => {setCurrent(firstNum + pagerIndex), current === firstNum + pagerIndex ? setLoading(false) : setLoading(true)}}
            >
              <PagerItemText color={current === firstNum + pagerIndex ? "#5e6a79" : "#5e6a79"}>
                {firstNum + pagerIndex}
              </PagerItemText>
            </PagerCircleButton>
          );
        }
      });
  }

  return (
    <PagerContainer>
      <PagerBox>
        {/* 맨 첫페이지 이동 */}
        <PagerCircleButton color={"#f5f5f8"} onClick={toFirstPage}>
          <img style={{ width: "10.8px", height: "10.2px" }} src={btn_side_double_left_on} alt={"처음으로 아이콘"} />
        </PagerCircleButton>
        
        {/* 현재페이지에서 이전페이지로 이동 */}
        <PagerCircleButton color={"#f5f5f8"} onClick={toPrevPage}>
          <img style={{ width: "5.4px", height: "10.2px" }} src={btn_side_left_on} alt={"처음으로 아이콘"} />
        </PagerCircleButton>
        
        {renderPages()}
        
        {/* 현재페이지에서 다음페이지로 이동 */}
        <PagerCircleButton color={"#f5f5f8"} onClick={toNextPage}>
          <img style={{ width: "5.4px", height: "10.2px" }} src={btn_side_right_on} alt={"처음으로 아이콘"} />
        </PagerCircleButton>
        
        {/* 맨 마지막 페이지 이동 */}
        <PagerCircleButton color={"#f5f5f8"} onClick={toLastPage}>
          <img style={{ width: "10.8px", height: "10.2px" }} src={btn_side_double_right_on} alt={"처음으로 아이콘"} />
        </PagerCircleButton>
      </PagerBox>

    </PagerContainer>
  )
}

const PagerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PagerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
`
const PagerCircleButton = styled.button`
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 15px;
`
const PagerItemText = styled.p`
  color: ${(props) => props.color};
  font-size: 15px;
  font-family: NanumSquareBold;
`

Pagination.propTypes = {
  current: PropTypes.any,
  setCurrent: PropTypes.func,
  total: PropTypes.number,
}

export default Pagination
