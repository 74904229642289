import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { WidthToDP, dateFormat, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"

import SEO from "../../seo"
import Layout from "../../component/Layout"

import imageApis from "../../../apis/ImageApis"

import CropContainer from "../../../containers/CropContainer"

import BasicPopup from "../../popup/BasicPopup"
import DateBox from "../../component/common/DateBox"
import AttchImageBox from "../../component/common/AttchImageBox"
import { YellowButton, SkyButton, GreenButton, GrayButton } from "../../component/common/Button"

import CommonContainer from "../../../containers/CommonContainer"

const ASInfo = (props) => {
  const { t, faultSeq, saveAS, asList, open, close, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const [attachImages, setAttachImages] = useState([])

  const TODAY = dateFormat(new Date(), "YYYY-MM-DD")

  const [stdDate, setStdDate] = useState(TODAY)
  const [workValue, setWorkValue] = useState("") //주요작업
  const [popup, setPopup] = useState({ open: false, text: "" })

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  useEffect(() => {
    getTranslationWords()
    if (open) getData(faultSeq)

    return () => {}
  }, [open])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async (faultSeq) => {
    // console.log(777,faultSeq)

    if (faultSeq > 0 && asList.length > 0) {
      const asInfo = asList.filter((row) => row.faultSeq == faultSeq)

      setStdDate(asInfo.length > 0 ? asInfo[0].regDateTime : stdDate)
      setWorkValue(asInfo.length > 0 ? asInfo[0].faultContent : workValue)
      setAttachImages(asInfo.length > 0 ? [{ imageUrl: asInfo[0].fileUri, fileName: asInfo[0].fileName }] : attachImages)
    } // end if
  }

  const saveDate = async () => {
    if (checkRequiredField()) {
      let images = []

      if (attachImages.length > 0) {
        const formData = new FormData()

        for (let i = 0; i < attachImages.length; i++) {
          if (attachImages[i].file) {
            formData.append("images", attachImages[i].file)
          } else {
            images.push({ fileUrl: attachImages[i].imageUrl, fileName: attachImages[i].fileName })
          }
        } // end for

        const uploadsResult = await imageApis.uploadImages(formData)

        images.push(...uploadsResult.data.images)
      }
      const data = {
        faultSeq: faultSeq ? faultSeq : 0,
        receiptDate: stdDate.replaceAll("-", ""),
        fileUri: images.length > 0 ? images[0].fileUrl : "",
        fileName: images.length > 0 ? images[0].fileName : "",
        faultContent: workValue,
        count: 10,
        page: 0,
      }

      if (await saveAS(data)) close()
    } // end if
  }

  const checkRequiredField = () => {
    let msg = ""
    replaceWord(dict["n.Please select a product."], dict['productionUnit'])
    if (stdDate == "") msg = replaceWord(dict["n.Invalid value. Please try again."], dict['regDate'])
    else if (workValue == "") msg = replaceWord(dict["n.Invalid value. Please try again."], dict['asContent'])

    if (msg > "") {
      setPopup({ open: true, text: msg })

      return false
    }

    return true
  }

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={1} // default : 0 => 0: 흰색, 1: 주황색
      isBack={true}
      title={dictCk("errorReception")}
      backHandler={(e) => {
        close()
      }}
    >
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dictCk("confirm")]} />
      <Container>
        <SEO title={dictCk("errorReception")} />

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <TextTitle>{dictCk("regDate")}</TextTitle>
          </section>
          <DateBox disabled={true} style={{ width: "100%" }} stdDate={stdDate} setStdDate={setStdDate} />
        </SubjectSection>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <TextTitle>{dictCk("regContent")}</TextTitle>
          </section>
          <DefaultTextArea value={workValue} onChange={(e) => setWorkValue(e.target.value)} />
        </SubjectSection>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <TextTitle>{dictCk("uplaodImage")}</TextTitle>
          </section>
          <AttchImageBox images={attachImages} setImages={setAttachImages} maxCnt={1} />
        </SubjectSection>

        <div style={{ display: "flex" }}>
          <GrayButton width={`50%`} style={{ marginRight: `${WidthToDP(15)}px` }} onClick={() => close()}>
            {dictCk("cancel")}
          </GrayButton>
          <GreenButton width={`50%`} style={{}} onClick={() => saveDate()}>
            {dictCk("save")}
          </GreenButton>
        </div>
      </Container>
    </Layout>
  )
}

export default CropContainer(CommonContainer(withTranslation()(ASInfo)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(30)}px ${WidthToDP(60)}px;
  background-color: #f6f6f6;
`

const TextTitle = styled.p`
  opacity: 0.8;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(30)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`

const FlexView = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

const SubjectSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
`

const DefaultTextArea = styled.textarea`
  width: 100%;
  height: ${WidthToDP(500)}px;
  resize: none;
`
