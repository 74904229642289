import { createAction } from "redux-actions"
import CropApis from "../apis/CropApis"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_CROP_REPORT_LIST = "@elefarm-app/crop/SET_CROP_REPORT_LIST"
export const SET_CROP_REPORT_INFO = "@elefarm-app/crop/SET_CROP_REPORT_INFO"
export const SET_CROP_REPORT_SEARCH = "@elefarm-app/crop/SET_CROP_REPORT_SEARCH"
export const SET_CROP_HIST_INFO = "@elefarm-app/crop/SET_CROP_HIST_INFO"
export const INIT_AS_LIST = "@elefarm-app/crop/INIT_AS_LIST"
export const SET_AS_LIST = "@elefarm-app/crop/SET_AS_LIST"
export const SET_AS_LIST_TOTAL = "@elefarm-app/crop/SET_AS_LIST_TOTAL"
export const SET_CROP_REPORT_LIST_TOTAL = "@elefarm-app/crop/SET_CROP_REPORT_LIST_TOTAL"
export const INIT_CROP_REPORT_LIST = "@elefarm-app/crop/INIT_CROP_REPORT_LIST"
export const INIT_CROP_REPORT_INFO = "@elefarm-app/crop/INIT_CROP_REPORT_INFO"

/* action function */
export const setCropReportList = createAction(SET_CROP_REPORT_LIST)
export const setCropReportInfo = createAction(SET_CROP_REPORT_INFO)
export const setCropReportSearch = createAction(SET_CROP_REPORT_SEARCH)
export const setCropHistInfo = createAction(SET_CROP_HIST_INFO)
export const initASList = createAction(INIT_AS_LIST)
export const setASList = createAction(SET_AS_LIST)
export const setASListTotal = createAction(SET_AS_LIST_TOTAL)
export const setCropReportListTotal = createAction(SET_CROP_REPORT_LIST_TOTAL)
export const initCropReportList = createAction(INIT_CROP_REPORT_LIST)
export const initCropReportInfo = createAction(INIT_CROP_REPORT_INFO)

/* action api function */

// 재배일지 리스트
export const getCropReportList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropReport/list", param)
  if (result.code == 0) {
    dispatch(setCropReportListTotal(result.data.totalCount))
    dispatch(setCropReportList(result.data.dataList))
    return true
  } else return false
}
// 재배일지 정보
export const getCropReportInfo = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropReport/info", param)
  if (result.code == 0) {
    dispatch(setCropReportInfo(result.data.dataList))
    return true
  } else return false
}

// 재배일지 추가
export const postCropReportAdd = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropReport/add", param)

  if (result.code == 0) {
    return true
  } else return false
}

// 재배일지 정보수정
export const postCropReportMod = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropReport/mod", param)

  if (result.code == 0) {
    return true
  } else return false
}
// 재배일지 삭제
export const postCropReportDel = (param) => async (dispatch) => {
  const result = await CropApis.postCropReportDel(param)
  if (result.code == 0) {
    return true
  } else return false
}
// 재배일지 검색
export const getCropReportSearch = (param) => async (dispatch) => {
  const result = await CropApis.getCropReportSearch(param)
  if (result.code == 0) {
    dispatch(setCropReportSearch(result.data))
    return true
  } else return false
}

export const getCropHistInfo = (param) => async (dispatch) => {
  // 재배이력

  const result = await CommonApis.getApi("/product/hist/info", param)

  if (result.code == 0) {
    dispatch(setCropHistInfo(result.data.dataList))

    return true
  } else return false
}

export const getASList = (param) => async (dispatch) => {
  // 장애접수

  const result = await CommonApis.getApi("/info/fault/list", param)

  if (result.code == 0) {
    dispatch(setASList(result.data.dataList))
    dispatch(setASListTotal(result.data.totalCount))
    return true
  } else return false
}

export const saveAS = (param) => async (dispatch) => {
  // 장애접수 저장

  const result = await CommonApis.postApi("/info/fault/add", param)

  if (result.code == 0) {
    const result = await CommonApis.getApi("/info/fault/list", param)

    if (result.code == 0) {
      dispatch(setASList(result.data.dataList))

      return true
    } else return false
  } else return false
}
