// 센서데이터 그래프 조회 화면 || 최종 수정 날짜 - 2023.07.11
//    >> 모바일에서는 목표값 관련 데이터 그래프에 적용하지 않음
import React, { useState, useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import moment from "moment"

import CommonContainer from "../../../containers/CommonContainer"
import ChartContainer from "../../../containers/ChartContainer"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
//import PageName from "../../component/common/PageName"
import DateBox from "../../component/common/DateBox2"
import MultiComboBox from "../../component/common/MultiComboBox2"
import ComboBox from "../../component/common/ComboBox2"
// import MyChart from '../../popup/Mychart'
// import CompareChart from '../../popup/CompareChart'
import AlertPopup from "../../popup/AlertPopup"
import Tab from "../../component/common/Tab2"
import SensorTargetValuePopup from "../../popup/SensorTargetValuePopup"

import RequiredMark from "../../component/RequiredMark"
//import AlertPopup from "../../popup/AlertPopup"

import {Oval} from "react-loader-spinner";
import {BallTriangle} from "react-loader-spinner";

import CircularIndeterminate from "../../component/common/CircularIndeterminate" // 로딩화면
import CircularIndeterminateSecond from "../../component/common/CircularIndeterminateSecond" // 로딩화면2
import Pagination from "../../component/Pagination" //페이징
//CSS =============="
  import homeButtonIcon from "../../../images/left-arrow.png"
  import GoogleFontLoader from 'react-google-font-loader';

//import GoogleChartSample from "./GoogleChartSample"
//import DygraphChartSample from "./DygraphChartSample"
// import { downExcel, dateFormat } from "../../../utils/util"
// import { setTargetValueList } from "../../../actions/ChartAction"
// import AddDevItemContainer from "../../../containers/AddDevItemContainer"
// import SalesInfoPopup from "../../popup/SalesManage/SalesInfoPopup"
// import { TableComponent } from "../../component/common/Table"
// import InputBox from "../../component/common/InputBox"
// import CodeHelpBox from "../../component/common/CodeHelpBox"
// import ChartPopup from "../../popup/AddDev/ChartPopup"
// import { setSensor } from "../../../actions/DefaultInfoAction"
//import '../../popup/AddDev/chart.css'
// import icon_search from "images/search.png"
// import CodeHelpItemPopup from "../../popup/CodeHelpItemPopup"


/* 
  구현 방식
    1. 이벤트 발생 시 특정 함수 호출
    2. 1번의 함수 호출로 인한 변수의 데이터 값 변경
    3. 2번의 변수 값 변경으로 인한 useEffect 실행
    4. 3번으로 인해 렌더링이 되며 차트 내용 변경 
*/

const SensorDataInquiryComp = (props) => {
  
  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, close } = props
  
  const { 
    itemList: dataList, 
    ITEM_LIST: actionType,
    
    // 다국어
    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

    // 차트
    getWorkCenterComboList, // 워크센터 콤보박스 리스트 가져오는 함수
    getSensorNodeComboList, // 센서노드 콤보박스 리스트 가져오는 함수
    getSensorKindComboList, // 센서노드 콤보박스 리스트 가져오는 함수
    getSensorMinMax,        // 기간 내 센서의 최소값 최대값 가져오는 함수
    initSensorMinMax,
    
    initChartDataList, // 차트데이터 초기화 함수
    getChartDataList, // 차트데이터 가져오는 함수

    initCompareChartDataList, // 비교 차트데이터 초기화 함수
    getCompareChartDataList, // 비교 차트데이터 가져오는 함수

    _workCenterComboList, // 워크센터 리스트 담기는 변수
    _sensorNodeComboList, // 센서노드 리스트 담기는 변수
    _sensorKindComboList,
    _chartDataList, // 차트데이터 담기는 변수
    _compareChartDataList, // 비교 차트데이터 담기는 변수
    _sensorMinMax, // 센서 데이터 최소값 최대값 담기는 변수

    getTargetValueList, // 센서 목표값 리스트 가져오기
    initTargetValueList, // 센서 목표값 리스트 초기화
    _targetValueList, // 센서 목표값 리스트 담기는 변수

    sensorKindForChart, // MainPage에서 넘겨받은 sensorKindForChart
  } = props

  const [itemNo, setItemNo] = useState("")

  // 다국어 관련 ==================================================
    const dict = _translationWords[0] // 변수에 사전 담기
    const dictCk = (word) => {
      if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
        return word
      } else {
        const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
        return result
      }
    }
  // ==============================================================

  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  const [myChartIsChanged, setMyChartIsChanged] = useState([0]) // MyChart가 변경되었는지 확인하는 키값


  // const [stdDate, setStdDate] = useState(moment().format("YYYY0101")) // 조회조건 시작 일시
  const [stdDate, setStdDate] = useState(moment().subtract(30, 'day').format('YYYYMMDD')); // 조회조건 시작 일시
  // const [endDate, setEndDate] = useState(moment().format("YYYYMMDD")) // 조회조건 종료 일시
  const [endDate, setEndDate] = useState(moment().subtract(0, 'day').format('YYYYMMDD')); // 조회조건 종료 일시

  const [workCenterComboList, setWorkCenterComboList] = useState([]) // 작업장 리스트 담는 변수
  const [sensorNodeComboList, setSensorNodeComboList] = useState([]) // 센서노드 리스트 담는 변수
  const [sensorKindComboList, setSensorKindComboList] = useState([]) // 센서종류 리스트 담는 변수
  const [unitInquiryComboList, setUnitInquiryComboList] = useState([]) // 조회단위 리스트

  const [classifyKey, setClassifyKey] = useState(0) // 특정 명령을 실행할 때 일반차트 그래프인지, 비교차트 그래프인지 구분하는 키값

  const [tabIndex, setTabIndex] = useState(0) // tab 라이브러리의 tab 순서 담는 변수
  const [targetValues, setTargetValues] = useState([]) // 목표 최대값 및 최소값 리스트 담는 변수

  // 일반 차트 관련 useState =======================================================================================================                       
    const [sensorKind, setSensorKind] = useState("") // 센서종류 담는 변수  
    const [workCenterDropIndex, setWorkCenterDropIndex] = useState("")   // 선택된 작업장 데이터 담는 변수
    const [sensorNodeDropIndex, setSensorNodeDropIndex] = useState("")   // 선택된 센서노드 리스트 담는 변수
    const [sensorKindDropIndex, setSensorKindDropIndex] = useState("")   // 선택된 센서종류 담는 변수
    const [unitInquiryDropIndex, setUnitInquiryDropIndex] = useState("") // 선택된 조회단위 담는 변수

    const [chartDataList, setChartDataList] = useState([])               // 그래프의 데이터 리스트 담는 변수
    const [categoryList, setCategoryList] = useState([]) // 카테고리 리스트 담는 변수
    const [seriesList, setSeriesList] = useState([])     // 시리즈 리스트 담는 변수 > series는 'ApexCharts'에서 그래프 데이터로 사용되는 변수명임
    const [tooltipList, setTooltipList] = useState([])   // toolTip 리스트 담는 변수 > toolTip은 그래프에 커서가 위치했을 때 나타나는 상자
    const [sensorMinMax, setSensorMinMax] = useState([]) // 센서의 최대값 및 최소값 담는 변수
    const [chartLoading, setChartLoading] = useState([]) // 차트의 조회버튼 클릭 시 로딩 관련
    const [loading, setLoading] = useState(false)        // 로딩 변수

    const [currentPage, setCurrentPage] = useState(1)  // 현재페이지
    const [totalData, setTotalData]     = useState(0)  // 총데이터 숫자
    const [dataCount, setDataCount]     = useState(0)  // 조회단위에 따른 카운팅갯수
  // ==============================================================================================================================

  // 비교 차트 관련 useState =======================================================================================================
    const [compareSensorKind, setCompareSensorKind] = useState("") // 센서종류 담는 변수
    const [compareWorkCenterDropIndex, setCompareWorkCenterDropIndex] = useState("")   // 비교차트의 선택된 작업장 데이터 담는 변수
    const [compareSensorNodeDropIndex, setCompareSensorNodeDropIndex] = useState("")   // 비교차트의 선택된 센서노드 리스트 담는 변수
    const [compareUnitInquiryDropIndex, setCompareUnitInquiryDropIndex] = useState("") // 비교차트의 선택된 조회단위 담는 변수

    const [compareChartDataList, setCompareChartDataList] = useState([]) // 비교차트 그래프의 데이터 리스트 담는 변수
    const [compareCategoryList, setCompareCategoryList] = useState([])   // 비교차트의 카테고리 리스트 담는 변수
    const [compareSeriesList, setCompareSeriesList] = useState([])       // 비교차트의 시리즈 리스트 담는 변수
    const [compareTooltipList, setCompareTooltipList] = useState([])     // 비교차트의 toolTip 리스트 담는 변수
    const [compareYaxisList, setCompareYaxisList] = useState([])         // 비교차트의 y축 리스트 담는 변수
    const [compareSensorMinMax, setCompareSensorMinMax] = useState([])   // 비교차트 센서의 최대값 및 최소값 담는 변수
    const [compareChartLoading, setCompareChartLoading] = useState([])                 // 비교차트의 조회버튼 클릭 시 로딩 관련
  // ==============================================================================================================================  

  // 모바일 가로모드 관련 useState =======================================================================================================                    
    const [isRendering, setIsRendering] = useState(false)
    const [prevScrollPosition, setPrevScrollPosition] = useState(-1)
    const [isTabChanged, setIsTabChanged] = useState(false)
  // ==============================================================================================================================

  // 동적 import ===================================
    const [MyChartComponent, setMyChartComponent] = useState(null);
    const [CompareChartComponent, setCompareChartComponent] = useState(null);
  //
    // 날짜 최대 최저
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())

  // const [journalAddPopup, setJournalAddPopup] = useState(false)
  // const [imgListPopup, setImgListPopup] = useState(false)
  // const [reportSeq, setReportSeq] = useState(-1) //createWrite or Edit
  // const [dropIndex, setDropIndex] = useState("")
  // const [value, setValue] = useState([]) //multiComboBox

  useEffect(() => {
    console.log("dict:", dict)
    getTranslationWords()
    getWorkCenterComboList()
    getSensorNodeComboList()
    getSensorKindComboList()
    initChartDataList()
    initCompareChartDataList()
    initSensorMinMax()
    console.log("확인중 setSensorKindDropIndex(sensorKindForChart) >>>>", sensorKindForChart)
    setSensorKindDropIndex(sensorKindForChart)
    setIsRendering(true)
    import('../../popup/Mychart')
      .then((module) => {
        setMyChartComponent(module.default);
      })
      .catch((error) => {
        // 로딩 중 에러 처리
        console.error('MyChart 로딩에 실패하였습니다.', error);
      });

    // CompareChart를 동적으로 로딩
    import('../../popup/CompareChart')
      .then((module) => {
        setCompareChartComponent(module.default);
      })
      .catch((error) => {
        // 로딩 중 에러 처리
        console.error('CompareChart 로딩에 실패하였습니다.', error);
      });

    // if(unitInquiryDropIndex != "") {
    //   setUnitInquiryDropIndex(unitInquiryComboList[3]['unitInquirySeq'])
    // }
    setUnitInquiryComboList([
                            {unitInquirySeq: '1m'    , unitInquiryName: "1"+dictCk('minutes')},
                            {unitInquirySeq: '10m'   , unitInquiryName: "10"+dictCk('minutes')},
                            {unitInquirySeq: '1h'    , unitInquiryName: "1"+dictCk('hour')},
                            {unitInquirySeq: '1day'  , unitInquiryName: dictCk('1day')},
                            {unitInquirySeq: '1week' , unitInquiryName: dictCk('1week')},
                            {unitInquirySeq: '1month', unitInquiryName: dictCk('1month')}
                          ]) 
  }, [])

  // 만약 props의 각 콤보리스트 변수가 변경되면 다시 useState에 값 할당
  useEffect(()=>{
      if(_workCenterComboList != null && _workCenterComboList != undefined && _workCenterComboList.length != 0){
          setWorkCenterComboList(_workCenterComboList)
      }
      if(_sensorNodeComboList != null && _sensorNodeComboList != undefined && _sensorNodeComboList.length != 0){
          setSensorNodeComboList(_sensorNodeComboList)
      }
      if(_sensorKindComboList != null && _sensorKindComboList != undefined  && _sensorKindComboList.length != 0){
        setSensorKindComboList(_sensorKindComboList)
      }
  }, [_workCenterComboList, _sensorNodeComboList, _sensorKindComboList])

  // 센서데이터 그래프 및 비교그래프 조회 화면 콤보박스 기본값 세팅
  useEffect(()=>{
    if(workCenterComboList != null && workCenterComboList != undefined && workCenterComboList.length != 0){
      setWorkCenterDropIndex(workCenterComboList[0]['workCenterSeq'])
      setCompareWorkCenterDropIndex(workCenterComboList[0]['workCenterSeq'])
    }
    if(sensorKindComboList != null && sensorKindComboList != undefined  && sensorKindComboList.length != 0){
      console.log("sensorKindComboList >>>>>>>>>>>>>>>>>>>>", sensorKindComboList)
      //setSensorKindDropIndex(sensorKindComboList[0]['sensorKind'])
    }
    if(unitInquiryComboList != null && unitInquiryComboList != undefined  && unitInquiryComboList.length != 0){
      setUnitInquiryDropIndex(unitInquiryComboList[3]['unitInquirySeq'])
      setCompareUnitInquiryDropIndex(unitInquiryComboList[3]['unitInquirySeq'])
    }
  }, [workCenterComboList, sensorKindComboList, unitInquiryComboList])

  // _chartDataList 값이 변경되면 다시 chartDataList에 값 할당
  useEffect(()=>{
    if(_chartDataList == null || _chartDataList == [] || _chartDataList == undefined || _chartDataList.length == 0) {}
    else {
      if (_chartDataList != [] && _chartDataList[0]["series"].length != 0){
      setChartDataList(_chartDataList[0]['dataList'])
      Promise.all(chartDataList)
      } else {
        setChartDataList(_chartDataList)

        setCategoryList([])
        setSeriesList([])
        setTooltipList([])
        setSensorMinMax([])
        //탭 바꿀시 페이징 리셋
        setCurrentPage(1) // 현재페이지
        setTotalData(0) // 총데이터 숫자
        setDataCount(0) // 조회단위에 따른 카운팅갯수
      }
    }        
  }, [_chartDataList])

  // _compareChartDataList 값이 변경되면 다시 compareChartDataList에 값 할당
  useEffect(()=>{
    if(_compareChartDataList == null || _compareChartDataList == [] || _compareChartDataList == undefined || _compareChartDataList.length == 0) {}
    else {setCompareChartDataList(_compareChartDataList)}        
  }, [_compareChartDataList])

  // _sensorMinMax 값이 변경되면 차트 구분 키값에 따라 값 다시 할당
  useEffect(() => {
    if(_sensorMinMax == null || _sensorMinMax == [] || _sensorMinMax == undefined || _sensorMinMax.length == 0) {}
    else {
      if(classifyKey == 1) {
        setSensorMinMax(_sensorMinMax)
        Promise.all(sensorMinMax)
      } else if(classifyKey == 2) {
        setCompareSensorMinMax(_sensorMinMax)
        Promise.all(compareSensorMinMax)       
      }
    }
  }, [_sensorMinMax])

  // _targetValueList 값이 변경되면 다시 finalTargetValues에 값 할당
  useEffect(() => {
    setTargetValues([])
    const fetchData = async () => {
      try {
        if(_targetValueList != []){
          let finalTargetValues = []
          let sensorKindName = ""
          for(let sensorKind of sensorKindComboList){
            if(sensorKind.sensorKind == sensorKindDropIndex) {
              sensorKindName = sensorKind.sensorName
            }
          }
          for(let i in _targetValueList){
            if (sensorNodeDropIndex.length > 0) {
              for(let sensorNode of sensorNodeDropIndex){
                if(_targetValueList[i].sensorNodeSeq==sensorNode.value){
                  let targetValue = _targetValueList[i]
                  targetValue['sensorNodeName'] = sensorNode.label
                  targetValue['sensorKindName'] = sensorKindName
                  finalTargetValues.push(_targetValueList[i])
                }
              }          
            }
          }
            setTargetValues(finalTargetValues)
            Promise.all(targetValues)
          }
      } catch (error) {
        console.error(error);
      }
    };
    // Call the async function
    fetchData();
  }, [_targetValueList])


  useEffect(() => {
  }, [targetValues])

  // chartDataList 값이 변경되면 카테고리, 시리즈, 툴팁의 값을 가져옴
  useEffect(() => {
    if (chartDataList == null || chartDataList == [] || chartDataList == undefined || chartDataList.length == 0){
      setChartLoading(true)
      setTimeout(() => {
        setLoading(false) // 조회버튼 로딩종료
      }, 1000);
    }
    else {
      const fetchData = async () => {
      try {
        if (_chartDataList != [] && _chartDataList.length > 0) {
        // setCategoryList(_chartDataList[0]['category'])
        // setSeriesList(_chartDataList[0]['series'])
        // setTooltipList(_chartDataList[0]['tooltip']) 
        
          // ---------------------------------- 데이터 페이징처리 start
          const seriesListtotal = _chartDataList[0]['series'].map(item => item.data); //조회한 데이터의 총 갯수 
          setTotalData(seriesListtotal[0].length) // 데이터 총 갯수 set

          const startIdx = (currentPage - 1) * dataCount; // 시작 인덱스 계산
          const endIdx   = currentPage * dataCount;       // 끝 인덱스 계산

          const categoryListSplit = _chartDataList[0]['category'].slice(startIdx, endIdx) // setCategoryList(_chartDataList[0]['category'])
          const seriesListSplit   = _chartDataList[0]['series'].map(item => {               // setSeriesList(_chartDataList[0]['series'])
            return { name: item.name, data: item.data.slice(startIdx, endIdx) };
          });
          const tooltipListMax = _chartDataList[0]['tooltip']['max'].map(item => {         // setTooltipList(_chartDataList[0]['tooltip'])
            return { name: item.name, data: item.data.slice(startIdx, endIdx) };
          });
          const tooltipListMin = _chartDataList[0]['tooltip']['min'].map(item => {          // setTooltipList(_chartDataList[0]['tooltip'])
            return { name: item.name, data: item.data.slice(startIdx, endIdx) };
          });

          const tooltipListSplit = {max:tooltipListMax, min:tooltipListMin}

          setCategoryList(categoryListSplit)
          setSeriesList(seriesListSplit)
          setTooltipList(tooltipListSplit)
          // ---------------------------------- 데이터 페이징처리 end

        } else {
          setCategoryList([])
          setSeriesList([])
          setTooltipList([])
        }

        if (chartDataList[0]!=0) {
          setMyChartIsChanged([1])
          Promise.all(myChartIsChanged)
        }

      } catch (error) {
        console.error(error);
      }
    };
    // Call the async function
    fetchData();
    }
  }, [chartDataList, currentPage])

  // compareChartDataList 값이 변경되면 비교차트의 카테고리, 시리즈, 툴팁, Y축 리스트의 값을 가져옴
  useEffect(() => {
    if (compareChartDataList == null || compareChartDataList == [] || compareChartDataList == undefined || compareChartDataList.length == 0){
      setCompareChartLoading(false)
      setTimeout(() => {
        setLoading(false) // 조회버튼 로딩종료
      }, 1000);
    }
    else {
      const fetchData = async () => {
      try {
        if (_compareChartDataList != [] && _compareChartDataList.length > 0) {
          // setCompareCategoryList(_compareChartDataList[0]['category'])
          // setCompareSeriesList(_compareChartDataList[0]['series'])
          // setCompareTooltipList(_compareChartDataList[0]['tooltip'])
          // setCompareYaxisList(_compareChartDataList[0]['yaxisList'])
          
          // ---------------------------------- 데이터 페이징처리 start
          if(_compareChartDataList[0]['series'].length == 0){
            setCompareCategoryList([])
            setCompareSeriesList([])
            setCompareTooltipList([])
            setCompareYaxisList([])

          }else{
            const compareChartDataListtotal = _compareChartDataList[0]['series'].map(item => item.data); //조회한 데이터의 총 갯수 
            setTotalData(compareChartDataListtotal[0].length) // 데이터 총 갯수 set

            const startIdx = (currentPage - 1) * dataCount; // 시작 인덱스 계산
            const endIdx   = currentPage * dataCount;       // 끝 인덱스 계산
            
            const categoryListSplit = _compareChartDataList[0]['category'].slice(startIdx, endIdx)   // setCompareCategoryList(_compareChartDataList[0]['category'])
            const seriesListSplit   = _compareChartDataList[0]['series'].map(item => {               // setCompareSeriesList(_compareChartDataList[0]['series']) // setCompareTooltipList(_compareChartDataList[0]['series'])
              return { name: item.name, data: item.data.slice(startIdx, endIdx) };
            });

            setCompareCategoryList(categoryListSplit)
            setCompareSeriesList(seriesListSplit)
            setCompareTooltipList(seriesListSplit)
            setCompareYaxisList(_compareChartDataList[0]['yaxisList'])
          }
          // ---------------------------------- 데이터 페이징처리 start

        } else {
          setCompareCategoryList([])
          setCompareSeriesList([])
          setCompareTooltipList([])
          setCompareYaxisList([])
          setCompareChartLoading(false)
          setLoading(false) // 로딩화면 종료
          return
        }
          

        if (chartDataList[0]!=0) {
          setMyChartIsChanged([1])
          Promise.all(myChartIsChanged)
        }
        setCompareChartLoading(false)
        setLoading(false) // 로딩화면 종료
      } catch (error) {
        console.error(error);
      }
    };

    // Call the async function
    fetchData();
    }
  }, [compareChartDataList, currentPage])

  // 차트 조회 버튼 클릭했을 때 차트데이터가 렌더링 될때까지 로딩 걸어주는 기능
  useEffect(() => {
    if(categoryList != [] && seriesList != [] && tooltipList != []){
      setChartLoading(true)
      setTimeout(() => {
        setLoading(false) // 조회버튼 로딩종료
      }, 1000);
    }
  }, [categoryList, seriesList, tooltipList])

  // 비교차트 조회 버튼 클릭했을 때 차트데이터가 렌더링 될때까지 로딩 걸어주는 기능
  useEffect(() => {
    if(compareCategoryList.length > 0 && compareSeriesList.length > 0 && Object.keys(compareTooltipList).length > 0 && compareYaxisList.length > 0 ){
      setCompareChartLoading(false)
      setTimeout(() => {
        setLoading(false) // 조회버튼 로딩종료
      }, 1000);
    }
  }, [compareCategoryList, compareSeriesList, compareTooltipList, compareYaxisList])
  
  const firstBoxRef = useRef(null);
  const secondBoxRef = useRef(null);

  /* 탭 클릭하여 tabIndex 값 변경될 때 모바일 세로 및 가로 모드 상태 체크 후
     가로인 경우 아래로 스크롤 할때 상단의 탭 가려주는 기능 */
  useEffect(() => {
    // console.log("isRendering:",isRendering)
    const dateBoxFrom = document.getElementById('dateBoxFrom')
    const dateBoxTo = document.getElementById('dateBoxTo')
    dateBoxFrom.style.paddingLeft = "25px";
    dateBoxFrom.style.paddingRight = "25px";
    dateBoxFrom.style.fontSize = "17px";
    dateBoxFrom.style.border = "1px solid #8a8e8b";
    dateBoxFrom.style.borderRadius = "20px";
    dateBoxFrom.readOnly = true;

    dateBoxTo.style.paddingLeft = "25px";
    dateBoxTo.style.paddingRight = "25px";
    dateBoxTo.style.fontSize = "17px";
    dateBoxTo.style.border = "1px solid #8a8e8b";
    dateBoxTo.style.borderRadius = "20px";
    dateBoxTo.readOnly = true;

    const multiComboBox = document.getElementsByClassName('multiComboBox');
    if(multiComboBox){
      const multiComboBoxInput = multiComboBox != null && multiComboBox != undefined ? multiComboBox[0].getElementsByTagName('input') : ''
      multiComboBoxInput[0].readOnly = true;

      const multiComboBoxDiv = multiComboBox != null && multiComboBox != undefined ? multiComboBox[0].getElementsByTagName('div') : ''
      multiComboBoxDiv[0].style.height = '55px'
    }

    // scroll 이벤트 발생했을 때 실행시키는 함수: 세로 및 가로의 스크롤 위치에 따라 상단부 숨기기 위한 용도 - 차건담 2023.07.18
    function handleScroll(event) {
      console.log("handleScroll 진입")
      const scrollPosition = event.target.scrollTop;
      // console.log("scrollPosition:",scrollPosition)     
      const tabContainer = document.getElementById('tabContainer');
      const homeButton = document.getElementById('homeButton');
      const container = document.getElementById('container');
      const firstBox = document.getElementById('firstBox');
      const secondBox = document.getElementById('secondBox');
      const titleDiv = document.getElementById('titleDiv');
      
      if(container){container.style.transition = 'transform 0.5s ease'}
      if(firstBox){firstBox.style.transition = 'margin-top 0.5s'}
      if(secondBox){secondBox.style.transition = 'margin-top 0.5s'}
      if(titleDiv){titleDiv.style.transition = 'height 0.5s'}

      if(scrollPosition < 100) {
        if(tabContainer){
          tabContainer.style.display = 'flex';
          tabContainer.style.justifyContent = 'flex-start';
          tabContainer.style.opacity = 1;
          tabContainer.style.height = '37px';
          tabContainer.style.marginTop = '30px';
        }
        
        if(homeButton){
          homeButton.style.display = 'flex';
          homeButton.style.opacity = 1;
          homeButton.style.justifyContent = 'center';
          homeButton.style.alignItems = 'center';
          homeButton.style.height = '170px';
        }
      }
      else {
        if (typeof window !== "undefined") {
          if(window.matchMedia('(orientation: portrait)').matches){}
          else{
            if(tabContainer){
              tabContainer.style.position = 'relative'
              tabContainer.style.transition = 'opacity 0.5s, height 0.5s, margin-top 0.5s'
              tabContainer.style.opacity = 0;
              tabContainer.style.height = 0;
              tabContainer.style.marginTop = 0;
            }

            if(homeButton){
              homeButton.style.position = 'relative'
              homeButton.style.transition = 'opacity 0.5s, height 0.5s'
              homeButton.style.opacity = 0;
              homeButton.style.height = 0;
  
              homeButton.style.justifyContent = 'center';
              homeButton.style.alignItems = 'center';
            }

            //if(titleDiv){titleDiv.style.height=0;}

            if(firstBox){firstBox.style.marginTop=0;}
            if(secondBox){secondBox.style.marginTop=0;}
          }
        }
      }
    }

    // 태그에 이벤트 등록하고 이벤트 발생 시 함수 작동 ============================
      /*
        useRef로 변수를 정의하여 해당 useRef를 HTML태그에 부여하여
        정의된 변수.current를 다른 변수에 할당하면 마치 DOM객채를 호출하여
        변수에 담는 것과 유사하게 사용이 가능하다 
        
        firstBox와 secondBox는 위와같은 방식으로 사용되고 있고 해당 변수에
        값이 할당되려면 HTML이 렌더링되어야 하기에 조건문을 사용하고 생성된 경우
        각 변수에 eventListner를 추가해주고 있다.

        scroll 이벤트가 발생하면 위에 정의된 handScroll 함수가 실행된다.
      */
      let firstBox = null;
      let secondBox = null;

      firstBox = firstBoxRef.current;
      secondBox = secondBoxRef.current;

      if (firstBox) { // 1번 탭 화면에 스크롤 이벤트 등록
        firstBox.addEventListener('scroll', handleScroll);
      }

      if (secondBox) { // 2번 탭 화면에 스크롤 이벤트 등록
        secondBox.addEventListener('scroll', handleScroll);
      }
    // ========================================================================


    // =====================================================================탭 차트리셋 start
    if(categoryList != [] && seriesList != [] && tooltipList != []){ // 일반차트 탭 바꿀시 데이터 담고있는 변수들 리셋

      setChartDataList([])
      setCategoryList([])
      setSeriesList([])
      setTooltipList([])
      setSensorMinMax([])
  
      //탭 바꿀시 페이징 리셋
      setCurrentPage(1) // 현재페이지
      setTotalData(0)   // 총데이터 숫자
      setDataCount(0)   // 조회단위에 따른 카운팅갯수
      }
  
      if(compareCategoryList != [] && compareSeriesList != [] && compareTooltipList != [] && compareYaxisList != []){ // 비교차트 탭바꿀시 데이터 담고있는 변수들 리셋
        setCompareChartDataList([])
        setCompareCategoryList([])
        setCompareSeriesList([])
        setCompareTooltipList([])
        setCompareYaxisList([])
  
        //탭 바꿀시 페이징 리셋
        setCurrentPage(1) // 현재페이지
        setTotalData(0)   // 총데이터 숫자
        setDataCount(0)   // 조회단위에 따른 카운팅갯수
      }
    // =====================================================================탭 차트리셋 end

    // console.log("firstBox, secondBox:",firstBox, secondBox)
    return () => {
      console.log("return isRendering:", isRendering)
        // 컴포넌트 마운트가 해제되는 경우 이벤트 발생에 대한 함수작동을 하지 않기 위해서 이벤트를 제거한다
        if (firstBox) {
          firstBox.removeEventListener('scroll', handleScroll);
        }
        
        if (secondBox) {
          secondBox.removeEventListener('scroll', handleScroll);
        }
    };
  }, [tabIndex]);

    // 23.0905 - 날짜제한 추가 - 김한중 ==========================================================
    // ======================================================================날짜 제한 큰버전START
    // 날짜 변경시 달력 날짜 제한
    useEffect(() => {
      // setStdDate(stdDate);//시작일
      // setEndDate(stdDate);//종료일 동일하게 변경
      const differenceInDays = calculateDateDifference(stdDate, endDate) //두 날짜 차이값

      let dayNum = 0
      const plusMinus = "-"
      if ((tabIndex == 0 && unitInquiryDropIndex == "1m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1m")) {
          setDataCount(180) // 화면에서 보여줄 데이터 갯수 1분단위 3시간
          dayNum = 2
          if (differenceInDays >= 3) {
              const dateChange = dateConversion(endDate, 1, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(dateChange) //시작일
              setEndDate(endDate) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "10m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "10m")) {
          setDataCount(144) // 화면에서 보여줄 데이터 갯수 10분단위 1일
          dayNum = 30
          if (differenceInDays >= 31) {
              const dateChange = dateConversion(endDate, 10, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(dateChange) //시작일
              setEndDate(endDate) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "1h") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1h")) {
          setDataCount(168) // 화면에서 보여줄 데이터 갯수 1시간단위 7일
          dayNum = 91
          if (differenceInDays >= 92) {
              const dateChange = dateConversion(endDate, 31, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(dateChange) //시작일
              setEndDate(endDate) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "1day") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1day")) {
          setDataCount(180) // 화면에서 보여줄 데이터 갯수 하루단위 3개월
          dayNum = 729
          if (differenceInDays >= 730) {
              const dateChange = dateConversion(endDate, 180, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(dateChange) //시작일
              setEndDate(endDate) //종료일 동일하게 변경
          }
      } else {
          setDataCount(200) // 화면에서 보여줄 데이터 갯수 나머지
          dayNum = 10000
      }
      // console.log("@@@@@@@@dayNum 조회날짜", dayNum)
      // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
      const originalDate = new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2))
      const modifiedDate = originalDate.setDate(originalDate.getDate() + parseInt(dayNum))

      setMinDate(new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2)))
      setMaxDate(modifiedDate)
      // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
      // console.log('dateFr(1) : ', dateFr)
      // console.log('dateTo(1) : ', dateTo)
  }, [unitInquiryDropIndex, compareUnitInquiryDropIndex, tabIndex])
  // ======================================================================날짜 제한 큰버전END
  // ======================================================================날짜 제한 큰버전START
  // 날짜 변경시 달력 날짜 제한
  useEffect(() => {
      // setStdDate(stdDate);//시작일
      // setEndDate(stdDate);//종료일 동일하게 변경
      const differenceInDays = calculateDateDifference(stdDate, endDate) //두 날짜 차이값

      let dayNum = 0
      const plusMinus = "+"
      if ((tabIndex == 0 && unitInquiryDropIndex == "1m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1m")) {
          setDataCount(180) // 화면에서 보여줄 데이터 갯수 1분단위 3시간
          dayNum = 2
          if (differenceInDays >= 3 || parseInt(stdDate) > parseInt(endDate)) {
              const dateChange = dateConversion(stdDate, 1, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(stdDate) //시작일
              setEndDate(dateChange) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "10m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "10m")) {
          setDataCount(144) // 화면에서 보여줄 데이터 갯수 10분단위 1일
          dayNum = 30
          if (differenceInDays >= 31 || parseInt(stdDate) > parseInt(endDate)) {
              const dateChange = dateConversion(stdDate, 10, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(stdDate) //시작일
              setEndDate(dateChange) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "1h") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1h")) {
          setDataCount(168) // 화면에서 보여줄 데이터 갯수 1시간단위 7일
          dayNum = 91
          if (differenceInDays >= 92 || parseInt(stdDate) > parseInt(endDate)) {
              const dateChange = dateConversion(stdDate, 31, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(stdDate) //시작일
              setEndDate(dateChange) //종료일 동일하게 변경
          }
      } else if ((tabIndex == 0 && unitInquiryDropIndex == "1day") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1day")) {
          setDataCount(180) // 화면에서 보여줄 데이터 갯수 하루단위 3개월
          dayNum = 729
          if (differenceInDays >= 730 || parseInt(stdDate) > parseInt(endDate)) {
              const dateChange = dateConversion(stdDate, 180, plusMinus) // 바꿀날짜
              // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
              setStdDate(stdDate) //시작일
              setEndDate(dateChange) //종료일 동일하게 변경
          }
      } else {
          setDataCount(200) // 화면에서 보여줄 데이터 갯수 나머지
          dayNum = 10000
      }
      // console.log("@@@@@@@@dayNum 조회날짜", dayNum)
      // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
      const originalDate = new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2))
      const modifiedDate = originalDate.setDate(originalDate.getDate() + parseInt(dayNum))

      setMinDate(new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2)))
      setMaxDate(modifiedDate)
      // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
      // console.log('dateFr(1) : ', dateFr)
      // console.log('dateTo(1) : ', dateTo)
  }, [stdDate, endDate])
  // ======================================================================날짜 제한 큰버전END

  // 조회 단위에 따른 조회 기간 제한을 주기 위해 두 날짜 사이의 일 수 계산하는 함수 - 차건담 2023.08.08
  function calculateDateDifference(startDate, endDate) {
    startDate = startDate.substr(0,4)+"-"+startDate.substr(4,2)+"-"+startDate.substr(6,2)
    endDate = endDate.substr(0,4)+"-"+endDate.substr(4,2)+"-"+endDate.substr(6,2)
    const oneDay = 24 * 60 * 60 * 1000; // 1일을 밀리초로 표현
    console.log("startDate/ endDate:", startDate, " / ", endDate)
    // 날짜를 밀리초로 변환하여 차이 계산
    const startTime = new Date(startDate).getTime();
    const endTime = new Date(endDate).getTime();
    console.log("startTime:", startTime)
    console.log("endTime:", endTime)
    // 날짜 차이를 일 단위로 계산
    const differenceInDays = Math.round(Math.abs((endTime - startTime) / oneDay));
  
    return differenceInDays;
  }

  // 날짜를 더해주는 함수(dateChange=변할날짜, changNum=변할날짜수, plusMinus=더할지뺼지)
  function dateConversion(dateChange, changNum, plusMinus) {
    // 입력받은 stdDate를 "YYYY-MM-DD" 형식의 날짜로 변환합니다.
    const originalDateS = new Date(dateChange.substr(0, 4) + "-" + dateChange.substr(4, 2) + "-" + dateChange.substr(6, 2))
    // 날짜를 3일 뒤로 변경합니다.
    if (plusMinus == "+") {
        originalDateS.setDate(originalDateS.getDate() + changNum)
    } else if (plusMinus == "-") {
        originalDateS.setDate(originalDateS.getDate() - changNum)
    }
    // 변경된 날짜의 연도를 가져옵니다.
    const year = originalDateS.getFullYear()
    // 변경된 날짜의 월을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
    const month = String(originalDateS.getMonth() + 1).padStart(2, "0")
    // 변경된 날짜의 일을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
    const day = String(originalDateS.getDate()).padStart(2, "0")
    // 변경된 날짜를 "YYYYMMDD" 형식으로 만듭니다.
    const modifiedDate = `${year}${month}${day}`
    // 결과값으로 변경된 날짜를 반환합니다.
    return modifiedDate
  }
    // 예시: dateConversion("20230305") 호출 시 "20230308" 반환

  // 클릭 시 조회 조건에 따른 차트데이터 가져오는 함수 - 차건담 2023.05.22
  const onClickSearch = async () => {
    console.log("김한중 - sensorNodeDropIndex", sensorNodeDropIndex)

    if (workCenterDropIndex === undefined || workCenterDropIndex === '' || workCenterDropIndex === 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('workCenterName') ) }) //작업장명
    else if (sensorNodeDropIndex === undefined || sensorNodeDropIndex === '' || sensorNodeDropIndex.length == 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('sensorNodeName') ) }) //센서노드명
    else if (unitInquiryDropIndex === undefined || unitInquiryDropIndex === '' || unitInquiryDropIndex === 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('inquiry')+dictCk('unit') ) }) //조회단위
    else if (sensorKindDropIndex === undefined || sensorKindDropIndex === '' || sensorKindDropIndex === 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('sensorKind') ) }) //센서종류
    else if (stdDate === undefined || stdDate.length < 8) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('dateFr') ) }) //등록일 시작
    else if (endDate === undefined || endDate.length < 8) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('dateTo') ) }) //등록일 종료
    
    const differenceInDays = calculateDateDifference(stdDate, endDate) // 두 날짜사이 일수 계산

 // ======================================================================날짜 제한 큰버전START
        if (unitInquiryDropIndex == "1m") {
            // 1분일 경우 최대 3일로 제한
            if (differenceInDays >= 3)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "3"),
                })
        } else if (unitInquiryDropIndex == "10m") {
            // 10분일 경우 최대 31일로 제한
            console.log(differenceInDays)
            if (differenceInDays >= 31)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "10" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "31"),
                })
        } else if (unitInquiryDropIndex == "1h") {
            // 1시간일 경우 최대 92일로 제한
            if (differenceInDays >= 92)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("hour")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "92"),
                })
        } else if (unitInquiryDropIndex == "1day") {
            // 하루일 경우 최대 365일로 제한
            if (differenceInDays >= 730)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("day")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "730"),
                })
        }
        // ======================================================================날짜 제한 큰버전END

    setChartLoading(false)
    setLoading(true) // 로딩화면 시작

    setClassifyKey(1)
    if (workCenterDropIndex == "") { // 워크센터 선택되지 않으면 차트데이터 가져오지 않음
      await initChartDataList()
      await initSensorMinMax()
      setChartDataList([0])
    } else {
      let allSensorNode = []
      for(let i in sensorNodeComboList){
        allSensorNode.push(sensorNodeComboList[i]['sensorNodeSeq'])
      }
      const data = {
        workCenterSeq: workCenterDropIndex == "" ? "" : workCenterDropIndex,
        sensorNodeSeq: sensorNodeDropIndex == [] || sensorNodeDropIndex.length == 0? allSensorNode : sensorNodeDropIndex.map(i => i.value),
        sensorKind: sensorKindDropIndex == "" ? "" : sensorKindDropIndex,
        unitInquiry: unitInquiryDropIndex == "" ? "" : unitInquiryDropIndex,
        dateFr: stdDate, 
        dateTo: endDate,
        tab: 'tab1',
      }
      setSensorKind(data['sensorKind'])

      await getChartDataList(data)
      await getSensorMinMax(data)
      await getTargetValueList({sensorKind: data['sensorKind']})
    }
  }

  // 클릭 시 조회 조건에 따른 비교차트 데이터 가져오는 함수
  const onClickButton = async (sensorKind) => {

    if (compareWorkCenterDropIndex === undefined || compareWorkCenterDropIndex === '' || compareWorkCenterDropIndex === 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('workCenterName') ) }) //작업장명
    else if (compareSensorNodeDropIndex === undefined || compareSensorNodeDropIndex === '' || compareSensorNodeDropIndex.length == 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('sensorNodeName') ) }) //센서노드명
    else if (compareUnitInquiryDropIndex === undefined || compareUnitInquiryDropIndex === '' || compareUnitInquiryDropIndex === 0) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('inquiry')+dictCk('unit') ) }) //조회단위

    else if (stdDate === undefined || stdDate.length < 8) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('dateFr') ) }) //등록일 시작
    else if (endDate === undefined || endDate.length < 8) 
    return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Invalid value. Please try again.'), dictCk('dateTo') ) }) //등록일 종료
    
    const differenceInDays = calculateDateDifference(stdDate, endDate)

        // ======================================================================날짜 제한 큰버전START
        if (compareUnitInquiryDropIndex == "1m") {
            // 1분일 경우 최대 3일로 제한
            if (differenceInDays >= 3)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "3"),
                })
        } else if (compareUnitInquiryDropIndex == "10m") {
            // 10분일 경우 최대 31일로 제한
            console.log(differenceInDays)
            if (differenceInDays >= 31)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "10" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "31"),
                })
        } else if (compareUnitInquiryDropIndex == "1h") {
            // 1시간일 경우 최대 92일로 제한
            if (differenceInDays >= 92)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("hour")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "92"),
                })
        } else if (compareUnitInquiryDropIndex == "1day") {
            // 하루일 경우 최대 730일로 제한
            if (differenceInDays >= 730)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("day")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "730"),
                })
        }
        // ======================================================================날짜 제한 큰버전END

    setCompareChartLoading(true)
    setLoading(true) // 로딩화면  시작
    setClassifyKey(2)
    if (compareWorkCenterDropIndex.length == 0 || compareSensorNodeDropIndex.length == 0 || compareUnitInquiryDropIndex.length == 0) { // 워크센터 선택되지 않으면 차트데이터 가져오지 않음
      await initCompareChartDataList()
      await initSensorMinMax()
      setChartDataList([0])
      setCompareChartLoading(false)
      setLoading(false) // 로딩화면  종료
    } else {
      let allSensorNode = []
      for(let i in sensorNodeComboList){
        allSensorNode.push(sensorNodeComboList[i]['sensorNodeSeq'])
      }
      const data = {
        workCenterSeq: compareWorkCenterDropIndex == "" ? "" : compareWorkCenterDropIndex,
        sensorNodeSeq: compareSensorNodeDropIndex == [] || compareSensorNodeDropIndex.length == 0? allSensorNode : compareSensorNodeDropIndex.map(i => i.value),
        sensorKind: sensorKind,
        unitInquiry: compareUnitInquiryDropIndex == "" ? "" : compareUnitInquiryDropIndex,
        dateFr: stdDate, 
        dateTo: endDate,
        tab: 'tab2',
      }
      setCompareSensorKind(data['sensorKind'])

      await getCompareChartDataList(data)
      await getSensorMinMax(data)
    }
  }
  
  return (
    <Container id={'container'}>
      {/* <CircularIndeterminate open={loading} /> */}
      <CircularIndeterminateSecond open={loading} />
      <GoogleFontLoader
        fonts={[
          {
            font: 'Do Hyeon',
            weights: [400, 700], // 폰트의 두께를 설정할 수 있습니다. 여러 개 지정 가능.
          },
        ]}
      />
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />

      <div id={'homeButton'}
                  style={{width:"100%", height:"170px", display:'flex', alignItems:'center'}}
                  >
        <div style={{width:"50%", height:"100%", display:"flex", alignItems:"center"}}>
          {/* <p style={{font:"icon", fontSize:"23px", fontWeight:"bold", color:"#8a8e8b", marginLeft:"10px"}}>센서데이터조회</p> */}
        </div>
        <div style={{width:"30%", height:"100%"}}></div>
        <div style={{width:"20%", height:"100%", paddingTop:"15px", zIndex:'11'}}>
          <button style={{width:"100%", height:"100%", display:"flex", justifyContent:"right"}}>
            <img src={homeButtonIcon}
              onClick={async () => {close()}}
              style={{width:"30px", height:"30px", display:"block", marginRight:"15px"}}>
            </img>
          </button>
        </div>
        <div id={'titleDiv'} style={{ width:"100%", height:"170px", display:'flex', alignItems:'center',
                      justifyContent:'center', position:'absolute', zIndex:'10', marginTop:'40px'}}>                        
          <p style={{fontFamily: 'Do Hyeon, sans-serif', fontWeight: 400, fontSize:"30px",
                    color:"#1eb544", marginLeft:"10px", letterSpacing:"3px"}}>
            {dictCk('sensorDataInquiry')}
          </p>
        </div>
      </div>
      <Tab
        idName={'tabContainer'}
        style={{ marginTop: "30px" , width: "100%", zIndex:"12"}}
        list={[
          { seq: 0, name: dictCk('sensorDataGraph') },
          { seq: 1, name: dictCk('comparisonGraphBySensor') },
        ]}
        tabIndex={tabIndex}
        //tabWidth={window.matchMedia('(orientation: portrait)').matches ? "45%" : "20%"}
        onClick={(i) => {
          setTabIndex(i.seq)
        }}
      />

      {tabIndex == 0 && (
        <Box id={'firstBox'}>
          <WhiteBox ref={firstBoxRef}>
            {/* <SearchBox> */}
            <div style={{marginTop:"20px"}}>
              {/* <Text><RequiredMark />{'작업장명'}</Text> */}
              <ComboBox
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                defaultValue={dictCk('workCenterName')}
                hasDefault={true}
                items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                //items={ {value: 1, label: 1} }
                value={workCenterDropIndex}
                setValue={setWorkCenterDropIndex}
              />

              {/* <Text><RequiredMark />{'센서노드명'}</Text> */}
              <MultiComboBox
                className={"multiComboBox"}
                defaultValue={dictCk('sensorNodeName')}
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px"}}
                placeholder={""}
                items={sensorNodeComboList.map((i) => ({ value: i.sensorNodeSeq, label: i.sensorNodeName }))}
                //items={ {value: 1, label: 1} }
                value={sensorNodeDropIndex}
                setValue={setSensorNodeDropIndex}
              />


              {/* </SearchBox> */}

              {/* <SearchBox style={{marginTop:'15px'}}> */}

              {/* <Text><RequiredMark />{dictCk('조회단위(x)')}</Text> */}
              <ComboBox
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                defaultValue={dictCk('inquiry')+" "+dictCk('unit')}
                hasDefault={true}
                items={unitInquiryComboList.map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))}
                //items={ {value: 1, label: 1} }
                value={unitInquiryDropIndex}
                setValue={setUnitInquiryDropIndex}
              />
              {/* <Text><RequiredMark />{dictCk('sensorKind')}</Text> */}
              <ComboBox
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                defaultValue={dictCk('sensorKind')}
                hasDefault={true}
                items={sensorKindComboList.map((i) => ({ value: i.sensorKind,
                                                         label: i.sensorName == "온도" ? dictCk('temperature') :
                                                                i.sensorName == "습도" ? dictCk('humidity') :
                                                                i.sensorName == "CO2" ? dictCk('carbonDioxide') :
                                                                i.sensorName == "PH" ? dictCk('PH') :
                                                                i.sensorName == "EC" ? dictCk('EC') :
                                                                i.sensorName == "풍향" ? dictCk('windDirection') :
                                                                i.sensorName == "풍속" ? dictCk('weedSpeed') : 
                                                                i.sensorName == "일사" ? dictCk('solarRad') :
                                                                i.sensorName == "감우" ? dictCk('rainyWeather') :
                                                                i.sensorName == "수분부족량" ? dictCk('humidityDeficit') : ""
                                                    }))}
                //items={ {value: 1, label: 1} }
                value={sensorKindDropIndex}
                setValue={setSensorKindDropIndex}
              />
              <Text style={{fontSize:"16px", fontWeight:"bold", marginLeft:"7%", color:"#8a8e8b", marginBottom:"10px", marginTop:"30px"}}>{dictCk('inquiryPeriod')}</Text>
              <DateBox
                dateBoxId="dateBoxFrom"
                dateBoxInform={dictCk("dateFr")}
                style={{
                  width: "88%",
                  height: "16px",
                  marginLeft: "6%",
                }}
                stdDate={stdDate}
                setStdDate={setStdDate}
              />
              {/* <p style={{ width: "15px" }}>~</p> */}
              <DateBox
                dateBoxId="dateBoxTo"
                dateBoxInform={dictCk("dateTo")}
                style={{
                  width: "88%",
                  height: "16px",
                  marginLeft: "6%",
                  marginTop: "50px",
                }}
                stdDate={endDate}
                setStdDate={setEndDate}
                minDate={minDate} // 날짜 최저일
                maxDate={maxDate} // 날짜 최대일
              />
              <div style={{ paddingTop:"20px", marginTop: "60px", marginBottom:"20px", textAlign:"center",
                            justifyContent:"center", display:"flex", height:"60px"}}>
                {/* {chartLoading ? <InquiryMyChartBtn
                                  onClick={async () => { initChartDataList(), await onClickSearch() }}>{dictCk('search')}
                                </InquiryMyChartBtn> : <div><Oval height={50} width={50}/></div>} */}
                 <InquiryMyChartBtn
                   onClick={async () => { initChartDataList(), setCurrentPage(1), await onClickSearch() }}>{dictCk('search')}
                 </InquiryMyChartBtn>                         
              </div>
            </div>
            {/* </SearchBox>        */}
            <div>
              {/* <FlexBox style={{ marginBottom: "40px" }}> */}

              {/* </FlexBox> */}

            </div>

            <div style={{ width: '100%', height: '1050px', maxWidth: '1200px',
                          marginTop: '30px', marginBottom: '30px', justifyContent: 'center',
                          paddingTop: '60px', paddingBottom:'25px',
                          textAlign: 'center', backgroundColor: '#f9f9f9'}}>

            {MyChartComponent && <MyChartComponent chartDataList={Promise.all(chartDataList)}
                categoryList={categoryList}
                seriesList={seriesList}
                tooltipList={tooltipList}
                sensorKind={sensorKind}
                sensorMinMax={sensorMinMax}
                targetValues={targetValues}
                isChanged={myChartIsChanged}
                setIsChanged={setMyChartIsChanged}
              />}
            
              <div>
                 {/* 페이징 파트 */}
                 <Pagination
                   current={currentPage} // 현재페이지
                   setCurrent={setCurrentPage}
                   total={totalData} // 총데이터 숫자
                   count={dataCount} // 조회단위에 따른 카운팅갯수
                   setLoading={setLoading} // 페이지 변경시 로딩
                 />
              </div>
            </div>
          </WhiteBox>
        </Box>
      )}

      {tabIndex == 1 && (
        <Box id="secondBox">
          <WhiteBox  ref={secondBoxRef}>
            {/* <SearchBox> */}
            <div style={{marginTop:"20px"}}>
              {/* <Text><RequiredMark />{'작업장명'}</Text> */}
              <ComboBox
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                defaultValue={dictCk('workCenterName')}
                hasDefault={true}
                items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                value={compareWorkCenterDropIndex}
                setValue={setCompareWorkCenterDropIndex}
              />

              {/* <Text><RequiredMark />{'센서노드명'}</Text> */}
              <MultiComboBox
                className={'multiComboBox'}
                defaultValue={dictCk('sensorNodeName')}
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                placeholder={""}
                items={sensorNodeComboList.map((i) => ({ value: i.sensorNodeSeq, label: i.sensorNodeName }))}
                value={compareSensorNodeDropIndex}
                setValue={setCompareSensorNodeDropIndex}
              />

              {/* </SearchBox> */}

              {/* <SearchBox style={{marginTop:'15px'}}> */}
              {/* <Text><RequiredMark />{dictCk('조회단위')}</Text> */}
              <ComboBox
                style={{ width: "92%", height: "55px", marginLeft:"4%", marginBottom:"10px" }}
                defaultValue={dictCk('inquiry')+" "+dictCk('unit')}
                hasDefault={true}
                items={unitInquiryComboList.map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))}
                value={compareUnitInquiryDropIndex}
                setValue={setCompareUnitInquiryDropIndex}
              />
            </div>
            {/* </SearchBox>  */}
            <div>
              {/* <FlexBox style={{ marginBottom: "40px" }}> */}
                <Text style={{ fontSize:"16px", fontWeight:"bold", marginLeft:"7%", color:"#8a8e8b", marginBottom:"10px", marginTop:"10px" }}>{dictCk('inquiryPeriod')}</Text>
                <DateBox
                  dateBoxId="dateBoxFrom"
                  dateBoxInform={dictCk("dateFr")}
                  style={{
                    width: "88%",
                    height: "16px",
                    marginLeft: "6%",
                  }}
                  stdDate={stdDate}
                  setStdDate={setStdDate}
                />
                {/* <p style={{ width: "15px" }}>~</p> */}
                <DateBox
                  dateBoxId="dateBoxTo"
                  dateBoxInform={dictCk("dateTo")}
                  style={{
                    width: "88%",
                    height: "16px",
                    marginLeft: "6%",
                    marginTop: "50px",
                  }}
                  stdDate={endDate}
                  setStdDate={setEndDate}
                  minDate={minDate} // 날짜 최저일
                  maxDate={maxDate} // 날짜 최대일
                />
              {/* </FlexBox> */}
              {/* <div style={{ marginTop: "75px", textAlign:"center", justifyContent:"center", display:"flex" }}> */}
                {/* <FlexBox> */}
                  {/* {compareChartLoading ?
                    <div style={{ marginTop: "75px", textAlign:"center", justifyContent:"center", display:"flex", height: "60px" }}>
                      <BallTriangle height={40} width={50} />
                    </div>
                  :
                  <div style={{ marginTop: "75px", textAlign:"center", justifyContent:"center", display:"flex", height: "60px" }}>
                    <InquiryCompareChartBtn style={{
                                              marginRight:"9px",
                                              height: String(dictCk('temperature')).slice(0,1) == "온" ? "52px" : "70px" 
                                            }}
                                            onClick={async () => { initCompareChartDataList(), await onClickButton([10150001, 10150002, 10150004]) }}>
                      {String(dictCk('temperature')).slice(0,1) == "온" ?
                        dictCk('temperature')+"/"+dictCk('humidity')+"/"+dictCk('carbonDioxide')
                        : (dictCk('temperature')+"/"+dictCk('humidity')+"/"+dictCk('carbonDioxide')).replace(/\//g, "\n/")
                      }
                    </InquiryCompareChartBtn>
                    <InquiryCompareChartBtn style={{
                                              marginLeft:"9px",
                                              height: String(dictCk('temperature')).slice(0,1) == "온" ? "52px" : "70px"
                                            }}
                                            onClick={async () => { initCompareChartDataList(), await onClickButton([10150005, 10150006]) }}>
                      {dictCk('PH')+"/"+dictCk('EC')}
                    </InquiryCompareChartBtn>
                  </div>} */}

                  <div style={{ marginTop: "75px", textAlign:"center", justifyContent:"center", display:"flex", height: "60px" }}>
                    <InquiryCompareChartBtn style={{ marginRight:"9px"}}
                                            onClick={async () => { initCompareChartDataList(), setCurrentPage(1), await onClickButton([10150001, 10150002, 10150004]) }}>
                      {dictCk('온도/습도/CO2')}
                    </InquiryCompareChartBtn>
                    <InquiryCompareChartBtn style={{ marginLeft:"9px"}}
                                            onClick={async () => { initCompareChartDataList(), setCurrentPage(1), await onClickButton([10150005, 10150006]) }}>
                      {dictCk('PH/EC')}
                    </InquiryCompareChartBtn>
                  </div>

                  {/* {compareChartLoading ? <BallTriangle height={40} width={50} /> : <InquiryCompareChartBtn style={{ marginLeft:"9px"}}
                              onClick={async () => { initCompareChartDataList(), await onClickButton([10150005, 10150006]) }}>{dictCk('PH/EC')}</InquiryCompareChartBtn>} */}
                  {/* <div style={{ display: compareChartLoading ? "none" : "flex", marginTop: "15px", }}> <BallTriangle height={40} width={50} /> </div> */}
                {/* </FlexBox> */}
              {/* </div> */}
            </div>
            <div style={{ width: '100%', height: '1050px', maxWidth: '1200px',
                          marginTop: '30px', marginBottom: '30px', justifyContent: 'center',
                          paddingTop: '60px', paddingBottom:'25px',
                          textAlign: 'center', backgroundColor: '#f9f9f9' }}>
              
              {CompareChartComponent && <CompareChartComponent chartDataList={Promise.all(compareChartDataList)}
                categoryList={compareCategoryList}
                seriesList={compareSeriesList}
                tooltipList={compareTooltipList}
                sensorKind={compareSensorKind}
                sensorMinMax={compareSensorMinMax}
                yaxisList={compareYaxisList}
              />}
            
              <div>
                 {/* 페이징 파트 */}
                 <Pagination
                   current={currentPage} // 현재페이지
                   setCurrent={setCurrentPage}
                   total={totalData} // 총데이터 숫자
                   count={dataCount} // 조회단위에 따른 카운팅갯수
                   setLoading={setLoading} // 페이지 변경시 로딩
                 />
              </div>
            
            </div>

          </WhiteBox>
        </Box>
      )
      }    
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
`
// overflow-y: hidden;
// padding: 28px 10px 0px 10px;
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 15px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

const InquiryMyChartBtn = styled.button`
  width: 88%;
  height: 52px;
  font-size: 18px;
  background-color: white;
  color: #1eb544;
  font-weight: bold;
  letter-spacing: 10px;
  border-radius: 20px;
  border: 1px solid #1eb544;
  transition: background-color 0.2s; /* 클릭 효과를 부드럽게 만들기 위한 트랜지션 */

  /* 클릭 효과 스타일 */
  &:active {
    background-color: #1eb544;
    color: white;
  }
`
const InquiryCompareChartBtn = styled.button`
  width: 43%;
  height: 52px;
  font-size: 16px;
  background-color: white;
  color: #1eb544;
  font-weight: bold;
  /*letter-spacing: 10px;*/
  border-radius: 20px;
  border: 1px solid #1eb544;
  transition: background-color 0.2s; /* 클릭 효과를 부드럽게 만들기 위한 트랜지션 */

  /* 클릭 효과 스타일 */
  &:active {
    background-color: #1eb544;
    color: white;
  }
`



const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const HomeButton = styled.div`
  width: 100%;
  textAlign: center;
`

export default React.memo(CommonContainer(ChartContainer(withTranslation()(SensorDataInquiryComp))))