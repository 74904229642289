import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

import { TOKEN_ID } from "config"
import SignContainer from "../../containers/SignContainer"
import CommonContainer from "../../containers/CommonContainer"

import { Dialog, Slide } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { WidthToDP } from "../../utils/util"
import IconClose from "images/icon_close.png"
import IconUser from "images/icon_user.png"
import IconSetting from "images/icon_setting.png"

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiPaper-root": {
      backgroundColor: "#ffffff99",
      padding: `${WidthToDP(40)}px`,
    },
  },
}))

const HambergerPopup = (props) => {
  const classes = useStyles()
  const {
    t, open, onClose, postSignOut, userInfo,

    // 다국어 관련 추가 - 차건담 2023.11.06
    initTranslationWords,
    getTranslationWords,
    _translationWords,
    replaceWord,
  } = props

  useEffect(() => {
    getTranslationWords()
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.04

  const dictCk = (word) => {
    if (dict == undefined || dict == null || dict == {} || dict == "") {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const linkList = [
    { title: dictCk("env"), link: "/app/main" },
    { title: dictCk("cropReport"), link: "/app/crop/diary" },
    { title: dictCk("equipControl"), link: "/app/equip/equipCtrl" },
    { title: dictCk("alarmSearch"), link: "/app/alarmInfo/alarmInfoList" },
    { title: dictCk("cropHistory"), link: "/app/crop/CropHist" },
    { title: dictCk("asManage"), link: "/app/crop/AS" },
    { title: dictCk("scheduleManage"), link: "/app/schedule/manage" },
    { title: dictCk("equipLog"), link: "/app/log/EquiplogList" },
    { title: dictCk("changeTargetValueCondition"), link: "/app/autoConfReSetting/ConfReSettingAdd" },
  ]

  const onClickUser = () => console.log("")
  const onClickSetting = () => {
    navigate("/app/setting/defaultSetting")
  }

  return (
    <Dialog className={classes.container} open={open} onClose={onClose} fullScreen={true} transition={Slide} onClick={onClose} >
      <Slide direction="right" in={open} mountOnEnter unmountOnExit >
        <Container>
          <CloseButton onClick={onClose}>
            {/* 클로즈 아이콘 */}
            <img src={IconClose} />
          </CloseButton>

          <HeaderView>
            <Button onClick={onClickUser}>
              {/* 유저아이콘 */}
              <img src={IconUser} />
              <p>{userInfo ? userInfo.farm.farmOwner : ""}</p>
            </Button>
            <SettingButton onClick={onClickSetting}>
              {/* 세팅아이콘 */}
              <img src={IconSetting} />
            </SettingButton>
          </HeaderView>

          <CenterView>
            {linkList.map((i, idx) => (
              <StyledLink
                key={idx}
                onClick={() => navigate(i.link)}
                active={window.location.pathname.toLowerCase().includes(i.link.toLowerCase()) ? "on" : "off"}
              >
                {i.title}
              </StyledLink>
            ))}
          </CenterView>

          <BottomView>
            <LogoutButton
              onClick={async () => {
                const result = await postSignOut()

                if (result) {
                  navigate("/app/login")
                }
              }}
            >
              {t("logout")}
            </LogoutButton>
          </BottomView>
        </Container>
      </Slide>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #3e7347;
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${WidthToDP(433)}px;
  height: 100%;
  border-radius: ${WidthToDP(16)}px;
`
const CloseButton = styled.button`
  background-color: #ffffff00;
  position: absolute;
  right: -${WidthToDP(67)}px;
  width: ${WidthToDP(67)}px;
  height: ${WidthToDP(67)}px;
  & > img {
    width: ${WidthToDP(67)}px;
    height: ${WidthToDP(67)}px;
  }
`
const HeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${WidthToDP(40)}px ${WidthToDP(40)}px ${WidthToDP(88.5)}px;
  border-bottom: ${WidthToDP(2)}px solid #0000001a;
`
const Button = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > img {
    width: ${WidthToDP(40)}px;
    height: ${WidthToDP(52)}px;
  }
  & > p {
    margin-left: ${WidthToDP(24)}px;
    color: #fff;
    font-size: ${WidthToDP(30)}px;
  }
`
const SettingButton = styled.button`
  & > img {
    width: ${WidthToDP(50)}px;
    height: ${WidthToDP(50)}px;
  }
`
const CenterView = styled.div`
  flex: 1;
  width: 100%;
  padding-top: ${WidthToDP(40)}px;
`
const StyledLink = styled.button`
  width: 100%;
  padding: ${WidthToDP(29)}px ${WidthToDP(68)}px;
  color: ${(props) => (props.active == "on" ? "#fadb9a" : "#ffffffb3")};
  font-size: ${WidthToDP(30)}px;
  text-align: left;
`
const BottomView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${WidthToDP(40)}px;
  border-top: ${WidthToDP(2)}px solid #0000001a;
`
const LogoutButton = styled.button`
  width: 100%;
  height: 100;
  padding: ${WidthToDP(14)}px;
  color: #ffffff80;
  font-size: ${WidthToDP(25)}px;
  text-align: left;
`

HambergerPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CommonContainer(SignContainer(withTranslation()(HambergerPopup)))
