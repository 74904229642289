//앱 재배일지 추가/수정 팝업
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SEO from "../../seo"
import { withTranslation } from "react-i18next"

import Layout from "../../component/Layout"
import { WidthToDP } from "../../../utils/util"
import RequiredMark from "../../component/common/RequiredMark"
import moment from "moment"
import imageApis from "../../../apis/ImageApis"

import { GreenButton, GrayButton } from "../../component/common/Button"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import AttchImgsBox from "../../component/common/AttchImgsBox"
import BasicPopup from "../../popup/BasicPopup"
import MultiComboBox from "../../component/common/MultiComboBox"

import CropContainer from "../../../containers/CropContainer"

import CommonContainer from "../../../containers/CommonContainer"

import CircularIndeterminateSecond from "../../component/common/CircularIndeterminateSecond" //로딩화면

const DiaryAdd = (props) => {
  const {
    t,
    open,
    postCropReportAdd,
    postCropReportMod,
    sminorComboList,
    uminorComboList,
    // getSMinorComboList,
    // getUMinorComboList,
    // getCropComboList,
    cropComboList,
    staffComboList, // 재배인력 리스트 - 차건담 2023.04.04
    getCropGrowthComboList,
    cropGrowthComboList,
    getWorkCenterComboList,
    workCenterComboList,
    close,
    infoItem,
    getCropReportList,

    getTranslationWords, 
    _translationWords, 
    replaceWord,

  } = props

  const TODAY = moment()

  const [stdDate, setStdDate] = useState(TODAY)
  const [value, setValue] = useState("") //작기
  const [centerValue, setCenterValue] = useState(0) //작업장
  const [grownValue, setGrownValue] = useState(0) //생육단계
  const [cropValue, setCropValue] = useState(0) //재배활동
  const [eventValue, setEventValue] = useState(0) //재배이벤트
  const [harvestValue, setHarvestValue] = useState("") //수확수량
  const [workValue, setWorkValue] = useState("") //주요작업
  const [remarkValue, setRemarkValue] = useState("") //비고
  const [attachImages, setAttachImages] = useState([]) //이미지 업로드
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [staffList, setStaffList] = useState([]) //재배인력
  const [useTime, setUseTime] = useState(0)

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const [loading, setLoading] = useState(false) //사진업로드 중에 로딩화면

  const getSubData = async () => {
    await getWorkCenterComboList(value) //작업장콤보
    await getCropGrowthComboList(value) //생육단계콤보
  }

  const getData = async () => {
    if (infoItem && infoItem.cropReportSeq) {
      setStdDate(Object.keys(infoItem).length > 0 ? infoItem.cropReportDate : "") //날짜
      setValue(Object.keys(infoItem).length > 0 ? infoItem.cropSeq : "") //작기
      setCenterValue(Object.keys(infoItem).length > 0 ? infoItem.workCenterSeq : "") //작업장
      setGrownValue(Object.keys(infoItem).length > 0 ? infoItem.cropGrowthSeq : "") //생육단계
      setCropValue(Object.keys(infoItem).length > 0 ? infoItem.cropActionSeq : "") //재배활동
      setEventValue(Object.keys(infoItem).length > 0 ? infoItem.cropEventSeq : "") //재배이벤트
      setHarvestValue(Object.keys(infoItem).length > 0 ? (infoItem.cropReportQty > 0 ? infoItem.cropReportQty : "") : "") //수확수량
      setStaffList(
        Object.keys(infoItem).length > 0 ? infoItem.staffList.map((i) => ({ value: i.staffSeq, label: i.staffName })) : []
      ) //재배인력 - 차건담 2023.04.04
      setUseTime(Object.keys(infoItem).length > 0 ? infoItem.useTime : 0) //인력사용시간 - 차건담 2023.04.04
      setWorkValue(Object.keys(infoItem).length > 0 ? infoItem.work : "") //주요작업
      setRemarkValue(Object.keys(infoItem).length > 0 ? infoItem.remark : "") //비고
      setAttachImages(
        infoItem.imageList && infoItem.imageList.length > 0
          ? infoItem.imageList.map((i) => ({ imageUrl: i.fileUri, fileName: i.fileName }))
          : attachImages
      ) //사진
    }
    // await getSMinorComboList() //공통콤보
    // await getUMinorComboList() //사용자정의콤보
  }

  useEffect(() => {
    getTranslationWords()
    if (open) getData()
  }, [open])

  useEffect(() => {
    if (value > 0) getSubData()
  }, [value])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    let hiddenTarget = cropGrowthComboList.filter((i) => (i.cropGrowthSeq == grownValue))
    let isHarv = '0'
    
    if (hiddenTarget.length > 0) {
      const obj = JSON.stringify(hiddenTarget[0])
      const jsonObj = JSON.parse(obj)
      isHarv = jsonObj['isHarv']

      if (isHarv == '0') {
        document.getElementById("cropQty").style.display = 'none'
        setHarvestValue(0)
       
      } else {
        document.getElementById("cropQty").style.display = 'flex'
    
      }
    }
  
  }, [close, grownValue]) // grownValue 값 기준 >> close, grownValue 값 기준으로 변경 - 차건담 2023.04.05

  const postData = async () => {
    if (checkRequiredField()) {
      let images = []

      if (attachImages.length > 0) {
        const formData = new FormData()

        for (let i = 0; i < attachImages.length; i++) {
          if (attachImages[i].file) {
            formData.append("images", attachImages[i].file)
          } else {
            images.push({ fileUrl: attachImages[i].imageUrl, fileName: attachImages[i].fileName })
          }
        } // end for

        const uploadsResult = await imageApis.uploadImages(formData)

        images.push(...uploadsResult.data.images)
      }

      let _staffList = staffList.map( (i) => i.value ) // 차건담 - 2023.04.04

      const data = {
        cropReportSeq: 0, // 차건담 - 2023.04.04
        cropReportDate: moment(stdDate).format("YYYYMMDD"),

        cropSeq: value,
        workCenterSeq: centerValue,
        cropGrowthSeq: grownValue,
        cropActionSeq: cropValue,
        cropEventSeq: eventValue,
        cropReportQty: harvestValue == "" ? 0 : harvestValue,
        work: workValue,
        imageUris: images.length > 0 ? images.map((i) => ({ fileUri: i.fileUrl, fileName: i.fileName })) : [],
        remark: remarkValue,
        staffList: _staffList, //재배인력
        useTime: useTime //인력사용시간
      }
      const data2 = { page: 0, count: 5, queryWord: "" }

      if (infoItem && infoItem.cropReportSeq) {
        data.cropReportSeq = infoItem.cropReportSeq
        const result = await postCropReportMod(data)
        const result2 = await getCropReportList(data2)
        if (result && result2) {
          close()
          // setLoading(false) //로딩화면 종료
        }
      } else {
        const result = await postCropReportAdd(data)
        const result2 = await getCropReportList(data2)
        if (result && result2) {
          close()
          // setLoading(false) //로딩화면 종료
        }
      }
      setLoading(false) //로딩화면 종료
    } // end if
  }

  const findCtrl = () => {
    let temp = 0
    if (cropValue == 10120001) {
      temp = 1011
    } else if (cropValue == 10120002) {
      temp = 1012
    } else if (cropValue == 10120003) {
      temp = 1010
    } else {
      temp = 0
    }
    return temp
  }

  // 필수 입력사항 체크 
  const checkRequiredField = () => {
    let msg = ""

    if (value <= 0) { // 작기명
      msg = replaceWord(dict['n.Please select a product.'], dict['cropName']) 
    } else if (centerValue <= 0 && workCenterComboList.length > 0) { //작업장
      msg = replaceWord(dict['n.Please select a product.'], dict['workCenter'])
    } else if (grownValue <= 0 && cropGrowthComboList.length > 0) { //생육단계
      msg = replaceWord(dict['n.Please select a product.'], dict['growthStep'])
    } else if (cropValue <= 0 && sminorComboList.length > 0) { //재배활동
      msg = replaceWord(dict['n.Please select a product.'], dict['cropAction'])
    } else if (eventValue <= 0) { //재배이벤트
      msg = replaceWord(dict['n.Please select a product.'], dict['cropEvent'])
    } else if (workValue <= "") { //주요작업
      msg = replaceWord(dict["n.Invalid value. Please try again."], dict['importWork'])
    } else if (cropComboList.filter((i) =>i.cropSeq == value).map((i) =>i.cropDateFr)[0] > stdDate || stdDate > cropComboList.filter((i) =>i.cropSeq == value).map((i) =>i.cropDateTo)[0]) { // 등록일
      
      let cropDateFr = cropComboList.filter((i) =>i.cropSeq == value).map((i) =>i.cropDateFr)[0]; //작기 시작일
      let cropDateTo = cropComboList.filter((i) =>i.cropSeq == value).map((i) =>i.cropDateTo)[0]; //작기 종료일
    
      msg = replaceWord(dict["n.Please check the contents."], dict['crop']+dict['days']) + "/" + cropDateFr + "~" + cropDateTo // 작기시작일 작기종료일 사이날짜에만 등록가능
    }

    if (msg != "") {
      setPopup({ open: true, text: msg })
    } else {
      setLoading(true) //로딩화면 시작
      return true
    }
    
  }

  return (
    <Layout
      isHeader={true} // default : false
      isBack={true}
      backHandler={(e) => {
        close()
      }}
      headerBgType={1} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("cropReportReg")}
    >
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dictCk("confirm")]} />
      <CircularIndeterminateSecond open={loading} /> 
      {/* 로딩화면 */}
      <Container>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("regDate")}
            </DefaltFont>
          </section>
          <DateBox style={{ width: "100%" }} stdDate={stdDate} setStdDate={setStdDate}></DateBox>
        </SubjectSection>

        {/* 작기명 - 차건담 2023.04.04 */}
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("cropName")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={cropComboList && cropComboList.map((row) => ({ value: row.cropSeq, label: row.cropName }))}
            value={value}
            setValue={setValue}
          />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("workCenter")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={value ? workCenterComboList && workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName })) : []}
            value={centerValue}
            setValue={setCenterValue}
          />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("growthStep")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            //items={value ? cropGrowthComboList && cropGrowthComboList.map((row) => ({ value: row.cropGrowthSeq, label: row.work })) : []}
            items={cropGrowthComboList.map((i) => ({ value: i.cropGrowthSeq, label: i.work }))}
            value={grownValue}
            setValue={setGrownValue}
          />
        </SubjectSection>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("cropAction")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={
              sminorComboList && sminorComboList.filter((row) => row.majorSeq == "1012").map((row) => ({ value: row.minorSeq, label: row.minorName }))
            }
            value={cropValue}
            setValue={setCropValue}
          />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
             <RequiredMark />
              {dictCk("cropEvent")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={
              cropValue > 0
                ? uminorComboList &&
                  uminorComboList.filter((row) => row.majorSeq == findCtrl()).map((row) => ({ value: row.minorSeq, label: row.minorName }))
                : []
            }
            value={eventValue}
            setValue={setEventValue}
          />
        </SubjectSection>

        {/* 수량 >> 수확여부에 따라 diplay none/flex - 차건담 2023.04.04 */}
        <SubjectSection id="cropQty" style={{display:'none'}}>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("quantity")}</DefaltFont>
          </section>
          <InputBox style={{ width: "100%" }} type="number" value={harvestValue} onChange={(e) => setHarvestValue(e.target.value)} />
        </SubjectSection>

        {/* 재배인력 - 차건담 2023.04.04 */}
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("cultivationManpower")}</DefaltFont>
          </section>
          {/* <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={
              staffComboList.map((i) => ({ value: i.staffSeq, label: i.staffName }))
            }
            value={staffList}
            setValue={setStaffList}
          /> */}
              <MultiComboBox
                // style={{width : '220px'}}
                style={{ backgroundColor: "#ffffff", width: "100%" }}
                placeholder={dictCk('multiSelect')}
                items={staffComboList.map((i) => ({ value: i.staffSeq, label: i.staffName }))}
                value={staffList}
                setValue={setStaffList}
                // disabled={isRowClick==1}
              />
        </SubjectSection>

        {/* 인력사용시간 - 차건담 2023.04.04 */}
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("manpower")+dictCk("usageTime")}</DefaltFont>
          </section>
          <InputBox style={{ width: "100%" }} type="number" value={useTime} onChange={(e) => setUseTime(e.target.value)} />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("importWork")}
            </DefaltFont>
          </section>
          <DefaultTextArea value={workValue} onChange={(e) => setWorkValue(e.target.value)} />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("remark")}</DefaltFont>
          </section>
          <DefaultTextArea value={remarkValue} onChange={(e) => setRemarkValue(e.target.value)} />
        </SubjectSection>

        {/* 이미지 업로드  시작 */}
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px`, width: "100%", overflow: "hidden" }}>
            <TextTitle>{dictCk("uplaodImage")}</TextTitle>
          </section>

          <AttchImgsBox style={{ width: "100%", overflowX: "auto" }} images={attachImages} setImages={setAttachImages} maxCnt={50} />
        </SubjectSection>
        {/* 이미지 업로드  끝 */}
        <div style={{ display: "flex", marginTop: `${WidthToDP(54)}px` }}>
          <GrayButton width={`50%`} style={{ marginRight: `${WidthToDP(15)}px` }} onClick={() => close()}>
            {dictCk("cancel")}
          </GrayButton>
          <GreenButton
            width={`50%`}
            style={{}}
            onClick={() => {
              // checkRequiredField()
              postData()
            }}
          >
            {dictCk("save")}
          </GreenButton>
        </div>
      </Container>
    </Layout>
  )
}

export default CropContainer(CommonContainer(withTranslation()(DiaryAdd)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(30)}px ${WidthToDP(60)}px;
  background-color: #f6f6f6;
`
const DefaltFont = styled.p`
  font-size: ${WidthToDP(30)}px;
  opacity: 0.8;
  font-family: NotoSansCJKkr;
  line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(37)}px`)};
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  color: ${(props) => (props.color ? props.color : "#555555")};
`

const SubjectSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
`

const DefaultTextArea = styled.textarea`
  overflow-y: auto;
  height: ${WidthToDP(300)}px;
  width: 100%;
  resize: none;
`
const TextTitle = styled.p`
  opacity: 0.8;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(30)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
