import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { WidthToDP, androidBackHandler } from "../../utils/util"
import AlarmInfoContainer from "../../containers/AlarmInfoContainer"
import BasicPopup from "../popup/BasicPopup"

import CommonContainer from "../../containers/CommonContainer" 

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
      margin: "25px",
    },
  },
}))

const AlarmContentsPopup = (props) => {
  const classes = useStyles()
  const { t, typeData, open, onClose, selectedValue, items, postInfoUserAlarmMod, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [tapIndex, setTapIndex] = useState(0)

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.06 app

  useEffect(() => {
    if (items && Object.keys(items).length > 0) {
      setTapIndex(items.alarmKind)
    }
  }, [items])

  useEffect(() => {
    getTranslationWords()
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/alarmInfo/alarmInfoList", { replace: true })
    })
  }, [])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  return (
    <Dialog className={classes.container} open={open} onClose={onClose} fullWidth={true}>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[t("close")]} />
      <div style={{ display: "flex", flexDirection: "column", maxHeight: "700px", padding: `${WidthToDP(30)}px` }}>
        {/* 본문 */}
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
          {/* 제목 */}
          <section style={{ display: "flex" }}>
            <TapDiv color={typeData[tapIndex].color}>
              <TapFont>{typeData[tapIndex].subject}</TapFont>
            </TapDiv>
            <div style={{ width: `${WidthToDP(350)}px` }}>
              <ContentFont>{items.title}</ContentFont>
            </div>
            <div
              onClick={async () => {
                let msg = ""
                let result = await postInfoUserAlarmMod({
                  userAlarmSeq: items.userAlarmSeq,
                })
                if (result) {
                  msg = dictCk("successfully deleted.")
                  setPopup({ open: true, text: msg })
                } else {
                  msg = dictCk("temporary Error. Please try again.")
                  setPopup({ open: true, text: msg })
                }
              }}
            >
              <DelFont>{dictCk("del")}</DelFont>
            </div>
          </section>
          <section style={{ display: "flex", flexDirection: "column", height: "100px" }}>
            {/* 날짜 */}
            <section style={{ display: "flex", width: "100%", marginBottom: `${WidthToDP(32)}px` }}>
              <ContentFont fs={`${WidthToDP(25)}px`} mr={`${WidthToDP(8)}px`}>
                {items.regDateTime}
              </ContentFont>
            </section>
            {/* 내용 */}
            <section style={{ width: "100%", minHeight: `${WidthToDP(147)}px` }}>
              <ContentFont>{items.content}</ContentFont>
            </section>
          </section>
        </div>

        <ButtonView>
          {selectedValue.map((item, idx) => {
            return (
              <Button key={idx} one={selectedValue.length === 2 && idx === 0} onClick={() => onClose(item, items)}>
                {item}
              </Button>
            )
          })}
        </ButtonView>
      </div>
    </Dialog>
  )
}

AlarmContentsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.array.isRequired,
}

export default AlarmInfoContainer(CommonContainer(withTranslation()(AlarmContentsPopup)))

const ButtonView = styled.section`
  display: flex;
  justify-content: flex-end;
  height: 50px;
`

const Button = styled.button`
  background-color: #ffffff;
  width: 100%;
  /* padding: 0px; */
  padding-right: ${WidthToDP(30)}px;
  text-align: end;
  /* border-top: 1px solid #dddde5; */
  border-radius: 0;
  color: ${(props) => (props.one ? "#5e6a79" : "#555555")};
  font-size: ${WidthToDP(27)}px;
  letter-spacing: -0.54px;
`
const TapDiv = styled.div`
  display: flex;
  height: ${WidthToDP(47)}px;
  width: ${WidthToDP(144)}px;
  justify-content: center;
  align-items: center;
  border-radius: ${WidthToDP(6)}px;
  background-color: ${(props) => (props.color ? props.color : "#022808")};
  margin-right: ${(props) => (props.mr ? "" : `${WidthToDP(24)}px`)};
  margin-bottom: ${WidthToDP(20)}px;
`
const TapFont = styled.p`
  line-height: ${WidthToDP(37)}px;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
`
const DelFont = styled.p`
  line-height: ${WidthToDP(40)}px;
  opacity: 0.63;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(27)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.54px;
  text-align: center;
  color: #ff1414;
`
const ContentFont = styled.p`
  opacity: 0.7;
  font-family: NotoSansCJKkr;
  font-size: ${(props) => (props.fs ? "" : `${WidthToDP(30)}px`)};
  margin-right: ${(props) => (props.mr ? props.mr : "")};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
