import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { WidthToDP } from "../../../utils/util"
import icon_selector from "images/selector.png"

const ComboBox = (props) => {
  const { t, style = {}, defaultValue, hasDefault, items, value, setValue } = props

  const [open, setOpen] = useState(false)
  const [blur, setBlur] = useState(true)
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    const dataList = (hasDefault ? [{ value: 0, label: defaultValue }] : []).concat(items)

    setDataList(dataList)
  }, [items])

  return (
    <StyledDropdown style={{ ...style }} onBlur={() => (blur ? setOpen(false) : null)}>
      <SelectButton type="button" color={value <= 0 && hasDefault ? "#55555566" : "#555555"} onClick={() => setOpen(!open)}>
        <p>{dataList.filter((row) => row.value == value).length > 0 ? t(dataList.filter((row) => row.value == value)[0].label) : ""}</p>
        <img src={icon_selector} alt={""} />
      </SelectButton>
      {open ? (
        <Options onMouseOver={() => setBlur(false)} onMouseOut={() => setBlur(true)}>
          {dataList.map((data) => (
            <button
              key={data.value}
              onClick={() => {
                setValue(data.value, data.label)
                setOpen(false)
              }}
            >
              <p>&nbsp;&nbsp;&nbsp;{t(data.label)}</p>
            </button>
          ))}
        </Options>
      ) : null}
    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  background-color: #ffffff;
  width: ${WidthToDP(440)}px;
  margin-right: ${WidthToDP(60)}px;
`
const SelectButton = styled.button`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${WidthToDP(100)}px;
  border: 1px solid #dedede;
  border-radius: ${WidthToDP(6)}px;
  & > p {
    padding: 0 ${WidthToDP(20)}px;
    color: ${(props) => props.color};
    font-size: ${WidthToDP(25)}px;
  }
  & > img {
    position: absolute;
    right: ${WidthToDP(16)}px;
    width: ${WidthToDP(48)}px;
    height: ${WidthToDP(48)}px;
  }
  &:focus {
    border: ${WidthToDP(2)}px solid #52935d;
  }
`
const Options = styled.div`
  overflow-y: auto;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: auto;
  max-height: ${WidthToDP(410)}px;
  z-index: 1000;
  border: ${WidthToDP(2)}px solid #dedede;
  border-radius: ${WidthToDP(6)}px;
  & > button {
    background-color: #ffffff00;
    width: 100%;
    height: ${WidthToDP(100)}px;
    text-align: left;
    border-bottom: ${WidthToDP(2)}px solid #dedede;
    &:last-child {
      border-bottom: none;
    }
  }
`

export default withTranslation()(ComboBox)
