import React, { useState, forwardRef } from "react"
import styled, { css, createGlobalStyle } from "styled-components"

import { WidthToDP } from "../../../utils/util"

import icon_defaultImg from "images/defaultImg.png"

const AttchImageBox = (props) => {
  const { style = {}, images, setImages, maxCnt } = props

  //이미지 미리보기용 base64변경
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  return (
    <StyledAttchImageBox alignItems={"flex-start"} style={{}}>
      <input
        id="farmImgaeInputRef"
        style={{ display: "none" }}
        type="file"
        accept={"image/*"}
        value={""}
        onChange={async (e) => {
          if (e.target.files[0]) {
            const file = e.target.files[0]
            // console.log('@@@@@@@@@@@@@@파일',file)
            // console.log('@@@@@@@@@@@@@@파일사이즈file.size::',file.size)
            if (file.size > 20 * 1024 * 1024) { // 파일용량이 50MB 넘을시 반환
              alert("파일 용량이 20MB를 넘어 저장이 불가합니다.");
              return;
            }

            const base64 = await toBase64(file)
            const temp = [...images]
            temp.push({ base64, file, imageUrl: null })
            setImages(temp)
          }
        }}
      />
      <button
        style={{ width: "", height: "" }}
        onClick={() => {
          if (images.length < maxCnt) {
            document.getElementById("farmImgaeInputRef").click()
          }
        }}
      >
        <img style={{ width: `${WidthToDP(193)}px`, height: `${WidthToDP(193)}px`, borderRadius: `${WidthToDP(3)}px` }} src={icon_defaultImg} />
      </button>
      <div>
        {images.map((image, imageIndex) => (
          <div
            key={imageIndex}
            style={{
              position: "relative",
              width: `${WidthToDP(193)}px`,
              height: `${WidthToDP(193)}px`,
              marginLeft: `${WidthToDP(12)}px`,
              border: `${WidthToDP(1)}px solid #dddddd`,
            }}
          >
            <img style={{ width: `${WidthToDP(190)}px`, height: `${WidthToDP(190)}px` }} src={image.base64 || image.imageUrl} />
            <button
              onClick={() => {
                const temp = [...images]
                temp.splice(imageIndex, 1)
                setImages(temp)
              }}
              style={{
                backgroundColor: "#abbfae",
                position: "absolute",
                top: `${WidthToDP(2)}px`,
                right: `${WidthToDP(2)}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: `${WidthToDP(40)}px`,
                height: `${WidthToDP(40)}px`,
                paddingBottom: `${WidthToDP(2)}px`,
                border: `${WidthToDP(1)}px solid #fff`,
                borderRadius: "50%",
                color: "#000",
                fontSize: `${WidthToDP(18)}px`,
                fontWeight: 700,
              }}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </StyledAttchImageBox>
  )
}

const StyledAttchImageBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

export default AttchImageBox
