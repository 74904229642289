import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { Dialog } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import { navigate } from "@reach/router"
import { WidthToDP, androidBackHandler } from "utils/util"
import ScheduleContainer from "../../containers/ScheduleContainer"
import Layout from "../component/Layout"
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import ComboBox from "../component/common/ComboBox"
import CheckBox from "../component/common/CheckBox"
import RequiredMark from "../component/common/RequiredMark"
import BasicPopup from "./BasicPopup"

import CommonContainer from "../../containers/CommonContainer"

const CropGrowthAddPopupPlan = (props) => {
  const { t, open, onClose } = props
  const { cropComboList, workCenterComboList, _cropGrowth } = props
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord,} = props


  const [popup, setPopup] = useState({ open: false, text: "" })
  // const [dateFr, setDateFr] = useState("")
  // const [dateTo, setDateTo] = useState("")
  // const [timeFr, setTimeFr] = useState("")
  // const [timeTo, setTimeTo] = useState("")
  // const [isAlarm, setIsAlarm] = useState(false)
  // const [cropSeq, setCropSeq] = useState(0)
  // const [workCenterSeq, setWorkCenterseq] = useState(0)
  // const [harvPlanQty, setHarvPlanQty] = useState("")
  // const [content, setContent] = useState("")
  // const [cropAlarm, setCropAlarm] = useState(false)

  const [growthStepName, setGrowthStepName] = useState("")
  const [dateFr, setDateFr] = useState("")
  const [date, setDate] = useState("")
  const [work, setWork] = useState("")
  const [isHarv, setIsHarv] = useState(false)
  const [isPlant, setIsPlant] = useState(false)
  const [checkPoint, setCheckPoint] = useState("")
  const [remark, setRemark] = useState("")
  const [cropPlanQty, setCropPlanQty] = useState("")


  useEffect(() => {
    
    if (_cropGrowth.growthStepSeq) {
      setGrowthStepName(_cropGrowth.growthStepName)
      setDateFr(_cropGrowth.dateFr)
      setDate(_cropGrowth.date)
      setWork(_cropGrowth.work)
      setIsHarv(_cropGrowth.isHarv == 1)
      setIsPlant(_cropGrowth.isPlant == 1)
      setCheckPoint(_cropGrowth.checkPoint)
      setRemark(_cropGrowth.remark)
      setCropPlanQty(_cropGrowth.cropPlanQty)

    }
    getTranslationWords()
    return () => {
      setGrowthStepName("")
      setDateFr("")
      setDate("")
      setWork("")
      setIsHarv(false)
      setIsPlant(false)
      setCheckPoint("")
      setRemark("")
      setCropPlanQty("")
    }
  }, [open])

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/schedule/manage", { replace: true })
    })
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dictCk("confirm")]} />
      <Layout
        isHeader={true} // default : false
        headerBgType={1} // default : 0 => 0: 흰색, 1: 주황색
        isBack={true} // default : false
        backHandler={onClose}
        title={dictCk("stdCropGrowth")}
      >
        <Container>
          <Title><RequiredMark />{dictCk('growthStep')}</Title>
          <FlexView>
          <InputBox type="text" value={growthStepName} onChange={(e) => setGrowthStepName(e.target.value)} disabled />
          </FlexView>

          <Title><RequiredMark />{dictCk("dateFr")}</Title>
          <FlexView>
          <InputBox type="text" value={dateFr} onChange={(e) => setDateFr(e.target.value)} disabled />
          </FlexView>

          <Title><RequiredMark />{dictCk("elapsedDays")}</Title>
          <FlexView>
          <InputBox type="number" value={date} onChange={(e) => setDate(e.target.value)} disabled/>
          </FlexView>

          <Title>{dictCk("importWork")}</Title>
          <FlexView>
          <InputBox type="text" value={work} onChange={(e) => setWork(e.target.value)} disabled/>
          </FlexView>

          <Title>{dictCk("productionPlanQuantity")}</Title>
          <FlexView>
          <InputBox type="text" value={cropPlanQty} onChange={(e) => setCropPlanQty(e.target.value)} disabled/>
          </FlexView>

          <Title>{dictCk("isHarv")}</Title>
          <FlexView>
          <CheckBox check={isHarv} setCheck={setIsHarv} disabled/>
          </FlexView>

          <Title>{dictCk("isPlant")}</Title>
          <FlexView>
          <CheckBox check={isPlant} setCheck={setIsPlant} disabled/>
          </FlexView>

          <Title>{dictCk("checkPoint")}</Title>
          <TextArea value={checkPoint} onChange={(e) => setCheckPoint(e.target.value)} disabled></TextArea>

          <Title>{dictCk("checkPoint")}</Title>
          <TextArea value={remark} onChange={(e) => setRemark(e.target.value)} disabled></TextArea>
  
          
          <FlexView style={{ marginTop: `${WidthToDP(51)}px`, marginBottom: `${WidthToDP(46)}px` }}>
            <Button bgColor={"#fff"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose(dictCk("cancel"), null)}>
              {dictCk("cancel")}
            </Button>
          </FlexView>
        </Container>
      </Layout>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: ${WidthToDP(30)}px ${WidthToDP(60)}px 0;
`
const Title = styled.p`
  margin-top: ${WidthToDP(20)}px;
  color: #555555cc;
  font-size: ${WidthToDP(30)}px;
`
const FlexView = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-top: ${WidthToDP(16)}px;
`
const TextArea = styled.textarea`
  width: ${WidthToDP(600)}px;
  height: ${WidthToDP(276)}px;
  margin-top: ${WidthToDP(16)}px;
  resize: none;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  /* width: ${WidthToDP(280)}px; */
  height: ${WidthToDP(100)}px;
  margin-left: ${WidthToDP(40)}px;
  border: ${(props) => props.border};
  border-radius: 4px;
  color: ${(props) => props.color};
  font-size: ${WidthToDP(28)}px;
  font-weight: 500;

  &:first-child {
    margin-left: 0;
  }
`

CropGrowthAddPopupPlan.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ScheduleContainer(CommonContainer(withTranslation()(CropGrowthAddPopupPlan)))
