import React from "react"
import styled from "styled-components"
import { WidthToDP } from "utils/util"
import ICON_WRITE from "images/icon-write.svg"

const WriteButton = ({ onClick = () => {} }) => {
  return (
    <ButtonContainer>
      <Img src={ICON_WRITE} onClick={onClick} />
    </ButtonContainer>
  )
}

export default WriteButton

const ButtonContainer = styled.button`
  position: absolute;
  right: ${WidthToDP(40)}px;
  bottom: ${WidthToDP(40)}px;
`
const Img = styled.img`
  width: ${WidthToDP(114)}px;
  height: ${WidthToDP(114)}px;
`
