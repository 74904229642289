import React from "react"
import styled from "styled-components"
import icon_checkS from "images/circleCheck_green.png"
import icon_check from "images/circleCheck_gray.png"

const CircleCheckBox = ({ text, check, setCheck, style = {}, boxStyle = {}, labelStyle = {} }) => {
  return (
    <StyledCheckBox type="button" style={{ ...style }} onClick={() => setCheck(!check)}>
      {/* 체크아이콘 */}
      <CheckIcon src={check ? icon_checkS : icon_check} style={{ ...boxStyle }} />
      <Label color={check ? "#52935d" : "#555555"} opacity={check ? "100%" : "60%"} style={{ ...labelStyle }}>
        {text}
      </Label>
    </StyledCheckBox>
  )
}

const StyledCheckBox = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`
const CheckIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`
const Label = styled.p`
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color};
  font-size: 14px;
`

export default CircleCheckBox
