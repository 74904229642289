import { createAction } from "redux-actions"
import SampleApis from "../apis/SampleApis"

/* action type */
export const SET_SAMPLE = "@elefarm-app/sample/SET_SAMPLE"

/* action function */
export const setSample = createAction(SET_SAMPLE)

/* action api function */
export const getSample = (param) => async (dispatch) => {
  const result = await SampleApis.getSample(param)
  if (result.code == 0) {
    dispatch(setSample(result))
    return true
  } else return false
}

export const postSample = (param) => async (dispatch) => {
  const result = await SampleApis.postSample(param)
  if (result.code == 0) {
    return true
  } else return false
}
