import api from "../utils/api"


// 콤보 (WorkCenter, Gateway, ControlNode)
const getComboList = async (data) => {
    const result = await api.get(`/system/combo/list?queryKind=${data.queryKind}&cropSeq=${data.cropSeq || 0}`)
    return result.data
  }

// 센서데이터 조회================================================================
  // 차트데이터 가져오기 - 차건담 2023.05.11
  const getChartData = async (data) => {
    //const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}`)
    const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}`)
    return result.data
  }

  const getCompareChartData = async (data) => {
    //const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}`)
    const result = await api.get(`/sensorData/compareChart/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}`)
    return result.data
  }

  const getChartComboList = async (data) => {
    const result = await api.get(`/system/combo/list`, { body: data })
    return result.data
  }

  // 기간내 센서데이터 최소값 최대값 가져오기 - 차건담 2023.05.24
  const getSensorMinMax = async (data) => {
    const result = await api.get(`/sensorData/minmax/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}&&tab=${data.tab}`)
    return result.data
  } 

  // 센서 목표값 가져오기 - 차건담 2023.06.12
  const getTargetValueList = async (data) => {
    const result = await api.get(`/sensorData/targetValue/list?sensorKind=${data.sensorKind}`)
    return result.data
  }   

  // 센서 목표값 저장하기 - 차건담 2023.06.13
  const insertTargetValueList = async (data) => {
    const result = await api.post(`/sensorData/targetValue/insert`, { body: data })
    return result.data
  }
// ==============================================================================



export default {
    getComboList,
  // 센서데이터 ==================================================
    // 센서데이터 조회 차트 데이터 가져오기 - 차건담 2023.05.11
    getChartData,

    // 센서데이터 조회 비교 차트 데이터 가져오기 - 차건담 2023.06.01
    getCompareChartData,
    
    // 센서데이터 콤보리스트 가져오기 - 차건담 2023.05.12
    getChartComboList,

    // 기간내 센서데이터 최소값 최대값 가져오기 - 차건담 2023.05.24
    getSensorMinMax,

    // 센서 목표값 가져오기 - 차건담 2023.06.12
    getTargetValueList,

    // 센서 목표값 저장하기 - 차건담 2023.06.12
    insertTargetValueList
  // ============================================================
}