import React from "react"
import styled from "styled-components"
import icon_check from "images/checkBasic.png"
import icon_check_select from "images/checkBasic_select.png"
import { WidthToDP } from "../../../utils/util"

const CheckBox = ({ text, check, setCheck, disabled = false, style = {} }) => {
  return (
    <CheckButton
      style={{ ...style }}
      onClick={() => {
        if (disabled) return
        setCheck(!check)
      }}
    >
      {/* 체크아이콘 */}
      <CheckIcon src={check ? icon_check_select : icon_check} />
      <Label>{text}</Label>
    </CheckButton>
  )
}

const CheckButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`
const CheckIcon = styled.img`
  width: 22px;
  height: 22px;
`

const Label = styled.p`
  /* opacity: 80%; */
  margin-left: ${WidthToDP(23)}px;
  color: #555555;
  font-size: ${WidthToDP(30)}px;
  line-height: ${WidthToDP(45)}px;
`

export default CheckBox
