import * as ConfigAction from "./ConfigAction"
import * as SampleAction from "./SampleAction"
import * as SignAction from "./SignAction"
import * as MainAction from "./MainAction"
import * as CropAction from "./CropAction"
import * as EquipAction from "./EquipAction"
import * as SettingAction from "./SettingAction"
import * as CommonAction from "./CommonAction"
import * as AlarmInfoAction from "./AlarmInfoAction"
import * as ScheduleAction from "./ScheduleAction"
import * as ChartAction from "./ChartAction"
import * as LogAction from "./LogAction"
import * as EnvControlAction from "./EnvControlAction"

export default {
  ConfigAction,
  SampleAction,
  SignAction,
  MainAction,
  CropAction,
  EquipAction,
  SettingAction,
  CommonAction,
  AlarmInfoAction,
  ScheduleAction,
  ChartAction,
  LogAction,
  EnvControlAction,
}
