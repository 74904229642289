// 앱 헤더
import React, { useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import styled from "styled-components"
import { WidthToDP } from "../../utils/util"
import HambergerPopup from "../popup/HambergerPopup"
import InputBox from "./common/InputBox"

import IconBackBlack from "images/icon_back_black.png"
import IconBackWhite from "images/icon_back_white.png"
import IconHamberger from "images/icon_hamberger.png"
import IconMenu from "images/icon_Menu.png"
import IconAlarmOn from "images/icon_alarm_on.png"
import IconAlarmOff from "images/icon_alarm_off.png"
import IconSearch from "images/icon_search.png"

const Header = (props) => {
  // left
  const { isHeader = false, headerBgType = 0, isBack = false, backHandler = () => navigate(-1), isHam = false, title = "농장이름" } = props
  // right
  const {
    isAlarm = false,
    alarmHandler = () => {},
    alarmCount = 0,
    isSearch,
    searchHandler = () => {},
    openSearch,
    inputValue,
    setInputValue,
  } = props
  const [popup, setPopup] = useState(false)

  return (
    isHeader && (
      <Container headerBgType={headerBgType}>
        <HambergerPopup open={popup} onClose={() => setPopup(false)} />
        <LeftComponent>
          {isBack && (
            <BackButton onClick={backHandler}>
              {/* 뒤로가기 */}
              <img src={headerBgType == 0 ? IconBackBlack : IconBackWhite} />
            </BackButton>
          )}
          {isHam && (
            <HamButton onClick={() => setPopup(true)}>
              {/* 햄버거 메뉴버튼 */}
              <img src={IconMenu} />
            </HamButton>
          )}
          <Title headerBgType={headerBgType}>{title}</Title>
        </LeftComponent>

        <RightComponent openSearch={openSearch}>
          {/* 
          {isAlarm && (
            <>
              <AlarmText>{alarmCount}</AlarmText> 
              <AlarmButton onClick={()=>{navigate("/app/alarmInfo/alarmInfoList");}}>
                
                <img src={alarmCount > 0 ? IconAlarmOn : IconAlarmOff} />
              </AlarmButton>
            </>
          )}
          // 오류로 인해 임시 삭제*/}
          {isSearch && (
            <>
              {openSearch && (
                <InputBox
                  style={{ width: "100%", marginRight: "0px", marginLeft: "22px" }}
                  type="text"
                  disabled={false}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}
              {/* 검색 */}
              <SearchButton onClick={searchHandler}>
                <img src={IconSearch} />
              </SearchButton>
            </>
          )}
        </RightComponent>
      </Container>
    )
  )
}

const Container = styled.div`
  background-color: ${(props) => (props.headerBgType == 0 ? "#fff" : "#fd5757")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${WidthToDP(104.5)}px;
  border-bottom: ${WidthToDP(2)}px solid #00C73C;
`
const LeftComponent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const BackButton = styled.button`
  width: ${WidthToDP(104.5)}px;
  height: ${WidthToDP(104.5)}px;
  & > img {
    width: ${WidthToDP(20)}px;
    height: ${WidthToDP(36)}px;
  }
`
const HamButton = styled.button`
  width: ${WidthToDP(104.5)}px;
  height: ${WidthToDP(104.5)}px;
  & > img {
    width: ${WidthToDP(43)}px;
    height: ${WidthToDP(40)}px;
  }
`
const Title = styled.p`
  padding-bottom: ${WidthToDP(12)}px;
  color: ${(props) => (props.headerBgType == 0 ? "#555555" : "#fff")};
  font-size: ${WidthToDP(32)}px;
`
const RightComponent = styled.div`
  display: flex;
  flex: ${(props) => (props.openSearch ? "1" : "")};
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.openSearch ? "100%" : "")};
`
const AlarmText = styled.p`
  color: #55555599;
  font-size: ${WidthToDP(25)}px;
`
const AlarmButton = styled.button`
  width: ${WidthToDP(104.5)}px;
  height: ${WidthToDP(104.5)}px;
  & > img {
    width: ${WidthToDP(50)}px;
    height: ${WidthToDP(57)}px;
  }
`
const SearchButton = styled.button`
  width: ${WidthToDP(104.5)}px;
  height: ${WidthToDP(104.5)}px;
  & > img {
    width: ${WidthToDP(40)}px;
    height: ${WidthToDP(40.7)}px;
  }
`

Header.propTypes = {
  isHeader: PropTypes.bool,
  title: PropTypes.string,
  leftComponent: PropTypes.any,
  rightComponent: PropTypes.any,
}

export default Header
