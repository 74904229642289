import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    //조건제어[복합]-목표값 조건변경
    SET_CONF_RESETTING_INFO,
} = actions.EnvControlAction

// default state value
const initialState = {
    _confReSettingInfo: {},
}

// handle action
export default handleActions(
    {
        
        //조건제어[복합] - 목표값 조건변경
        [SET_CONF_RESETTING_INFO]: (state, action) => ({
            ...state,
            _confReSettingInfo: action.payload,
        }),

    },

    initialState
)
