import styled from "styled-components"
import { WidthToDP } from "../../../utils/util"

export const YellowButton = styled.button`
  background-color: #fadb56;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;
  border: ${WidthToDP(1)}px solid #e9c11a;
  border-radius: ${WidthToDP(4)}px;
  color: #555555;
  font-size: ${WidthToDP(28)}px;
  /* &:hover {
    background-color: #f9cd08;
    border: ${WidthToDP(1)}px solid #e0b80a;
  } */
`
export const SkyButton = styled.button`
  background-color: #aed9fa;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;
  border: ${WidthToDP(1)}px solid #84beeb;
  border-radius: ${WidthToDP(4)}px;
  color: #555555;
  font-size: ${WidthToDP(28)}px;
  /* &:hover {
    background-color: #8cccfb;
    border: ${WidthToDP(1)}px solid #63ade6;
  } */
`
export const GreenButton = styled.button`
  background-color: #52935d;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;
  border-radius: ${WidthToDP(4)}px;
  color: #fff;
  font-size: ${WidthToDP(28)}px;
  font-weight: 500;
  /* &:hover {
    background-color: #3e7347;
  } */
`
export const GrayButton = styled.button`
  background-color: #f2f2f2;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;
  border: ${WidthToDP(1)}px solid #cccccc;
  border-radius: ${WidthToDP(4)}px;
  color: #55555599;
  font-size: ${WidthToDP(28)}px;
  /* &:hover {
    background-color: #e4e4e4;
    border: ${WidthToDP(1)}px solid #bebdbd;
  } */
`
