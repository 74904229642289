import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const INIT_PRODUCTION_PLAN_LIST = "@elefarm/production/INIT_PRODUCTION_PLAN_LIST"
export const SET_PRODUCTION_PLAN_LIST = "@elefarm/production/SET_PRODUCTION_PLAN_LIST"
export const INIT_PRODUCTION_PLAN = "@elefarm/production/INIT_PRODUCTION_PLAN"
export const SET_PRODUCTION_PLAN = "@elefarm/production/SET_PRODUCTION_PLAN"

// [작기]생육정보
export const INIT_CROP_GROWTH = "@elefarm/cropManage/INIT_CROP_GROWTH"
export const SET_CROP_GROWTH = "@elefarm/cropManage/SET_CROP_GROWTH"

/* action function */
export const initProductionPlanList = createAction(INIT_PRODUCTION_PLAN_LIST)
export const setProductionPlanList = createAction(SET_PRODUCTION_PLAN_LIST)
export const initProductionPlan = createAction(INIT_PRODUCTION_PLAN)
export const setProductionPlan = createAction(SET_PRODUCTION_PLAN)
// [작기]생육정보
export const initCropGrowth = createAction(INIT_CROP_GROWTH)
export const setCropGrowth = createAction(SET_CROP_GROWTH)

/* action api function */
// 일정관리 월 리스트
export const getProductionPlanMonthList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/plan/month/list", param)
  if (result.code === 0) {
    dispatch(setProductionPlanList(result.data.planMonthList))
    return true
  } else return false
}
// 일정관리 일 리스트
export const getProductionPlanDayList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/plan/day/list", param)
  if (result.code === 0) {
    dispatch(setProductionPlanList(result.data.PlanDayList))
    return true
  } else return false
}
// 일정관리 추가
export const addProductionPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/plan/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 일정관리 정보
export const getProductionPlan = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/plan/info", param)
  if (result.code === 0) {
    dispatch(setProductionPlan(result.data.PlanInfo[0]))
    return true
  } else return false
}
// 일정관리 정보수정
export const editProductionPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/plan/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 일정관리 삭제
export const delProductionPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/plan/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 작기정보 생육정보
export const getCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropGrowth/info", param)
  if (result.code === 0) {
    dispatch(setCropGrowth(result.data.cropGrowthInfo))
    return true
  } else return false
}
