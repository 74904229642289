import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_SETTING_INFO } = actions.SettingAction
const { SET_SMINOR_COMBO_LIST, SET_UMINOR_COMBO_LIST } = actions.CommonAction

// default state value
const initialState = {
  settingInfo: {},
  sminorComboList: [],
  uminorComboList: [],
}

// handle action
export default handleActions(
  {
    [SET_SETTING_INFO]: (state, action) => ({
      ...state,
      settingInfo: action.payload,
    }),
    [SET_SMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      sminorComboList: action.payload,
    }),
    [SET_UMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      uminorComboList: action.payload,
    }),
  },
  initialState
)
