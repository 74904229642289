// 앱 >일정관리 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { Dialog } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import { navigate } from "@reach/router"
import { WidthToDP, androidBackHandler } from "utils/util"
import ScheduleContainer from "../../containers/ScheduleContainer"
import Layout from "../component/Layout"
import DateBox from "../component/common/DateBox"
import InputBox from "../component/common/InputBox"
import ComboBox from "../component/common/ComboBox"
import CheckBox from "../component/common/CheckBox"
import RequiredMark from "../component/common/RequiredMark"
import BasicPopup from "../popup/BasicPopup"

import CommonContainer from "../../containers/CommonContainer"

const ScheduleAddPopup = (props) => {
  const { t, open, onClose } = props
  const { cropComboList, workCenterComboList, _productionPlan } = props
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord,} = props


  const [popup, setPopup] = useState({ open: false, text: "" })
  const [dateFr, setDateFr] = useState("")
  const [dateTo, setDateTo] = useState("")
  const [timeFr, setTimeFr] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [isAlarm, setIsAlarm] = useState(false)
  const [cropSeq, setCropSeq] = useState(0)
  const [workCenterSeq, setWorkCenterseq] = useState(0)
  const [harvPlanQty, setHarvPlanQty] = useState("")
  const [content, setContent] = useState("")

  const [cropAlarm, setCropAlarm] = useState(false)

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    setCropAlarm(false)
    if (_productionPlan.planSeq) {
      setDateFr(moment(_productionPlan.dateFr, "YYYYMMDD").isValid() ? moment(_productionPlan.dateFr, "YYYYMMDD") : "")
      setDateTo(moment(_productionPlan.dateTo, "YYYYMMDD").isValid() ? moment(_productionPlan.dateTo, "YYYYMMDD") : "")
      setTimeFr(_productionPlan.timeFr || "")
      setTimeTo(_productionPlan.timeTo || "")
      setIsAlarm(_productionPlan.isAlarm == 1)
      setCropSeq(_productionPlan.cropSeq || 0)
      setWorkCenterseq(_productionPlan.workCenterSeq || 0)
      setHarvPlanQty(_productionPlan.harvPlanQty)
      setContent(_productionPlan.content)
      setCropAlarm(_productionPlan.cropSeq ? 1 : 0 )
    }
    getTranslationWords()
    return () => {
      setDateFr("")
      setDateTo("")
      setTimeFr("")
      setTimeTo("")
      setIsAlarm(false)
      setCropSeq(0)
      setWorkCenterseq(0)
      setHarvPlanQty("")
      setContent("")
    }
  }, [open])

  // 달력 날짜 제한 관련
  useEffect(() => {
    // console.log("@@@@@@@@@@@@@@@typeof dateFr::",typeof dateFr); 
    if(typeof dateFr =="string" && dateFr != undefined && dateFr != "" && dateFr != null){

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)
  }else if(typeof dateFr == "object" && dateFr._i != undefined && dateFr._i != "" && dateFr._i != null){

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr._i.substr(0, 4) + '-' + dateFr._i.substr(4, 2) + '-' + dateFr._i.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr._i.substr(0,4)+'-'+dateFr._i.substr(4,2)+'-'+dateFr._i.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }

  }, [dateFr])

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onSelectcropAlarm = async (value) => {
    setCropAlarm(value)
    if ( value == 0){ setCropSeq(0), setWorkCenterseq(0), setHarvPlanQty("") }
  
  }

  const onClickSave = async () => {
    // console.log('김한중')
    // console.log(cropComboList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0])
    // console.log(cropComboList)
    
    if (dateFr == "" || dateTo == "") return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dictCk('date')) })
    
    else if (cropComboList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0] > (typeof dateFr == 'string' ? dateFr: dateFr.format('YYYYMMDD')) || (typeof dateFr == 'string' ? dateFr: dateFr.format('YYYYMMDD')) > cropComboList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateTo)[0]) 
    return setPopup({ open: true, text: replaceWord(dict["n.Please check the contents."], dictCk('crop')+dictCk('days')) })
    else if (cropComboList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0] > (typeof dateTo == 'string' ? dateTo: dateTo.format('YYYYMMDD')) || (typeof dateTo == 'string' ? dateTo: dateTo.format('YYYYMMDD')) > cropComboList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateTo)[0]) 
    return setPopup({ open: true, text: replaceWord(dict["n.Please check the contents."], dictCk('crop')+dictCk('days')) })

    else if (cropAlarm == 1 && cropSeq == 0) return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dictCk('cropName')) })
    else if (cropAlarm == 1 && workCenterSeq == 0) return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dictCk('workCenterName')) })
    else if (cropAlarm == 1 && harvPlanQty === '') return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dictCk('harvestPlanQuantity')) })
    else if (content == "") return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dictCk('content')) })

    let param = {
      cropSeq,
      workCenterSeq,
      dateFr: moment(dateFr).format("YYYYMMDD"),
      dateTo: moment(dateTo).format("YYYYMMDD"),
      timeFr: timeFr || "",
      timeTo: timeTo || "",
      isAlarm: isAlarm ? 1 : 0,
      harvPlanQty: harvPlanQty || 0,
      content,
    }
    if (_productionPlan.planSeq) param.planSeq = _productionPlan.planSeq

    onClose(dictCk("save"), param)
  }

  const onClickDelete = async () => {
    if (_productionPlan.planSeq) onClose(dictCk("delete"), { planSeq: _productionPlan.planSeq })
  }

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/schedule/manage", { replace: true })
    })
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dictCk("confirm")]} />
      <Layout
        isHeader={true} // default : false
        headerBgType={1} // default : 0 => 0: 흰색, 1: 주황색
        isBack={true} // default : false
        backHandler={onClose}
        title={dictCk("scheduleManage")}
      >
        <Container>
          <Title><RequiredMark />{dictCk('harvestPlan')}</Title>
          <FlexView>
            <CheckBox check={cropAlarm} setCheck={onSelectcropAlarm} />
          </FlexView>

          <Title><RequiredMark />{dictCk("date")}</Title>
          <FlexView>
            <DateBox style={{ flex: 1, marginRight: 0 }} stdDate={dateFr} setStdDate={setDateFr} />
            <p style={{ margin: `0 ${WidthToDP(16)}px`, color: "#555555e6", fontSize: WidthToDP(30) }}>~</p>
            <DateBox style={{ flex: 1, marginRight: 0 }} stdDate={dateTo} setStdDate={setDateTo} minDate={minDate} maxDate={maxDate} />
          </FlexView>
          <Title>{dictCk("time(24h)")}</Title>
          <FlexView>
            <InputBox style={{ flex: 1, marginRight: 0 }} type="text" value={timeFr} onChange={(e) => setTimeFr(e.target.value)} />
            <p style={{ margin: `0 ${WidthToDP(16)}px`, color: "#555555e6", fontSize: WidthToDP(30) }}>~</p>
            <InputBox style={{ flex: 1, marginRight: 0 }} type="text" value={timeTo} onChange={(e) => setTimeTo(e.target.value)} />
          </FlexView>
          <Title style={{display: cropAlarm == 0 ? 'none' : 'flex'}}><RequiredMark />{dictCk("cropName")}</Title>
          <ComboBox
            style={{ width: "100%", marginTop: `${WidthToDP(16)}px`, display: cropAlarm == 0 ? 'none' : 'flex' }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={cropComboList.map((i) => ({ value: i.cropSeq, label: i.cropName }))}
            value={cropSeq}
            setValue={setCropSeq}
          />
          <Title style={{display: cropAlarm == 0 ? 'none' : 'flex'}}><RequiredMark />{dictCk("workCenterName")}</Title>
          <ComboBox
            style={{ width: "100%", marginTop: `${WidthToDP(16)}px`, display: cropAlarm == 0 ? 'none' : 'flex' }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
            value={workCenterSeq}
            setValue={setWorkCenterseq}
          />
          <Title style={{display: cropAlarm == 0 ? 'none' : 'flex'}}><RequiredMark />{dictCk("harvestPlanQuantity")}</Title>
          <InputBox
            style={{ marginTop: `${WidthToDP(16)}px`, textAlign: "right", display: cropAlarm == 0 ? 'none' : 'flex' }}
            type="text"
            value={harvPlanQty}
            onChange={(e) => setHarvPlanQty(e.target.value)}
          />
          <Title><RequiredMark />{dictCk("content")}</Title>
          <TextArea value={content} onChange={(e) => setContent(e.target.value)}></TextArea>
          <FlexView style={{ marginTop: `${WidthToDP(51)}px`, marginBottom: `${WidthToDP(46)}px` }}>
            <Button bgColor={"#fff"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose(dictCk("cancel"), null)}>
              {dictCk("cancel")}
            </Button>
            {_productionPlan.planSeq && (
              <Button bgColor={"#fa5b59"} border={"none"} color={"#fff"} onClick={onClickDelete}>
                {dictCk("delete")}
              </Button>
            )}
            <Button bgColor={"#52935d"} border={"none"} color={"#fff"} onClick={onClickSave}>
              {dictCk("save")}
            </Button>
          </FlexView>
        </Container>
      </Layout>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: ${WidthToDP(30)}px ${WidthToDP(60)}px 0;
`
const Title = styled.p`
  margin-top: ${WidthToDP(20)}px;
  color: #555555cc;
  font-size: ${WidthToDP(30)}px;
`
const FlexView = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-top: ${WidthToDP(16)}px;
`
const TextArea = styled.textarea`
  width: ${WidthToDP(600)}px;
  height: ${WidthToDP(276)}px;
  margin-top: ${WidthToDP(16)}px;
  resize: none;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  /* width: ${WidthToDP(280)}px; */
  height: ${WidthToDP(100)}px;
  margin-left: ${WidthToDP(40)}px;
  border: ${(props) => props.border};
  border-radius: 4px;
  color: ${(props) => props.color};
  font-size: ${WidthToDP(28)}px;
  font-weight: 500;

  &:first-child {
    margin-left: 0;
  }
`

ScheduleAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ScheduleContainer(CommonContainer(withTranslation()(ScheduleAddPopup)))
