// 로그관련 - 차건담 2023.10.12
import { handleActions } from "redux-actions"
import actions from "../actions"

const {
    INIT_LOG_LIST, SET_LOG_LIST,
    SET_OPENPER_EQUIP_LIST,
    SET_ONOFF_EQUIP_LIST,
    SET_SWITCH_EQUIP_LIST,

    INIT_EQUIP_LOG_LIST, SET_EQUIP_LOG_LIST,
} = actions.LogAction

const initialState = {
    _logList: [],
    _openPerEquipList: [],
    _onOffEquipList: [],
    _switchEquipList: [],
    _equipLogList: [],
}

export default handleActions(
    {
        [INIT_LOG_LIST]: (state, action) => ({
            ...state,
            _logList: [],
        }),

        [SET_LOG_LIST]: (state, action) => ({
            ...state,
            _logList: action.payload,
        }),

        [SET_OPENPER_EQUIP_LIST]: (state, action) => ({
            ...state,
            _openPerEquipList: action.payload,
        }),

        [SET_ONOFF_EQUIP_LIST]: (state, action) => ({
            ...state,
            _onOffEquipList: action.payload,
        }),

        [SET_SWITCH_EQUIP_LIST]: (state, action) => ({
            ...state,
            _switchEquipList: action.payload,
        }),

        [INIT_EQUIP_LOG_LIST]: (state, action) => ({
            ...state,
            _equipLogList: [],
        }),

        [SET_EQUIP_LOG_LIST]: (state, action) => ({
            ...state,
            _equipLogList: action.payload,
        }),
    },
    initialState
)