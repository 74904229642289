import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { WidthToDP, isApp, isIPhone } from "../../utils/util"
import i18n from "../../lang/i18n"

import SignContainer from "../../containers/SignContainer"
import BasicPopup from "../popup/BasicPopup"
import CircleCheckBox from "../component/common/CircleCheckBox"
import icon_logo from "images/elefarm.png"

import ComboBox from "../component/common/ComboBox"

import GoogleChartSample from "./mainMenu/GoogleChartSample"
import DygraphChartSample from "./mainMenu/DygraphChartSample"

const Login = (props) => {
  const { t, postSignIn } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [check, setCheck] = useState(false)

  const [lang, setLang] = useState(i18n.language || "ko")

  const onSetLang = (languageCode) => {
    setLang(languageCode)
    i18n.changeLanguage(languageCode)
  }

  useEffect(() => {
    window.NativeLoginDataReceive = async (_data) => {
      loginFunction({
        signType: _data.LoginData.SignType,
        userId: _data.LoginData.ID,
        userPw: _data.LoginData.Password,
        isAutoLogin: _data.LoginData.IsAutoLogin,

        snsToken: "",
        pushToken: _data.LoginData.FCMToken,
        deviceNo: _data.LoginData.DeviceNo,
      })

      localStorage.setItem("deviceNo", _data.LoginData.DeviceNo)

      return "success"
    }
  }, [])

  const loginFunction = async (data) => {
    const result = await postSignIn(data)

    if (result) {
      // if (check) localStorage.setItem("userId", id)
      // else localStorage.removeItem("userId")

      navigate("/app/main")
    }
  }

  const onClickLogin = async () => {
    if (id === "" || password === "") return setPopup({ open: true, text: t("n.Please check the id or password.") })

    const data = {
      signType: 0,
      userId: id,
      userPw: password,
      isAutoLogin: check ? 1 : 0,
      snsToken: "",
      pushToken: "",
      deviceNo: "WEB",
    }

    if (isApp) {
      window.callNative(
        JSON.stringify({
          Type: "GetFCMToken",
          FunctionName: "NativeLoginDataReceive",
          data: {
            LoginData: {
              ID: data.userId,
              Password: data.userPw,
              SignType: data.signType,
              IsAutoLogin: data.isAutoLogin,
            },
          },
        })
      )
    } else {
      loginFunction(data)
    } // end if
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[t("confirm")]} />

      <InputBox
        onSubmit={(e) => {
          e.preventDefault()
          onClickLogin()
        }}
      >
        {/* <GoogleChartSample></GoogleChartSample> */}
        {/* <div style={{width:'200px'}}>
          <DygraphChartSample style={{width:'100%'}}></DygraphChartSample>
        </div> */}
        <LogoDiv>
          <img style={{ width: "49px", height: "49px", marginRight: "16px" }} src={icon_logo} />
          <LogoTextDiv>{t("logoName")}</LogoTextDiv>
        </LogoDiv>
        <TextDiv>{t("id")}</TextDiv>
        <Input
          placeholder={t("n.Invalid value. Please try again.", { n: t("id") })}
          type={"text"}
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <TextDiv>{t("password")}</TextDiv>
        <Input
          placeholder={t("n.Invalid value. Please try again.", { n: t("password") })}
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextDiv>{t("language")}</TextDiv>
        <ComboBox
          style={{ width: "100%", marginRight: 0 }}
          defaultValue={""}
          hasDefault={false}
          items={[
            { value: "en", label: "English" },
            { value: "ko", label: "한국어" },
            { value: "vi", label: "Tiếng Việt" },
            { value: "zh", label: "简体中文" },
            { value: "tw", label: "繁體中文" },
            { value: "ja", label: "日本語" },
          ]}
          value={lang}
          setValue={onSetLang}
        />
        <AutoLoginDiv>
          <CircleCheckBox
            style={{ height: `${WidthToDP(45)}px` }}
            boxStyle={{ height: `${WidthToDP(45)}px`, width: `${WidthToDP(45)}px` }}
            labelStyle={{ fontSize: `${WidthToDP(25)}px`, lineHeight: `${WidthToDP(35)}px` }}
            text={t("Keep me signed in")}
            check={check}
            setCheck={setCheck}
          />
        </AutoLoginDiv>
        <LoginButton type="submit" onClick={onClickLogin}>
          {t("login")}
        </LoginButton>
      </InputBox>
    </Container>
  )
}

const LogoTextDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${WidthToDP(51)}px;
  align-items: center;
  font-size: ${WidthToDP(25)}px;
  line-height: ${WidthToDP(37)}px;
  color: #555555;
`
const TextDiv = styled.div`
  display: flex;
  width: 100%;
  height: ${WidthToDP(80)}px;
  align-items: center;
  font-size: ${WidthToDP(30)}px;
  line-height: ${WidthToDP(45)}px;
  color: #555555;
`
const AutoLoginDiv = styled.div`
  display: flex;
  width: 100%;
  height: ${WidthToDP(105)}px;
  align-items: center;
  font-size: ${WidthToDP(25)}px;
  line-height: ${WidthToDP(32)}px;
  color: #555555;
`
const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${WidthToDP(286)}px;
  align-items: center;
  font-size: ${WidthToDP(25)}px;
  line-height: ${WidthToDP(32)}px;
  margin-top: ${WidthToDP(26)}px; ;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
`
const InputBox = styled.form`
  width: ${WidthToDP(600)}px;
`
const Input = styled.input`
  display: block;
  width: 100%;
  height: ${WidthToDP(100)}px;
  margin-bottom: ${WidthToDP(10)}px;
  padding: 0 10px;
  color: #000;
  font-size: ${WidthToDP(25)}px;
  line-height: ${WidthToDP(50)}px;
  letter-spacing: -0.9px;
  border: 1.5px solid #dedede;
`
const LoginButton = styled.button`
  background-color: #dbdbdb;
  width: 100%;
  height: ${WidthToDP(100)}px;
  color: #ffffff;
  font-size: ${WidthToDP(28)}px;
  line-height: ${WidthToDP(50)}px;
  letter-spacing: -0.9px;
  background-color: #52935d;
  border-radius: ${WidthToDP(4)}px;
`

export default SignContainer(withTranslation()(Login))
