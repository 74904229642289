import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { INIT_PRODUCTION_PLAN_LIST, SET_PRODUCTION_PLAN_LIST, INIT_PRODUCTION_PLAN, SET_PRODUCTION_PLAN, INIT_CROP_GROWTH, SET_CROP_GROWTH } = actions.ScheduleAction

// default state value
const initialState = {
  _productionPlanList: [],
  _productionPlan: {},

  _cropGrowth: {},

}

// handle action
export default handleActions(
  {
    [INIT_PRODUCTION_PLAN_LIST]: (state, action) => ({
      ...state,
      _productionPlanList: [],
    }),
    [SET_PRODUCTION_PLAN_LIST]: (state, action) => ({
      ...state,
      _productionPlanList: action.payload,
    }),
    [INIT_PRODUCTION_PLAN]: (state, action) => ({
      ...state,
      _productionPlan: {},
    }),
    [SET_PRODUCTION_PLAN]: (state, action) => ({
      ...state,
      _productionPlan: action.payload,
    }),

    [INIT_CROP_GROWTH]: (state, action) => ({
      ...state,
      _cropGrowth: {},
    }),
    [SET_CROP_GROWTH]: (state, action) => ({
      ...state,
      _cropGrowth: action.payload,
    }),
  },
  initialState
)
