import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../component/Layout"
import { WidthToDP } from "../../utils/util"
import Switch from "../component/common/Switch"
import CircleCheckBox from "../component/common/CircleCheckBox"
import CheckBox from "../component/common/CheckBox"
import Radio from "../component/common/Radio"

import { YellowButton, SkyButton, GreenButton, GrayButton } from "../component/common/Button"

import DateBox from "../component/common/DateBox"
import ComboBox from "../component/common/ComboBox"
import MultiComboBox from "../component/common/MultiComboBox"
import InputBox from "../component/common/InputBox"
import InputYMBox from "../component/common/InputYMBox"
import InputTimeBox from "../component/common/InputTimeBox"
import InputTelBox from "../component/common/InputTelBox"

const Sample = (props) => {
  const [switchOn, setSwitchOn] = useState(false)
  const [check, setCheck] = useState(false)
  const [radioIndex, setRadioIndex] = useState(0)

  const [stdDate, setStdDate] = useState("20211019")
  const [value, setValue] = useState(0)
  const [inputValue0, setInputValue0] = useState("0103") // 월일 
  const [inputValue1, setInputValue1] = useState("1111") // 시분 
  const [inputValue2, setInputValue2] = useState("") // 핸드폰 번호 
  const [inputValue3, setInputValue3] = useState("") // 핸드폰 번호 
  const [inputValue4, setInputValue4] = useState("") // 전화 번호 
  const [inputValue5, setInputValue5] = useState("") // 이름 
  const [inputValue6, setInputValue6] = useState("") // 수량 
  const [inputValue7, setInputValue7] = useState("") // 금액 

  const items = [
    { value: 1, label: "a" },
    { value: 2, label: "b선택" },
    { value: 3, label: "c" },
  ]

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      isBack={true} // default : false
      backHandler={() => console.log("백버튼 핸들러")}
      isHam={true} // default : false
      title="웅이버지 농장"
      isAlarm={true} // default : false
      alarmHandler={() => console.log("알람 핸들러")}
      isSearch={true} // default : false
      searchHandler={() => console.log("검색 핸들러")}
    >
      <Container>

        <p>0. 스위치 컴포넌트</p>
        <Switch bool={switchOn} setBool={setSwitchOn} />
        <hr />
        <Title>1. 버튼</Title>
        <YellowButton width={`100%`}>조회</YellowButton>
        <SkyButton width={`100%`}>추가</SkyButton>
        <GreenButton width={`100%`} style={{ marginTop: `${WidthToDP(15)}px` }}>
          저장
        </GreenButton>
        <div style={{ display: "flex", marginTop: `${WidthToDP(15)}px` }}>
          <GrayButton width={`50%`} style={{ marginRight: `${WidthToDP(15)}px` }}>
            취소
          </GrayButton>
          <GreenButton width={`50%`} style={{}}>
            저장
          </GreenButton>
        </div>
        <hr />
        
        <p>2. 체크박스</p>
        <CircleCheckBox style={{}} text="체크박스" check={check} setCheck={setCheck} />
        <CheckBox style={{}} check={check} setCheck={setCheck} />
        <hr />
        <Title>3. 드롭다운</Title>
          
        <MultiComboBox style={{}} placeholder={"멀티선택"} items={items} value={value} setValue={setValue} />
        <ComboBox style={{}} defaultValue={"선택"} hasDefault={true} items={items} value={value} setValue={setValue} />
        <DateBox style={{}} stdDate={stdDate} setStdDate={setStdDate}/>

        <InputYMBox style={{ }} disabled={false} value={inputValue0} setValue={setInputValue0} />
        <InputTimeBox style={{ }} disabled={false} value={inputValue1} setValue={setInputValue1} />

        <InputBox style={{ }} placeholder={"000 - 00 - 00000"} type="text" disabled={false} mask="### - ## - #####" 
          value={inputValue2} onChange={(e)=>setInputValue2(e.target.value)}  
        />

        <InputBox style={{ }} placeholder={"010 - 0000 - 0000"} type="text" mask="010 - #### - ####" 
          value={inputValue3} onChange={(e)=>setInputValue3(e.target.value)}  
        />

        <InputTelBox style={{ }} value={inputValue4} setValue={setInputValue4} />

        <InputBox style={{ }} placeholder={"이름"} type="text" 
          value={inputValue5} onChange={(e)=>setInputValue5(e.target.value)}  
        />

        <InputBox placeholder={"수량"} type="number" 
          value={inputValue6} onChange={(e)=>setInputValue6(e.target.value)}  
        />

        <InputBox placeholder={"금액"} type="number" useComma={true} maxLength={5}
          value={inputValue7} onChange={(e)=>setInputValue7(e.target.value)}  
        />

        <hr />
        ={stdDate}=
        ={inputValue0}=
        ={inputValue1}=
        ={inputValue2}=
        ={inputValue3}=
        ={inputValue4}=
        ={inputValue5}=
        ={inputValue6}=
        ={inputValue7}=
        <hr />
        
        <p>4. 라디오 버튼</p>
        <Radio style={{}} text="라디오1" idx={0} check={radioIndex} setCheck={setRadioIndex} />
        <Radio style={{}} text="라디오2" idx={1} check={radioIndex} setCheck={setRadioIndex} />
        
        <hr />
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${WidthToDP(30)}px;
`

const Title = styled.p`
  font-size: 20px;
`

export default Sample
