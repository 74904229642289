import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_EQUIP_CONTROL_LIST, SET_EQUIP_ONOFF_CONTROL_LIST, SET_EQUIP_CONTROL_STATUS, INIT_EQUIP_CONTROL_STATUS } = actions.EquipAction
const { SET_WORK_CENTER_LIST } = actions.MainAction

// default state value
const initialState = {
  equipControlList: [],
  workCenterList: [],
  equipOnOffControlList: [],

  _equipControlStatus: {},
}

// handle action
export default handleActions(
  {
    [SET_EQUIP_CONTROL_LIST]: (state, action) => ({
      ...state,
      equipControlList: action.payload,
    }),
    [SET_WORK_CENTER_LIST]: (state, action) => ({
      ...state,
      workCenterList: action.payload,
    }),
    [SET_EQUIP_ONOFF_CONTROL_LIST]: (state, action) => ({
      ...state,
      equipOnOffControlList: action.payload,
    }),

    [SET_EQUIP_CONTROL_STATUS]: (state, action) => ({
      ...state,
      _equipControlStatus: action.payload,
    }),
    [INIT_EQUIP_CONTROL_STATUS]: (state, action) => ({
      ...state,
      _equipControlStatus: {},
    }),
  },
  initialState
)
