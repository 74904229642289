import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Dialog } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#52935d",
    animationDuration: "1000ms",
  },
  container: {
    width: "100%",
    // maxWidth: "421px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      width: "50px",
    },
    "& .MuiDialog-paperScrollPaper": {
      height: "50px",
    },
  },
}))

const CircularIndeterminate = (props) => {
  const classes = useStyles()

  const { open, setOpen } = props

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      open={open}
      fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <CircularProgress className={classes.root} />
    </Dialog>
  )
}

export default CircularIndeterminate
