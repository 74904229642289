// 로그관련 - 차건담 2023.10.12
import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
    initLogList, getLogList,
    getOpenPerEquipList, getOnOffEquipList, getSwitchEquipList,
    initEquipLogList, getEquipLogList
} = actions.LogAction

const mapStateToProps = (state) => ({
    _logList: state.LogReducer._logList || [],
    _openPerEquipList: state.LogReducer._openPerEquipList || [],
    _onOffEquipList: state.LogReducer._onOffEquipList || [],
    _switchEquipList: state.LogReducer._switchEquipList || [],
    _equipLogList: state.LogReducer._equipLogList || [],
})

const mapDispatchToProps = (dispatch) => ({
    initLogList: (param) => dispatch(initLogList(param)),
    getLogList: (param) => dispatch(getLogList(param)),

    getOpenPerEquipList: (param) => dispatch(getOpenPerEquipList(param)),
    getOnOffEquipList: (param) => dispatch(getOnOffEquipList(param)),
    getSwitchEquipList: (param) => dispatch(getSwitchEquipList(param)),

    initEquipLogList: (param) => dispatch(initEquipLogList(param)),
    getEquipLogList: (param) => dispatch(getEquipLogList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
