// 앱 메인
import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "@reach/router"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { object } from "prop-types"
import { useLongPress, LongPressDetectEvents } from "use-long-press"
import { Dialog } from "@material-ui/core"

import { WidthToDP, dateFormat, androidBackHandler } from "../../../utils/util"

import SEO from "../../seo"
// import Header from "../../component/Header"
// import Layout from "../../component/Layout"
import Footer from "./Footer"

import MainContainer from "../../../containers/MainContainer"

import SensorData from "./SensorData"
import SensorDataChart from "./SensorDataChart"

import Icon_overcast_day from "../../../images/icon-overcast-day.svg"
import Icon_partialy_cloudy from "../../../images/icon-partialy-cloudy.svg"
import Lighting from "../../../images/lighting.svg"
import Overcast_day from "../../../images/overcast-day.svg"
import Temperature from "../../../images/temperature.svg"
import Pour_rain from "../../../images/pour-rain.svg"
import Icon_rainy_day from "../../../images/icon-rainy-day.svg"
import Icon_sunny_day from "../../../images/icon-sunny-day.svg"
import Icon_snow from "../../../images/icon-snow.svg"
import Ico_breeze from "../../../images/ico-breeze.svg"
import icon_detail from "../../../images/icon-detail.svg"

import Temperature2 from "../../../images/temperature2.svg"
import Pour_rain2 from "../../../images/pour-rain2.svg"
import Icon_rainy_day2 from "../../../images/icon-rainy-day2.svg"
import Icon_sunny_day2 from "../../../images/icon-sunny-day2.svg"
import Ico_breeze2 from "../../../images/ico-breeze2.svg"

import CommonContainer from "../../../containers/CommonContainer"
import { YellowButton } from "../../component/common/Button"

import SensorDataInquiryComp from "./SensorDataInquiryComp"

import imgSrc from "../../../images/weather_conditions.json"
import weatherLang from "../../../lang/wheatherLang.json"

import { PieChart } from 'react-minimal-pie-chart';

const sensorKind = { // 색깔컬럼에 올리면 sensorKind 종류에 따라 아이콘 생김
  //상단센서 아이콘
  10150001: Temperature, // 온도
  10150002: Pour_rain,   // Humidity -습도
  10150003: Temperature, // moistureContent -함수율
  10150004: Temperature, // CO2
  10150005: Temperature, // PH
  10150006: Temperature, // EC
  10150007: Icon_rainy_day, // 유량
  10150008: Ico_breeze, // windDirection -풍향
  10150009: Ico_breeze, // windSpeed -풍속
  10150010: Icon_sunny_day, // insolation -일사
  10150011: Icon_rainy_day, // 감우
  // 10150011: Lighting, // 감우

  10150012: "", // 수분부족량
  10150013: "", // 수위경고
  10150014: Pour_rain, // 수위

  // 하단센서 아이콘
  10150101: Temperature2, // 온도
  10150102: Pour_rain2,   // Humidity -습도
  10150103: Temperature2, // moistureContent -함수율
  10150104: Temperature2, // CO2
  10150105: Temperature2, // PH
  10150106: Temperature2, // EC
  10150107: Icon_rainy_day2, // 유량
  10150108: Ico_breeze2, // windDirection -풍향
  10150109: Ico_breeze2, // windSpeed -풍속
  10150110: Icon_sunny_day2, // insolation -일사
  10150111: Icon_rainy_day2, // 감우
  10150112: "", // 수분부족량
  10150113: "", // 수위경고
  10150114: Pour_rain2, // 수위

  0: "",

  //상단 비어있는경우
  1: Temperature,// 값없을경우 디폴트 아이콘
  2: Pour_rain,// 값없을경우 디폴트 아이콘
  3: Icon_sunny_day,// 값없을경우 디폴트 아이콘
  4: Icon_rainy_day,// 값없을경우 디폴트 아이콘

}

const MainPage = (props) => {
  const {
    t,
    getWeatherInfo,
    weatherInfo,
    getWorkCenterList,
    workCenterList,
    getInfoMainList, //센서값 가져오는
    infoMainList,
    postSortNoMod,
    modSensorDataFlag,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

    // 선택한 작업장 정보 값 유지 관련 - 차건담 2023.06.29
    keepWorkCenter,
    _selectedWorkCenter,

    // getInfoSensorDataList, infoSensorDataList,
  } = props

  // const { data, state } = props.location || { data: undefined }

  const [workCenterSeq, setWorkCenterSeq] = useState(0)
  const [sensorList, setSensorList] = useState([])
  // const [scrolling, setScrolling] = useState(false)
  const [openSensorData, setOpenSensorData] = useState(false)
  const [openSensorDataChart, setOpenSensorDataChart] = useState(false)
  const [flag, setFlag] = useState(0)
  const [sensorSeq, setSensorSeq] = useState(0)

  const [sensorDataFlag1, setSensorDataFlag1] = useState(undefined)
  const [sensorDataFlag2, setSensorDataFlag2] = useState(undefined)
  const [sensorDataFlag3, setSensorDataFlag3] = useState(undefined)
  const [sensorDataFlag4, setSensorDataFlag4] = useState(undefined)

  const [gStatus, setGStatus] = useState(1)
  const [sensorKindForChart, setSensorKindForChart] = useState(0) // SensorDataInquiryComp(센서데이터 조회 화면)에 전달할 sensorKind - 차건담 2023.08.04

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getImageSrc = (isDay, icon) => {
    const iconSrc = imgSrc.find(item => item.code == icon);
    if (iconSrc) {
      return `//cdn.weatherapi.com/weather/64x64/${isDay == 1 ? 'day' : 'night'}/${iconSrc.icon}.png`;
    } else {
      return ''
    }
  };

  const getLanguageIso = (languageSeq) => {
    switch (languageSeq) {
      case "10010002":
        return "ko";
      case "10010003":
        return "vi";
      case "10010004":
        return "zh";
      case "10010005":
        return "zh_tw";
      case "10010006":
        return "ja";
      default:
        return "en";
    }
  }

  const getWeatherInfoByLanguageSeq = (languageSeq, weatherCode) => {
    if (!languageSeq || !weatherCode) {
      return null;
    }
    
    const weatherData = weatherLang.find(weather => weather.code == weatherCode);
    if (!weatherData) {
      return null;
    }
    console.log("getLanguageIso(languageSeq)",getLanguageIso(languageSeq))
    console.log("languageSeq",languageSeq)
    console.log("weatherCode",weatherCode)
    console.log("weatherData",weatherData)
    if (getLanguageIso(languageSeq) != 'en' && weatherCode != 0) {
      const weatherInfo = weatherData.languages.find(lang => lang.lang_iso === getLanguageIso(languageSeq));
      
      if (!weatherInfo) {
        return null;
      }
      return {
        day: weatherInfo.day_text,
        night: weatherInfo.night_text,
      };
      
    } else {

      return {
        day: weatherData.day,
        night: weatherData.night,
      };
    }
  }

  function getWeatherDisplay(languageSeq, weatherInfo) {
    if (!languageSeq) {
      return dictCk("weatherNone");
    }
  
    const weatherLanguageInfo = getWeatherInfoByLanguageSeq(languageSeq, weatherInfo.fcst.sky);
  
    if (weatherInfo.fcst.pty == 1 && weatherLanguageInfo) {
      return weatherLanguageInfo.day;
    } else if (weatherInfo.fcst.pty != 1 && weatherLanguageInfo) {
      return weatherLanguageInfo.night;
    } else {
      return dictCk("weatherNone");
    }
  }

  // weatherMethod start
  const sky = {
    1: { name: dictCk("sunny"), image: Icon_sunny_day }, //Icon_sunny_day,
    3: { name: dictCk("cloudy"), image: Icon_partialy_cloudy },
    4: { name: dictCk("OvercastDay"), image: Overcast_day },
    0: { name: dictCk("weatherNone"), image: "" },
  }

  const pty = {
    1: { name: dictCk("rain"), image: Icon_rainy_day },
    2: { name: dictCk("rainSnow"), image: Icon_snow },
    3: { name: dictCk("snow"), image: Icon_snow },
    4: { name: dictCk("PourRain"), image: Pour_rain },
    5: { name: dictCk("raindrop"), image: Icon_rainy_day },
    6: { name: dictCk("raindropBlizzard"), image: Icon_snow },
    7: { name: dictCk("Blizzard"), image: Icon_snow },
    0: { name: dictCk("weatherNone"), image: "" },
  }

  // weatherMethod end

  const closeSensorData = async (sensorSeq) => {
    if (sensorSeq > 0) {
      const temp = sensorList.map((row) =>
        row.sensorSeq == sensorSeq && row.flag != flag ? { ...row, flag } : row.flag == flag ? { ...row, flag: 0 } : row
      )
      
      // console.log("temp", temp)

      await modSensorDataFlag({ dataList: temp, workCenterSeq })
    }
    // end if

    setOpenSensorData(false)
  }

  const closeSensorDataChart = async (sensorSeq) => {
    // await modSensorDataFlag( { workCenterSeq, sensorSeq, flag } )

    setOpenSensorDataChart(false)
    setSensorKindForChart(0) // sensorKindForChart 초기화 - 차건담 2023.08.04
  }

  let instCycle = null

  useEffect(() => { // 첫 오픈useEffect

    getTranslationWords()
    getData()

    if( instCycle === null ) instCycle = setInterval(getMainDataListCycle, 30 * 1000); // 30s 

    return async () => {
      
      clearInterval(instCycle)

      // await initDataList(actionType)

    }

  }, [])

  useEffect(() => {

    if( instCycle === null ) instCycle = setInterval(getMainDataListCycle, 30 * 1000); // 30s

    return async () => {
      
      clearInterval(instCycle)

      // await initDataList(actionType)

    }

  }, [workCenterSeq])

  const getMainDataListCycle = () => {

    console.log('[main] data loading...',workCenterSeq)

    // if( workCenterSeq > 0 ) 
    getMainDataList(workCenterSeq)

  }

  useEffect(() => {
    if (workCenterList.length > 0) {
      keepWorkCenter(workCenterList[0].workCenterSeq) // 메인 첫로딩시 맨앞 작업작선택
    } // end if
  }, [workCenterList])

  useEffect(() => {
    setSensorList(infoMainList)

    const gStatus = infoMainList
    setGStatus(gStatus.length > 0 ? gStatus[0].GatewayStatus : 1)

    const sensorDataFlag1 = infoMainList.filter((row) => row.flag == 1)
    const sensorDataFlag2 = infoMainList.filter((row) => row.flag == 2)
    const sensorDataFlag3 = infoMainList.filter((row) => row.flag == 3)
    const sensorDataFlag4 = infoMainList.filter((row) => row.flag == 4)

    setSensorDataFlag1(sensorDataFlag1.length > 0 ? sensorDataFlag1[0] : undefined)
    setSensorDataFlag2(sensorDataFlag2.length > 0 ? sensorDataFlag2[0] : undefined)
    setSensorDataFlag3(sensorDataFlag3.length > 0 ? sensorDataFlag3[0] : undefined)
    setSensorDataFlag4(sensorDataFlag4.length > 0 ? sensorDataFlag4[0] : undefined)
  }, [infoMainList])

  // 불필요한 재렌더링을 방지하기 위한 콜백 함수
  const memoizedCallback = useCallback(
    (props) => {
      return <SensorDataInquiryComp sensorKindForChart={sensorKindForChart} open={openSensorDataChart} close={closeSensorDataChart}></SensorDataInquiryComp>
    },
    [workCenterSeq, sensorKindForChart]
  );

  useEffect(() => {
    if(_selectedWorkCenter != '' && _selectedWorkCenter != null && _selectedWorkCenter != undefined){
      console.log("_selectedWorkCenter:", _selectedWorkCenter)
      onClickWorkCenter(_selectedWorkCenter)
      setWorkCenterSeq(_selectedWorkCenter)
      getMainDataList(_selectedWorkCenter)
    }
  }, [_selectedWorkCenter])

  // console.log('asdasd')
  // console.log(infoMainList[0].GatewayStatus)
  const getData = async () => {
    
    await getWeatherInfo()
    await getWorkCenterList()

  }

  const getMainDataList = async ( workCenterSeq ) => {

    const data = {
      workCenterSeq 
    }

    await getWeatherInfo()
    await getInfoMainList({ workCenterSeq })

  }

  const onClickWorkCenter = (workCenterSeq) => {
    keepWorkCenter(workCenterSeq)
    // setWorkCenterSeq(_selectedWorkCenter)
    // getMainDataList(_selectedWorkCenter)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    let temp1 = sensorList.filter((row) => row.flag > 0)
    let temp2 = sensorList.filter((row) => row.flag == 0)

    const items = reorder(temp2, result.source.index, result.destination.index)

    const temp3 = temp1.concat(items)

    // console.log(123,items)

    setSensorList(temp3)
    postSortNoMod({ dataList: temp3, workCenterSeq })
  }

  const onClickSensorData = (e, sensorData) => {
    e.stopPropagation()
    setSensorSeq(sensorData ? sensorData.sensorSeq : 0)
    setSensorKindForChart( sensorData ? sensorData.sensorKind : 0) // 클릭한 sensorData의 sensorKind값 sensorKindForChart에 주입 - 차건담 2023.08.04
  }

  useEffect(() => {
    if(sensorKindForChart != 0){ // sensorKindForChart가 초기값이 아닌 경우에만 센서데이터 조회 화면 열림 - 차건담 2023.08.04
      setOpenSensorDataChart(true)
    }
  }, [sensorKindForChart])

  // useEffect(()=>{
  //   setOpenSensorDataChart(true)
  // }, [sensorSeq])

  // useEffect(() => {
  //   //안드로이드 백 핸들러
  //   androidBackHandler(() => {
  //     if (openSensorDataChart) setOpenSensorDataChart(false)
  //     else if (openSensorData) setOpenSensorData(false)
  //   })
  // }, [openSensorDataChart, openSensorData])

  // 작업장 슬라이스 기능 파트 start ----------------------------------------------------
  const [startX, setStartX] = useState(null); // 터치 시작점
  const [moveControl, setMoveControl] = useState(0); // 연속적인 함수발동 정지

  const SWIPE_THRESHOLD = 90; // 최소 스와이프 거리
  const windowHeight = window.innerHeight; //화면 총길이

  const topReservedHeight = windowHeight * 0.1; // 상단 10% 영역
  const bottomReservedHeight = windowHeight * 0.15; // 하단 15% 영역

  const minY = topReservedHeight; // 상단 10% 영역
  const maxY = windowHeight - bottomReservedHeight; // 하단 15% 영역

  const handleTouchStart = (event) => { 
 
    const touchY = event.touches[0].pageY;
  
    if (touchY > minY && touchY < maxY && openSensorDataChart == false && openSensorData == false) {
      setStartX(event.touches[0].pageX);
    }
  
    if(openSensorDataChart == true || openSensorData == true){ //차트가 켜지는 순간 터치시작점 리셋
    setStartX(null);
    }
    
  };

  const handleTouchEnd = (event) => {
    if (startX !== null && openSensorDataChart == false && openSensorData == false) {
      const endX = event.changedTouches[0].pageX; //터치 종료위치
      const distance = Math.abs(endX - startX);   //터치 시작점 & 종료시점 거리차이

      // console.log("@@@@@@@@@startX222::",startX)
      // console.log("@@@@@@@@@endX2222::"  ,endX)
      // console.log("@@@@@@@@@workCenterSeq::"  ,workCenterSeq)
      // console.log("@@@@@@@@@openSensorDataChart::"  ,openSensorDataChart)

      const workCenterListIndex = workCenterList.findIndex(item => item.workCenterSeq === workCenterSeq); //현재 작업장인덱스
      const maxIndex = workCenterList.length-1; // 작업장 인덱스 최대값
      
      if (distance > SWIPE_THRESHOLD && moveControl === 0) {
        if (startX > endX) {
          // 오른쪽에서 왼쪽으로 스와이프 (swipeCount 증가)
          setMoveControl(1);
          onClickWorkCenter(workCenterList[maxIndex == workCenterListIndex ? 0 : workCenterListIndex+1].workCenterSeq)
        } else {
          // 왼쪽에서 오른쪽으로 스와이프 (swipeCount 감소)
          setMoveControl(1);
          onClickWorkCenter(workCenterList[0 == workCenterListIndex ? maxIndex : workCenterListIndex-1].workCenterSeq)
        }
        // 터치가 끝났으므로 초기화
        setStartX(null);
        setMoveControl(0);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [startX, openSensorDataChart, openSensorData]);
  // 작업장 슬라이스 기능 파트 end ------------------------------------------------------

  const Chart = ({ reveal , donutColor}) => {
    
    let revealArea = reveal > 1000  ? reveal/100 : reveal > 100 ? reveal/10 : reveal 
    // console.log("@@@@@@@@@@@@reveal:donutColor:revealArea::", reveal,donutColor,revealArea)

    return (
      <PieChart
        data={[
          {
            value: reveal,     // 데이터 값 (0에서 100 사이의 값)
            color: donutColor, // 도넛의 색상
            title: "name1",    // 데이터 항목의 제목 (라벨)
          },
        ]}
        reveal={revealArea}  // 도넛이 표시될 영역의 크기 (0에서 100 사이의 값)
        lineWidth={18}       // 도넛의 두께 (값이 클수록 도넛이 얇아짐)
        background="#f3f3f3" // 도넛의 배경색
        lengthAngle={360}    // 도넛의 전체 각도 (360이면 전체 도넛, 값이 작아지면 부채꼴 모양 도넛)
        rounded              // 도넛의 모서리를 둥글게 처리
        animate              // 애니메이션 효과 적용
        // label={({ dataEntry }) => dataEntry.value + "%"} // 레이블 내용 설정 (데이터의 값 + %)
        label={({ dataEntry }) => dataEntry.value } // 레이블 내용 설정 (데이터의 값 + %)
        labelStyle={{
          fontSize: "23px",   // 레이블 텍스트 크기
          fill: "#ffffff",    // 레이블 텍스트 색상
          fontWeight: "bold", //레이블 텍스트 글씨 두껍게
        }}
        labelPosition={0}     // 레이블의 위치 설정 (0: 도넛 중앙, 1: 도넛 외부)
      />
    );
  };

  return (
    <>
      <SEO title={dictCk("main")} />

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: `${WidthToDP(15)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(33)}px` }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TodayIcon bc={"#90AACB"}>{dictCk('Today')}</TodayIcon>
              <TodayText>{dateFormat(new Date(), "YYYY-MM-DD")}</TodayText> {/*"YYYY-MM-DD (dddd)" 수정 24.03.13 by 강동우 */}
            </div>
          <GatewayStatusIcon bc={infoMainList ? gStatus == '0' ? "#B4CFB0" : "#CE5959" : "#B3C99C"}>{infoMainList ? gStatus == '0' ? dictCk('commNormal') : dictCk('commFail') : dictCk('CommNormal')}</GatewayStatusIcon>
          {/* 게이트웨이 통신상태 */}
      </div>
      {/* 현재 날짜 및 요일 표시 */}

      {/* 초록색 start 날씨 상태*/}
      <ImportDiv2 padding={`${WidthToDP(24)}px`} margin={`${WidthToDP(0)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(33)}px`} bc={ weatherInfo.isData == "NODATA" ? "#EEEEEE" : "#ffffff"}>
      {/*weatherInfo.isData == "NODATA" 1시간이상 값 못받아왔을시 회색처리 */}
        
        <div style={{ display: "flex", marginTop: `${WidthToDP(8)}px` }}>
          {/* <section style={{}}>
            {!(weatherInfo.fcst.sky == 0 && weatherInfo.fcst.pty == 0)&&<img
              style={{ height: `${WidthToDP(80)}px`, width: `${WidthToDP(80)}px` }}
              src={ weatherInfo.fcst.pty == 0 ? sky[weatherInfo.fcst.sky].image : pty[weatherInfo.fcst.pty].image}
            />}
          </section> */}
          <section style={{}}>
          {weatherInfo.fcst.sky !== '0' ? (
            <img
              // style={{ height: `${WidthToDP(64)}px`, width: `${WidthToDP(64)}px` }}
              style={{ height: `${WidthToDP(94)}px`, width: `${WidthToDP(94)}px` }}
              src={getImageSrc(weatherInfo.fcst.pty, weatherInfo.fcst.sky)}
            />
          ) : null}

          </section>

          <section style={{ marginLeft: `${WidthToDP(5)}px` }}>
            <section>
              <CustomFont2
                size={`${WidthToDP(27)}px`}
                margin={`${WidthToDP(15)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
                height={`${WidthToDP(40)}px`}
                style={{ letterSpacing: 0.1, fontWeight: "bold", color: "000000", }}
              >
                {weatherInfo.ncst.t1h}&#8451;&nbsp;/&nbsp;{weatherInfo.ncst.reh}%
                {/* 현재온도 / 현재습도 */}
              </CustomFont2>
            </section>
            <section>
              <CustomFont2
                size={`${WidthToDP(20)}px`}
                margin={`${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
                // width={`${WidthToDP(50)}px`}
                height={`${WidthToDP(40)}px`}
                style={{ opacity: 0.8, letterSpacing: 0.1 }}
              >
                {getWeatherDisplay(dictCk('languageSeq'), weatherInfo)}
              </CustomFont2>
            </section>
          </section>
        </div>
        
        <div style={{ marginTop: `${WidthToDP(8)}px` }}>
          <section>
            <CustomFont2
              size={`${WidthToDP(27)}px`}
              margin={`${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
              height={`${WidthToDP(47)}px`}
              style={{ textAlign: "right", }}
            >
              {weatherInfo.address}
            </CustomFont2>
          </section>
          <section>
            <CustomFont2
              size={`${WidthToDP(25)}px`}
              margin={`${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
              height={`${WidthToDP(47)}px`}
              style={{ opacity: 0.7, textAlign: "right", }}
            >
              <span style={{ fontSize: `${WidthToDP(25)}px`, color: "#4477CE" }}>
              {weatherInfo.fcst.tmn < weatherInfo.ncst.t1h ? weatherInfo.fcst.tmn : weatherInfo.ncst.t1h}
              </span>
              <span style={{ fontSize: `${WidthToDP(25)}px`, color: "#555555" }}>
              &#8451;&nbsp;/&nbsp;
              </span>
              <span style={{ fontSize: `${WidthToDP(25)}px`, fontWeight: "bold", color: "#D71313" }}>
              {weatherInfo.fcst.tmx > weatherInfo.ncst.t1h ? weatherInfo.fcst.tmx : weatherInfo.ncst.t1h}&#8451;
              </span>
              {/* 최저온도 / 최고온도 */}
            </CustomFont2>
          </section>
        </div>
      </ImportDiv2>
      {/* 초록색 end */}

      {/* 빨,주 start */}
      <div style={{ display: "flex" }}>
        <SensorDataFlagItem
          bc="#C51605" //색깔컬러
          padding={`${WidthToDP(15)}px`}
          margin={`${WidthToDP(0)}px ${WidthToDP(15)}px ${WidthToDP(15)}px ${WidthToDP(33)}px`}
          items={sensorDataFlag1}
          onClick={(e) => onClickSensorData(e, sensorDataFlag1)}
          setOpenSensorData={setOpenSensorData}
          setFlag={setFlag}
          flag={1}
          Chart={Chart}
          donutColor={"#F15A59"}
        />

        <SensorDataFlagItem
          bc="#FD8D14"
          padding={`${WidthToDP(15)}px`}
          margin={`${WidthToDP(0)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(0)}px`}
          items={sensorDataFlag2}
          onClick={(e) => {
            onClickSensorData(e, sensorDataFlag2)
          }}
          setOpenSensorData={setOpenSensorData}
          setFlag={setFlag}
          flag={2}
          Chart={Chart}
          donutColor={"#FBC252"}
        />
      </div>
      {/* 빨,주 end */}

      {/* 파,보 start */}
      <div style={{ display: "flex" }}>
        <SensorDataFlagItem
          bc= "#769FCD"
          padding={`${WidthToDP(15)}px`}
          margin={`${WidthToDP(0)}px ${WidthToDP(15)}px ${WidthToDP(15)}px ${WidthToDP(33)}px`}
          items={sensorDataFlag3}
          onClick={(e) => {
            onClickSensorData(e, sensorDataFlag3)
          }}
          setOpenSensorData={setOpenSensorData}
          setFlag={setFlag}
          flag={3}
          Chart={Chart}
          donutColor={"#C5DFF8"}
        />

        <SensorDataFlagItem
          bc="#BE9FE1"
          padding={`${WidthToDP(15)}px`}
          margin={`${WidthToDP(0)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(0)}px`}
          items={sensorDataFlag4}
          onClick={(e) => {
            onClickSensorData(e, sensorDataFlag4)
          }}
          setOpenSensorData={setOpenSensorData}
          setFlag={setFlag}
          flag={4}
          Chart={Chart}
          donutColor={"#DFCCFB"}
        />
      </div>
      {/* 파,보 end */}

      <div style={{ overflow: "scroll", flex: 1, margin: `${WidthToDP(0)}px ${WidthToDP(33)}px ${WidthToDP(0)}px ${WidthToDP(33)}px` }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: `15px` }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sensorList
                  .filter((row) => row.flag == 0)
                  .map((row, idx) => (
                    <Draggable
                      key={idx}
                      sensorSeq={row.sensorSeq}
                      draggableId={"id" + idx}
                      index={idx}
                      style={{
                        position: "relative",
                        userSelect: "none",
                        width: "100%",
                        height: `${WidthToDP(88)}px`,
                        // marginBottom: `${WidthToDP(16)}px`,
                      }}
                    >
                      {(provided) => (
                        <div key={idx} ref={provided.innerRef} {...provided.draggableProps}>
                          <div
                            style={{
                              position: "relative",
                              // width: "97px",
                              // height: "65px",
                              marginBottom: `${WidthToDP(16)}px`,
                            }}
                            onClick={(e) => {
                              onClickSensorData(e, row)
                            }}
                          >
                            <SensorDataItem items={row} provided={provided} dict={dict}/>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Footer workCenterList={workCenterList} value={workCenterSeq} setValue={onClickWorkCenter} />
      {/* 작업장 변경 파트 */}
      <SensorData open={openSensorData} close={closeSensorData} workCenterSeq={workCenterSeq} boxFlag={flag} />
      {/* 상단 색깔 센서 화면오픈 파트 */}
      {/* <Dialog open={openSensorDataChart} fullScreen> */}
      {/* <Dialog open={false} fullScreen>
        <SensorDataChart open={openSensorDataChart} close={closeSensorDataChart} workCenterSeq={workCenterSeq} sensorSeq={sensorSeq} />
      </Dialog> */}

      <Dialog open={openSensorDataChart} fullScreen>
       {/* <SensorDataInquiryComp open={openSensorDataChart} close={closeSensorDataChart}></SensorDataInquiryComp> */}
       {memoizedCallback()}
      </Dialog>
    </>
  )
}


export default React.memo(MainContainer(CommonContainer(withTranslation()(MainPage))))

const ImportDiv = styled.div`
  display: flex;
  width: ${(props) => props.width};
  height: ${WidthToDP(160)}px;
  background-color: ${(props) => props.bc};
  border-radius: ${WidthToDP(22)}px;
  padding: ${(props) => props.padding};
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  margin: ${(props) => props.margin};
  border: 1.5px solid #e1e1e1; /* 회색 테두리 추가 */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); /* 음영 추가 */
`

const ImportDiv2 = styled.div`
  display: flex;
  width: ${(props) => props.width};
  height: ${WidthToDP(110)}px;
  background-color: ${(props) => props.bc};
  border-radius: ${WidthToDP(22)}px;
  padding: ${(props) => props.padding};
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  margin: ${(props) => props.margin};
  border: 1.5px solid #00C73C; /* 초록색 테두리 추가 */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); /* 음영 추가 */
`;

//margin-right: ${(props) => (props.mr ? props.mr : "")};

// const CustomFont = styled.p`
//   font-size: ${(props) => props.size};
//   line-height: ${(props) => props.line};
// `

const CustomFont = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  font-family: NotoSansCJKkr;
  font-size: ${(props) => props.size};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`
const CustomFont2 = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  font-family: NotoSansCJKkr;
  font-size: ${(props) => props.size};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   padding: ${WidthToDP(30)}px;
//   background-color: #f6f6f6;
// `

const TodayIcon = styled.div`
  width: ${WidthToDP(100)}px;
  height: ${WidthToDP(55)}px;
  padding: ${WidthToDP(5)}px ${WidthToDP(15)}px;
  border-radius: ${WidthToDP(6)}px;
  background-color: ${(props) => props.bc};

  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: ${WidthToDP(-0.5)}px;
  color: #ffffff;

  text-align: center;
`

const GatewayStatusIcon = styled.div`
  width: ${WidthToDP(140)}px;
  height: ${WidthToDP(55)}px;
  padding: ${WidthToDP(5)}px ${WidthToDP(15)}px;
  border-radius: ${WidthToDP(6)}px;
  background-color: ${(props) => props.bc};

  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: ${WidthToDP(-0.5)}px;
  color: #ffffff;

  text-align: center;
`

const TodayText = styled.div`
  display: flex;
  align-items: center; /* 수직 중앙 정렬 추가 */
  height: ${WidthToDP(37)}px;
  padding: 0 ${WidthToDP(15)}px; /* 상하 패딩을 0으로 조정 */

  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: ${WidthToDP(-0.3)}px;
  color: #000000;
`

// 상단 색깔블럭안에 들어가는값
const SensorDataFlagItem = (props) => {
  const { items, flag, onClick, setOpenSensorData, setFlag, bc, padding, margin, Chart, donutColor } = props
  
  const bind = useLongPress(
    (e) => {
      // if (scrolling) return
      // console.log( 'long~~ touch', items )
      setOpenSensorData(true)
      setFlag(flag)
    },
    {
      onStart: (e) => {
        console.log("Press started")
      },
      onFinish: (e) => console.log("Long press finished"),
      onCancel: (e) => console.log("Press cancelled"),
      threshold: 500,
      captureEvent: true,
      detect: LongPressDetectEvents.TOUCH,
    }
  )
  let revealNum = items ? items.sensorDataFinal : 0 // 도넛차트 수치값
  revealNum = typeof revealNum =="string" ? parseInt(0) : parseInt(revealNum)
  revealNum = parseInt(revealNum) //숫자화 하기

  let fontSizeControl = items ? (items.sensorNodeName+"-"+items.sensorKindName) : ""
  fontSizeControl = fontSizeControl.length >= 18 ? 17 : 23 // 하얀색 센서명길이 조절

  return (
    <ImportDiv width={`50%`} padding={padding} margin={margin} bc={items ?  items.sensorstatus == "XX" ? "#73777B" : bc : bc} {...bind} onClick={onClick}>
      {/* items.sensorstatus == "XX" 데이터가 최신이 아닌 경우 회색처리 */}
      <div style={{ display: "flex",}}>
        <section style={{}}>
          <section>
            <CustomFont
              size={`${WidthToDP(fontSizeControl)}px`}
              margin={`${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
              height={`${WidthToDP(40)}px`}
              style={{ opacity: 0.8 }}
            >
              {/* {items ? items.sensorName : ""} */}
              {items ? (items.sensorNodeName+"-"+items.sensorKindName) : ""}
            </CustomFont>
            {/* 노드명 + 센서종류명 */}
          </section>
          <section>
            <CustomFont
              size={`${WidthToDP(40)}px`}
              margin={`${WidthToDP(5)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`}
              height={`${WidthToDP(64)}px`}
              style={{}}
            > 
              {items ? items.sensorDataFinal : ""}
              &nbsp;
              <span style={{ fontSize: "12px", opacity: 0.8 }}>{items ? items.sensorUnitSeqName : ""}</span>
            </CustomFont>
            {/* 센서 온도값 */}
          </section>
        </section>
      </div>
      
      <div style={{ display: items && items.sensorKind != 10150011 ? "flex":"none",}}>
      <section style={{height: `${WidthToDP(100)}px`, width: `${WidthToDP(100)}px`}}>
      <Chart reveal={revealNum} donutColor={donutColor}/>
      </section>
      </div>
       {/* 도넛차트 */}
      
      <div style={{ display: items && items.sensorKind == 10150011 ? "flex": items ? "none":"flex",}}>
        <section style={{}}>
          <img style={{ height: `${WidthToDP(50)}px` }} src={sensorKind[items ? items.sensorKind : flag == undefined ? 0 : flag]} />
        </section> 
      </div>
       {/* 아이콘 */} 
    </ImportDiv>
  )
}

// 하단 흰색바탕 일반센서값들
const SensorDataItem = (props) => {
  const { items, provided, dict, } = props

  let fontSizeControl = items.sensorNodeName+"-"+items.sensorKindName 
  fontSizeControl = fontSizeControl.length >= 20 ? 21 : 27 // 하얀색 센서명길이 조절

  return (
    <>
      {/* 센서 개별 박스 */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: `${WidthToDP(88)}px`,
          width: "100%",
          border: `${WidthToDP(1.5)}px solid #e1e1e1`,
          padding: `0 ${WidthToDP(24)}px`,
          backgroundColor: `${(items.sensorKind == 10150011 || items.sensorKind == 10150013) && items.sensorDataFinal == "Y" ? "#c94b4b" : items.sensorstatus == "XX" ? "#d0d0d0" : "white" }`, //센서상태 데이터가 최신이 아닌경우 회색처리
          // 감우 & 수위경고 'Y' 일시 빨강색으로 변함
          borderRadius: '8px', // 모서리 둥글게 하는 파트
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // 음영 스타일 설정
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: `100%` }}>
          <div  style={{ display: "flex" }}>
            <section
              style={{
                padding: `${WidthToDP(3)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`,
                fontSize: `${WidthToDP(fontSizeControl)}px`,
                color: "#555555",
                lineHeight: `normal`,
              }}
            >
               {/* {items.sensorName} */}
              {/* 노드명 + 센서종류명 */}
              {/* <img src={sensorKind[items ? items.sensorKind : 0]} style={{ height: `${WidthToDP(34)}px` }}></img> */}
              {(items.sensorNodeName+"-"+items.sensorKindName)} 
              <img src={sensorKind[items ? items.sensorKind+100 : 0]} style={{ height: `${WidthToDP(34)}px`, marginLeft:"5px" }}></img>
            </section>
            {/* {
            items.sensorKind == 10150011 && items.sensorDataFinal == "Y" ?
            <section style={{display:"flex", alignItems:"center", marginLeft:"10px"}}>
              <img src={Icon_rainy_day} style={{ height: `${WidthToDP(37)}px` }}/>
            </section> : "" 
            } */}
            {
            items.sensorKind == 10150008 ? 
            <section style={{display:"flex", alignItems:"center", marginLeft:"10px", fontSize: `${WidthToDP(27)}px`, }}>
              {items.sensorDataFinal < 22.5   || items.sensorDataFinal >= 337.5 ? dict['north']     : ''}
              {items.sensorDataFinal >= 22.5  && items.sensorDataFinal < 67.5   ? dict['northeast'] : ''}
              {items.sensorDataFinal >= 67.5  && items.sensorDataFinal < 112.5  ? dict['east']      : ''}
              {items.sensorDataFinal >= 112.5 && items.sensorDataFinal < 157.5  ? dict['southeast'] : ''}
              {items.sensorDataFinal >= 157.5 && items.sensorDataFinal < 202.5  ? dict['south']     : ''}
              {items.sensorDataFinal >= 202.5 && items.sensorDataFinal < 247.5  ? dict['southwest'] : ''}
              {items.sensorDataFinal >= 247.5 && items.sensorDataFinal < 292.5  ? dict['west']      : ''}
              {items.sensorDataFinal >= 292.5 && items.sensorDataFinal < 337.5  ? dict['northwest'] : ''}
            </section> : "" 
            }
          </div>
          {/* 센서값 */}
          <section style={{ display: "flex" }}>
            <section style={{ fontSize: `${WidthToDP(33)}px`, color: "#022808", fontWeight: "bold", lineHeight: `normal` }}>
              {items.sensorDataFinal}
            </section>
            {/* 센서단위 */}
            <section
              style={{
                padding: `${WidthToDP(9)}px ${WidthToDP(0)}px ${WidthToDP(7)}px ${WidthToDP(5)}px`,
                fontSize: `${WidthToDP(25)}px`,
                color: "#555555",
                fontWeight: "normal",
                lineHeight: `normal`,
              }}
            >
              {items.sensorUnitSeqName}
            </section>
          </section>
        </div>
        <section style={{ display: "flex", justifyContent: "flex-end", width: `${WidthToDP(38)}px` }} {...provided.dragHandleProps}>
          <img src={icon_detail} style={{ height: `${WidthToDP(34)}px` }}></img>
        </section>
      </div>
    </>
  )
}
