import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getEquipControlList,
  postEquipControlAutoAMod,
  postEquipSettingMod,
  ctrlEquipControlSwitchMod,
  getEquipControlStatus,
  initEquipControlStatus,
} = actions.EquipAction
const { getWorkCenterList } = actions.MainAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  equipControlList: state.EquipReducer.equipControlList,
  workCenterList: state.MainReducer.workCenterList,
  equipOnOffControlList: state.EquipReducer.equipOnOffControlList,

  _equipControlStatus: state.EquipReducer._equipControlStatus,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getEquipControlList: (param) => dispatch(getEquipControlList(param)),
  postEquipControlAutoAMod: (param) => dispatch(postEquipControlAutoAMod(param)),
  postEquipSettingMod: (param) => dispatch(postEquipSettingMod(param)),
  getWorkCenterList: (param) => dispatch(getWorkCenterList(param)),
  ctrlEquipControlSwitchMod: (param) => dispatch(ctrlEquipControlSwitchMod(param)),

  getEquipControlStatus: (param) => dispatch(getEquipControlStatus(param)),
  initEquipControlStatus: (param) => dispatch(initEquipControlStatus(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
