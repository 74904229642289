import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
// import Button from '@material-ui/core/Button';
import { navigate } from "@reach/router"

import { isApp, WidthToDP, androidBackHandler } from "../../../utils/util"
import SEO from "../../seo"
import Layout from "../../component/Layout"

import CropContainer from "../../../containers/CropContainer"

import { YellowButton, SkyButton, GreenButton, GrayButton } from "../../component/common/Button"

import CommonContainer from "../../../containers/CommonContainer"

const CropHist = (props) => {
  const { t, getCropHistInfo, cropHistInfo, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const [barCode, setBarCode] = useState("")

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  useEffect(() => {
    getTranslationWords()
    
    //안드로이드 백 핸들러1
    androidBackHandler(() => {
      navigate("/app/main", { replace: true })
    })
    //

    window.NativeScanDataReceive = async (_data) => {
      setBarCode(_data.ScanData)

      getData(_data.ScanData)

      return "success"
    }

    scanBarCode()
  }, [])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const scanBarCode = () => {
    if (isApp) {
      window.callNative(
        JSON.stringify({
          Type: "Scan",
          FunctionName: "NativeScanDataReceive",
          data: {},
        })
      )
    }
  }

  const getData = async (barCode) => {
    if (barCode > "") {
      await getCropHistInfo({ barCode })
    }
  }

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      isHam={true} // default : false
      title={dictCk("cropHistory")}
      isAlarm={true} // default : false
      alarmHandler={() => console.log("alarm")}
    >
      <Container>
        <SEO title={dictCk("cropHistory")} />

        {barCode > "" && cropHistInfo && cropHistInfo.barcode ? (
          <BackBoard padding={`${WidthToDP(33)}px`} margin={`${WidthToDP(15)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(33)}px`} bc="#fff">
            <div style={{ display: "flex", marginTop: `${WidthToDP(8)}px` }}>
              <section style={{}}>
                <img style={{ height: `${WidthToDP(160)}px`, width: `${WidthToDP(160)}px` }} src={cropHistInfo.photoUri} />
              </section>

              <section style={{ width: "90%" }}>
                <TextDiv style={{ marginTop: "0", marginLeft: `${WidthToDP(10)}px` }}>
                  {dictCk("farmOwner")} : {cropHistInfo.farmOwner}
                </TextDiv>
                <TextDiv style={{ marginLeft: `${WidthToDP(10)}px` }}>
                  {dictCk("farmName")} : {cropHistInfo.farmName}
                </TextDiv>
                <TextDiv style={{ marginLeft: `${WidthToDP(10)}px` }}>
                  {dictCk("contactNumber")} : {cropHistInfo.teleNo}
                </TextDiv>
                <TextDiv style={{ marginLeft: `${WidthToDP(10)}px` }}>
                  {dictCk("placeProduction")} : {cropHistInfo.addr}&nbsp;{cropHistInfo.addrDetail}&nbsp;{cropHistInfo.zipCode}
                </TextDiv>
              </section>
            </div>

            <div style={{ marginTop: `${WidthToDP(8)}px` }}>
              <TextDiv>
                {dictCk("itemName")} : {cropHistInfo.itemName}
              </TextDiv>
              <TextDiv>
                {dictCk("itemKind")} : {cropHistInfo.itemKindName}
              </TextDiv>
              <TextDiv>
                {dictCk("qty")} : {cropHistInfo.qty}&nbsp;{cropHistInfo.unitName}
              </TextDiv>
              <TextDiv>
                {dictCk("salesCustName")} : {cropHistInfo.custName}
              </TextDiv>
              <TextDiv>
                {dictCk("plantDate")} : {cropHistInfo.plantDate}
              </TextDiv>
              <TextDiv>
                {dictCk("harvDate")} : {cropHistInfo.harvDate}
              </TextDiv>
              <TextDiv>
                {dictCk("salesOutDate")} : {cropHistInfo.salesOutDate}
              </TextDiv>
              <TextDiv>
                {dictCk("barCode")} : {barCode}
              </TextDiv>
              {/* <TextDiv>
              <Button variant="contained" onClick={()=>scanBarCode()}>바코드 스캔</Button>
            </TextDiv> */}
            </div>
          </BackBoard>
        ) : (
          ""
        )}

        <div style={{ display: "flex", margin: `${WidthToDP(15)}px ${WidthToDP(33)}px ${WidthToDP(0)}px ${WidthToDP(33)}px` }}>
          <GreenButton width={`100%`} style={{}} onClick={() => scanBarCode()}>
            {dictCk("barCodeScan")}
          </GreenButton>
        </div>
      </Container>
    </Layout>
  )
}

export default CropContainer(CommonContainer(withTranslation()(CropHist)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
`
const BackBoard = styled.div`
  width: ${(props) => props.width};
  background-color: ${(props) => props.bc};
  border-radius: ${WidthToDP(22)}px;
  padding: ${(props) => props.padding};
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.margin};
`

const TextDiv = styled.div`
  margin: ${WidthToDP(24)}px 0 0 0;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
