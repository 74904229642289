import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { 
  SET_DATA_LIST,

  // 다국어 관련 - 차건담 2023.04.03
  INIT_TRANSLATION_WORDS, SET_TRANSLATION_WORDS,

  // 선택한 작업장 정보 값 유지 관련 - 차건담 2023.06.29
  INIT_SELECTED_WORKCENTER, SET_SELECTED_WORKCENTER
} = actions.CommonAction

// default state value
const initialState = {
}

// handle action
export default handleActions(
  {

    [SET_DATA_LIST]: (state, action) => {
      
      state = {
        ...state,
        ...action.payload,
      }

      return state

    },

    // 다국어 TWord 초기화 - 차건담 2023.04.03
    [INIT_TRANSLATION_WORDS]: (state, action) => ({
      ...state,
      _translationWords: [],
    }),

    // 다국어 TWord 가져오기 - 차건담 2023.04.03
    [SET_TRANSLATION_WORDS]: (state, action) => ({
      ...state,
      _translationWords: action.payload,
    }),

    // 선택된 작업장 초기화 - 차건담 2023.06.29
    [INIT_SELECTED_WORKCENTER]: (state, action) => ({
      ...state,
      _selectedWorkCenter: '',
    }),
    // 선택된 작업장 값 저장 - 차건담 2023.06.29
    [SET_SELECTED_WORKCENTER]: (state, action) => ({
      ...state,
      _selectedWorkCenter: action.payload,
    }),

  },
  initialState
)
