import moment from "moment"
import "moment/locale/ko"
import { isTablet as isTabletDevice } from "react-device-detect"

// 웹인지 앱인지 구분하는 녀석
export const isApp =
  typeof window !== "undefined" && (window.navigator.userAgent.includes("WISHRROM_Android") || window.navigator.userAgent.includes("WISHRROM_Ios"))

// ios 인지 판단하는 녀석
export const isIOS = typeof window !== "undefined" && window.navigator.userAgent.includes("WISHRROM_Ios")

// 테블릿인지 모바일인지 구분하는 녀석
export const isTablet = isTabletDevice

// UI 작업용 길이 변환 함수
export const WidthToDP = (px) => {
  if (typeof window !== "undefined") {
    if (isTablet) {
      const screenWidth = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight
      return (screenWidth / 2226) * px
    } else {
      const screenWidth = window.innerWidth
      return (screenWidth / 720) * px
    }
  }
}

// 개발용 콘솔 로그
export const debug = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data)
  }
}

// 안드로이드 백 핸들러
export const androidBackHandler = (func) => {
  window.NativeDataReceive = (_data) => {
    switch (_data.Type) {
      case "Back":
        func()
        break
      default:
        break
    }
  }
}

// 전화번호 포맷
export const formatPhone = (num) => {
  let formatNum = num + ""
  if (formatNum.length < 4) {
    formatNum = formatNum.substr(0, 3)
  } else if (formatNum.length < 8) {
    formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4)
  } else if (formatNum.length < 12) {
    formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4) + "-" + formatNum.substr(7, 4)
  }
  return formatNum
}

// 날짜 포맷
// export const dateFormat = (day) => {
//   const TODAY = moment()
//   // 작년
//   if (moment(TODAY).format("YYYY") !== moment(day).format("YYYY")) return moment(day).format("YYYY.MM.DD")
//   // 어제 이상(1일 이상경과)
//   else if (moment(TODAY).subtract(1, "d").format("DD") !== moment(day).format("DD") && Math.abs(moment.duration(TODAY.diff(day)).asDays()) > 1)
//     return moment(day).format("MM월 DD일")
//   // 어제
//   else if (moment(TODAY).subtract(1, "d").format("DD") === moment(day).format("DD")) return "어제"
//   // 당일
//   else {
//     let hour = Number(moment(day).format("HH"))
//     // 오후 12시
//     if (hour === 12) return `오후 ${moment(day).format("HH:mm")}`
//     // 오후
//     else if (hour > 12) return `오후 ${hour - 12}:${moment(day).format("mm")}`
//     // 오전
//     else return `오전 ${moment(day).format("HH:mm")}`
//   }
// }

export const dateFormat = (day, format) => {
  return moment(day).locale("ko").format(format)
}

// 엑셀 다운로드 함수
export const downExcel = (props) => {
  const { result, fileName } = props
  const blob = new Blob([result], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  })

  const link = document.createElement("a")
  const href = window.URL.createObjectURL(blob)

  link.href = href
  link.download = `${fileName}.xlsx`
  link.click()
}
