import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { 

  initDataList, getDataList, saveDataList, setDataList, 
  // getComboList, getItemCodeHelpList, getCustCodeHelpList, 

  // 다국어 관련 - 차건담 2023.04.03
  initTranslationWords,
  getTranslationWords,

  // 선택한 작업장 정보 값 유지하기 위한 함수 - 차건담 2023.06.29
  initSelectedWorkCenter,
  keepWorkCenter,
} = actions.CommonAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  // sminorComboList : state.CommonReducer.sminorComboList || [],
  // uminorComboList : state.CommonReducer.uminorComboList || [],
  // workCenterComboList : state.CommonReducer.workCenterComboList || [],
  // cropComboList : state.CommonReducer.cropComboList || [],
  // cropGrowthComboList : state.CommonReducer.cropGrowthComboList || [],

  // farmComboList : state.CommonReducer.farmComboList || [],
  // sensorNodeComboList : state.CommonReducer.sensorNodeComboList || [],
  // sensorComboList : state.CommonReducer.sensorComboList || [],

  // itemCodeHelpList : state.CommonReducer.itemCodeHelpList || [],
  // custCodeHelpList : state.CommonReducer.custCodeHelpList || [],
  
  _translationWords: state.CommonReducer._translationWords || [],
  _selectedWorkCenter: state.CommonReducer._selectedWorkCenter || ''
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({

  initDataList: ( actionType ) => dispatch(initDataList( actionType )),
  getDataList: ( actionType, param, options = {} ) => dispatch(getDataList( actionType, param, options )),
  saveDataList: ( actionType, param ) => dispatch(saveDataList( actionType, param )),
  setDataList: ( param ) => dispatch(setDataList( param )),

  // getComboList: ( param ) => dispatch(getComboList( param )),
  // getItemCodeHelpList: ( param ) => dispatch(getItemCodeHelpList( param )),
  // getCustCodeHelpList: ( param ) => dispatch(getCustCodeHelpList( param )),

  // 다국어 관련 - 차건담 2023.04.03
  initTranslationWords: ( param ) => dispatch(initTranslationWords( param )),
  getTranslationWords: ( param ) => dispatch(getTranslationWords( param )),

  // 선택한 작업장 정보 값 유지 관련 - 차건담 2023.06.29
  initSelectedWorkCenter: ( param ) => dispatch(initSelectedWorkCenter( param )),
  keepWorkCenter: ( param ) => dispatch(keepWorkCenter( param )),

  // {{n}}에 다른 단어로 대체하는 함수 - 차건담 2023.02.15
  replaceWord: (wordWithN, word) => {
    const result = wordWithN.replace("{{n}}", word)
    return result
  }
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
