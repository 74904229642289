import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { IS_LOGIN, SET_USER_INFO } = actions.SignAction

// default state value
const initialState = {
  isLogin: false,
  userInfo: {}
}

// handle action
export default handleActions(
  {
    [IS_LOGIN]: (state, action) => ({
      ...state,
      isLogin: action.payload,
    }),

    [SET_USER_INFO]: (state, action) => ({
      ...state,
      userInfo: action.payload,
    }),
    
  },
  initialState
)
