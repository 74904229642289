// 앱 >일정관리
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import ScheduleContainer from "../../../containers/ScheduleContainer"
import Layout from "../../component/Layout"
import DateBox from "../../component/common/DateBox"
import WriteButton from "../../component/common/WriteButton"
import ScheduleAddPopup from "../../popup/ScheduleAddPopup"

import CropGrowthAddPopupPlan from "../../popup/CropGrowthAddPopupPlan"

import CommonContainer from "../../../containers/CommonContainer"

const Manage = (props) => {
  const {
    t,
    getCropComboList,
    getWorkCenterComboList,

    _productionPlanList,
    initProductionPlanList,
    getProductionPlanMonthList,
    getProductionPlanDayList,
    initProductionPlan,
    addProductionPlan,
    getProductionPlan,
    editProductionPlan,
    delProductionPlan,

    // web ScheduleManage에 업데이트 된 부분 추가 - 차건담 2023.03.30
      initTranslationWords, 
      getTranslationWords, 
      _translationWords, 
      replaceWord,   
      
      getCropGrowth,

  } = props
  const [schdeuleAddPopup, setScheduleAddPopup] = useState(false)
  const [displayDate, setDisplayDate] = useState(moment())
  const today = moment()

  const [cropGrowthAddPopupPlan, setCropGrowthAddPopupPlan] = useState(false)

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.04.03
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/main", { replace: true })
    })
    //
    initData()
    return async () => await initProductionPlanList()
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.03

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const initData = async () => {
    const pro1 = await getProductionPlanMonthList({ year: moment(displayDate).format("YYYY"), month: moment(displayDate).format("MM") })
    const pro2 = await getCropComboList()
    const pro3 = await getWorkCenterComboList()
    Promise.all([pro1, pro2, pro3])
  }

  const getData = async () => {
    await getProductionPlanMonthList({ year: moment(displayDate).format("YYYY"), month: moment(displayDate).format("MM") })
  }

  const generateCalendar = () => {
    const startWeek = (moment(displayDate) || today).clone().startOf("month").week()
    const endWeek =
      (moment(displayDate) || today).clone().endOf("month").week() === 1 ? 53 : (moment(displayDate) || today).clone().endOf("month").week()

    let calendar = []

    for (let week = startWeek; week <= endWeek; week++) {
      let num = 0
      let num2 = 0
      let positionStartDay = 0
      let positiondEndDay  = 0
      let plans = []
      let numposition = []
      let numposition2 = []
      calendar.push(
        <DayContainer key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = (moment(displayDate) || today)
                .clone()
                .startOf("month")
                .week(week)
                .startOf("week")
                .add(n + i, "day")
              const disabled = (moment(displayDate) || today).get("month") !== current.get("month")
              const isToday = today.format("YYYYMMDD") == moment(current).format("YYYYMMDD")

              _productionPlanList
                .filter((planItem) =>
                  moment(current).isBetween(
                    moment(planItem.dateFr).add(-1, "days").format("YYYY-MM-DD"),
                    moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                  )
                )
                .map((filteredItem) => {
                  if (plans.findIndex((p) => p.planSeq == filteredItem.planSeq) === -1) {
                    plans.push(filteredItem)
                  }
                })

              return (
                <DayBox
                  key={i}
                  isDisabled={disabled}
                  isToday={isToday}
                  onClick={(e) => {
                    if (!disabled) {
                      setDisplayDate(moment(current))
                    }
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: `0 ${WidthToDP(7)}px`,
                      border: isToday ? `${WidthToDP(3)}px solid #fadb9a` : `${WidthToDP(1)}px solid #e1e1e1`,
                      zIndex: isToday ? 100 : 0,
                      color: disabled ? "#f6f6f6" : "#555555b3",
                      fontSize: `${WidthToDP(12)}px`,
                      lineHeight: `${WidthToDP(22)}px`,
                      textAlign: "left",
                    }}
                  >
                    {current.format("D")}
                  </div>
                </DayBox>
              )
            })}
          {_productionPlanList.map((planItem, planIndex) => {
            const filterList = Array(7)
              .fill(0)
              .map((i, idx) => {
                let current = (moment(displayDate) || today).clone().startOf("month").week(week).startOf("week").add(idx, "day")
                const disabled = (moment(displayDate) || today).get("month") !== current.get("month")
                if (
                  !disabled &&
                  moment(current).isBetween(
                    moment(planItem.dateFr).add(-1, "days").format("YYYY-MM-DD"),
                    moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                  )
                )
                  return { visible: true, position: idx, current }
                else return { visible: false, position: idx, current }
              })
              .filter((i) => i.visible)

            if (filterList.length == 0) return null
              
              // 월 표시 조건 start
              positionStartDay  = filterList[0].position  // 일0/월1/화2/수3/목4/금5/토6 시작날 날짜position 데이터 들어갈 값
              positiondEndDay   = filterList[0].position == filterList[filterList.length - 1].position ? null : filterList[filterList.length - 1].position // 일0/월1/화2/수3/목4/금5/토6 마지막날 날짜position 데이터 들어갈 값
              
              if(1==1){numposition.push(positionStartDay)}
              if(1==1){numposition.push(positiondEndDay)}
  
              // console.log('numposition')
              // console.log(numposition)
              
              for(let i =positionStartDay+1; positionStartDay<i && i<positiondEndDay; i++){
                numposition.push(i) // 시작날부터 마지막날 사이 날짜position 값 넣어주는 포문
              }
              num2= (numposition.filter((i) => i == filterList[0].position).length) //요일에 현재 있는 날짜position 갯수 
              ////////////////////////////////////////////////////////////////////////////////
              
              if(numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0 ) //기존 행에서 새로 들어올 날짜위치에 이미 값이 존재하면실행 
              {  
                // console.log(numposition2.filter((i) => i.a == positionStartDay))
                // console.log(Math.max(...(numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)))+1)
                if(numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0 ){num2 = Math.max(...(numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)))+1} //시작날짜행의 최대세로값을 구한후 +1 을해줌
              }
              
              if(1==1){numposition2.push({a: positionStartDay, b: num2})} //시작날 날짜position + 해당 날짜 위치 = 가로위치 + 세로위치
              if(1==1){numposition2.push({a: positiondEndDay, b: num2})} //마지막날 날짜position + 해당 날짜 위치 = 가로위치 + 세로위치
  
              // console.log('numposition2')
              // console.log(numposition2)
  
              for(let i =positionStartDay+1; positionStartDay<i && i<positiondEndDay; i++){
                numposition2.push({a: i, b: num2}) // 시작날부터 마지막날 사이 날짜position 값 + 해당 날짜 위치 = 가로위치 + 세로위치 넣어주는 포문
              }
              
              num  = num < num2 ? num2 : num //늘어나는 행에따라 세로크기 늘여주는 변수 기존값보다 num2의 최대치만을 저장 
              // 월 표시 조건 end

            return (
              <PlanLabel
                key={planIndex}
                num={num2}
                position={filterList[0].position}
                ratio={filterList.length}
                left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? WidthToDP(7) : "0"}
                right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? WidthToDP(7) : "0"}
                onClick={(e) => {
                  let clickIndex = 0
                  if (e.clientX < window.innerWidth / 7) clickIndex = 0
                  else if (e.clientX > window.innerWidth / 7 && e.clientX < (window.innerWidth / 7) * 2) clickIndex = 1
                  else if (e.clientX > (window.innerWidth / 7) * 2 && e.clientX < (window.innerWidth / 7) * 3) clickIndex = 2
                  else if (e.clientX > (window.innerWidth / 7) * 3 && e.clientX < (window.innerWidth / 7) * 4) clickIndex = 3
                  else if (e.clientX > (window.innerWidth / 7) * 4 && e.clientX < (window.innerWidth / 7) * 5) clickIndex = 4
                  else if (e.clientX > (window.innerWidth / 7) * 5 && e.clientX < (window.innerWidth / 7) * 6) clickIndex = 5
                  else if (e.clientX > (window.innerWidth / 7) * 6 && e.clientX < (window.innerWidth / 7) * 7) clickIndex = 6

                  setDisplayDate((moment(displayDate) || today).clone().startOf("month").week(week).startOf("week").add(clickIndex, "day"))
                }}
              >
                {/* 달력막대 부분 */}
                <PlanLabelInner 
                  background={ planItem.sort == 1 ? "#FFEBB4" : "#BACDDB"}
                  left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? WidthToDP(3) : "0"}
                  right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? WidthToDP(3) : "0"}
                ></PlanLabelInner>
              </PlanLabel>
            )
          })}
          <div
            style={{
              width: "1px",
              height: WidthToDP(28) * (num + 1),
            }}
          ></div>
        </DayContainer>
      )
    }
    return calendar
  }

  const onClickAdd = async () => {
    await initProductionPlan()
    setScheduleAddPopup(true)
  }

  const onClickEdit = async (planSeq) => {
    await getProductionPlan({ planSeq })
    setScheduleAddPopup(true)
  }

  const onClickEditGrowth = async (cropGrowthSeq) => {
    await getCropGrowth({cropGrowthSeq})
    setCropGrowthAddPopupPlan(true)
  }

  const schduleAddPopupHandler = async (type, value) => {
    if (type == dictCk("save")) {
      let result = false
      if (value.planSeq == undefined) {
        result = await addProductionPlan(value)
      } else {
        result = await editProductionPlan(value)
      }
      if (result) getData()
    } else if (type == dictCk("del")) {
      const result = await delProductionPlan(value)
      if (result) getData()
    }
    setScheduleAddPopup(false)
  }

  const cropGrowthAddPopupPlanHandler = async (type, value) => {

    setCropGrowthAddPopupPlan(false)
  }

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      isHam={true} // default : false
      title={dictCk("scheduleManage")}
      isAlarm={true} // default : false
      alarmHandler={() => console.log("alarm")}
    >
      <ScheduleAddPopup open={schdeuleAddPopup} onClose={schduleAddPopupHandler} />
      <CropGrowthAddPopupPlan open={cropGrowthAddPopupPlan} onClose={cropGrowthAddPopupPlanHandler} />
      <Container>
        <DateView>
          <DateBox style={{ width: "100%", marginRight: "0" }} stdDate={displayDate} setStdDate={setDisplayDate} />
        </DateView>

        <div style={{ display: "flex", flexDirection: "column", flex: 1, overflowY: "auto" }}>
          <CalendarView>
            <WeekContainer>
              {[dictCk("sun"), dictCk("mon"), dictCk("tue"), dictCk("wed"), dictCk("thu"), dictCk("fri"), dictCk("sat")].map((day, dayIndex) => (
                <WeekBox key={dayIndex}>{day}</WeekBox>
              ))}
            </WeekContainer>
            {generateCalendar()}
          </CalendarView>

          {_productionPlanList.filter((planItem) =>
            moment(displayDate).isBetween(moment(planItem.dateFr).add(-1, "days"), moment(planItem.dateTo).add(1, "days"))
          ).length != 0 && (
            <PlanView>
              {_productionPlanList
                .filter((planItem) => moment(displayDate).isBetween(moment(planItem.dateFr).add(-1, "days"), moment(planItem.dateTo).add(1, "days")))
                .map((i, idx) => (
                  <div
                    key={idx}
                    style={{
                      backgroundColor: i.sort == 1 ? "#FFEBB4" : "#BACDDB",
                      width: "100%",
                      minHeight: `${WidthToDP(269)}px`,
                      marginBottom: `${WidthToDP(30)}px`,
                      padding: `${WidthToDP(24)}px ${WidthToDP(32)}px`,
                    }}
                    onClick={() => i.sort == 1 ? onClickEdit(i.planSeq) : onClickEditGrowth(i.planSeq)}
                  >
                    <p style={{ color: "#55555580", fontSize: `${WidthToDP(25)}px`, display: 1==2 ? 'none' : 'flex'}}>{moment(displayDate).format("YYYY-MM-DD")}</p>
                    <p style={{ marginTop: `${WidthToDP(22)}px`, color: "#555555", fontSize: `${WidthToDP(27)}px`, display: i.sort == 1 && i.cropName == null ? 'flex' : 'none'}}>{dictCk("content")}{" : "}{i.content}</p>

                    <p style={{ marginTop: `${WidthToDP(22)}px`, color: "#555555", fontSize: `${WidthToDP(27)}px`, display: i.sort == 1 && i.cropName != null ? 'flex' : 'none'}}>{dictCk("cropName")}{" : "}{i.cropName}{"/"}{dictCk("harvestPlanQuantity")}{" : "}{i.growthStepName}{"/"}{i.harvPlanQty}</p>

                    <p style={{ marginTop: `${WidthToDP(22)}px`, color: "#555555", fontSize: `${WidthToDP(27)}px`, display: i.sort == 1 ? 'none' : 'flex' }}>{dictCk("cropName")}{" : "}{i.cropName}{"/"}{dictCk("growthStep")}{" : "}{i.growthStepName}</p>
                  </div>
                ))}
            </PlanView>
          )}
        </div>

        <WriteButton onClick={onClickAdd} />
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const DateView = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${WidthToDP(160)}px;
  padding: 0 ${WidthToDP(40)}px;
`
const CalendarView = styled.div``
const WeekContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${WidthToDP(30)}px;
`
const WeekBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* width: calc(100% / 7); */
  height: 100%;
  margin-left: -1px;
  color: #555555b3;
  font-size: ${WidthToDP(15)}px;
  font-weight: 500;
  border: 1px solid #e1e1e1;
`
const DayContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: -1px;
`
const DayBox = styled.button`
  background-color: ${(props) => (props.isDisabled ? "#f6f6f6" : "#fff")};
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  /* width: calc(100% / 7); */
  min-height: ${WidthToDP(80)}px;
  margin-left: -1px;
`
const PlanLabel = styled.button`
  position: absolute;
  top: calc((${WidthToDP(25)}px) * (${(props) => props.num}));
  left: calc(100% / 7 * (${(props) => props.position}));
  width: calc(100% / 7 * (${(props) => props.ratio}));
  height: ${WidthToDP(17)}px;
  padding-left: ${(props) => props.left}px;
  padding-right: ${(props) => props.right}px;
`
const PlanLabelInner = styled.div`
  background-color: ${(props) => props.background};
  width: 100%;
  height: ${WidthToDP(17)}px;
  border-top-left-radius: ${(props) => props.left}px;
  border-bottom-left-radius: ${(props) => props.left}px;
  border-top-right-radius: ${(props) => props.right}px;
  border-bottom-right-radius: ${(props) => props.right}px;
`
const PlanView = styled.div`
  background-color: #f6f6f6;
  flex: 1;
  padding: ${WidthToDP(30)}px ${WidthToDP(40)}px ${WidthToDP(256)}px;
`

export default CommonContainer(ScheduleContainer(withTranslation()(Manage)))
