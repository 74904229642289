import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getCropReportList,
  getCropReportInfo,
  getCropReportSearch,
  postCropReportAdd,
  postCropReportMod,
  getCropHistInfo,
  getASList,
  saveAS,
  initASList,
  initCropReportList,
  initCropReportInfo,
} = actions.CropAction
const { getSMinorComboList, getUMinorComboList, getCropComboList, getCropGrowthComboList, getWorkCenterComboList, getStaffComboList } = actions.CommonAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  cropReportList: state.CropReducer.cropReportList,
  cropReportInfo: state.CropReducer.cropReportInfo,
  cropReportSearch: state.CropReducer.cropReportSearch,
  sminorComboList: state.CropReducer.sminorComboList,
  uminorComboList: state.CropReducer.uminorComboList,
  cropComboList: state.CropReducer.cropComboList,
  staffComboList: state.CropReducer.staffComboList, // 재배인력 리스트 - 차건담 2023.04.04
  cropGrowthComboList: state.CropReducer.cropGrowthComboList,
  workCenterComboList: state.CropReducer.workCenterComboList,
  cropHistInfo: state.CropReducer.cropHistInfo,
  asList: state.CropReducer.asList,
  asListTotal: state.CropReducer.asListTotal,
  cropReportListTotal: state.CropReducer.cropReportListTotal,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getCropReportList: (param) => dispatch(getCropReportList(param)),
  getCropReportInfo: (param) => dispatch(getCropReportInfo(param)),
  getCropReportSearch: (param) => dispatch(getCropReportSearch(param)),
  getSMinorComboList: (param) => dispatch(getSMinorComboList(param)),
  getUMinorComboList: (param) => dispatch(getUMinorComboList(param)),
  postCropReportAdd: (param) => dispatch(postCropReportAdd(param)),
  getCropComboList: (param) => dispatch(getCropComboList(param)),
  getStaffComboList: (param) => dispatch(getStaffComboList(param)), // 재배인력 리스트 - 차건담 2023.04.04
  getCropGrowthComboList: (param) => dispatch(getCropGrowthComboList(param)),
  getWorkCenterComboList: (param) => dispatch(getWorkCenterComboList(param)),
  postCropReportMod: (param) => dispatch(postCropReportMod(param)),

  getCropHistInfo: (param) => dispatch(getCropHistInfo(param)),
  initASList: (param) => dispatch(initASList(param)),
  getASList: (param) => dispatch(getASList(param)),
  saveAS: (param) => dispatch(saveAS(param)),

  initCropReportList: (param) => dispatch(initCropReportList(param)),
  initCropReportInfo: (param) => dispatch(initCropReportInfo(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
