import React from "react"
import { Router } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import { setServerErrorPopup } from "../actions/ConfigAction"
import PrivateRoute from "../components/privateRoute"
import "../components/layout.css"
import BasicPopup from "../components/popup/BasicPopup"
import Page404 from "./404"
import Root from "../components/page/Root"
import Sample from "../components/page/Sample"
import Login from "../components/page/Login"
import Main from "../components/page/Main"
// import MainPage from "../components/page/mainMenu/mainPage"
import SensorData from "../components/page/mainMenu/SensorData"
// import Statis from "../components/page/mainMenu/Statis"

import Diary from "../components/page/crop/Diary"
import DiaryAdd from "../components/page/crop/DiaryAdd"
import CropHist from "../components/page/crop/CropHist"
import AS from "../components/page/crop/AS"

import EquipCtrl from "../components/page/equip/EquipCtrl"
import DefaultSetting from "../components/page/setting/DefaultSetting"
import AlarmInfoList from "../components/page/alarmInfo/AlarmInfoList"
import SearchArea from "../components/component/SearchArea"

import ScheduleManage from "../components/page/schedule/Manage"

import EquipLogList from "../components/page/log/EquipLogList"

// 복합제어 - 목표값 조건변경
import ConfReSettingAdd from "../components/page/autoConfReSetting/ConfReSettingAdd"

const App = () => {
  const dispatch = useDispatch()
  const serverErrorPopup = useSelector((state) => state.ConfigReducer.serverErrorPopup)

  return (
    <>
      <BasicPopup
        open={serverErrorPopup.open}
        onClose={() => dispatch(setServerErrorPopup({ ...serverErrorPopup, open: false }))}
        content={serverErrorPopup.text}
        selectedValue={['확인']}
        
      />
      <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
        {/* 샘플 페이지 */}
        <Sample path="/sample" />

        <Login path="/login" />
        <PrivateRoute component={Root} path="/" />
        {/* <PrivateRoute component={Main} path="/main" /> */}
        <PrivateRoute component={Main} path="/main" />
        <PrivateRoute component={SensorData} path="/mainMenu/sensorData" />
        {/* <PrivateRoute component={Statis} path="/mainMenu/statis" /> */}
        <PrivateRoute component={Diary} path="/crop/diary" />
        <PrivateRoute component={DiaryAdd} path="/crop/diaryAdd" />
        <PrivateRoute component={CropHist} path="/crop/CropHist" />
        <PrivateRoute component={AS} path="/crop/AS" />

        <PrivateRoute component={EquipCtrl} path="/equip/equipCtrl" />
        <PrivateRoute component={DefaultSetting} path="/setting/defaultSetting" />
        <PrivateRoute component={AlarmInfoList} path="/alarmInfo/alarmInfoList" />
        <PrivateRoute component={SearchArea} path="/component/searchArea" />

        <PrivateRoute component={ScheduleManage} path="/schedule/manage" />
        <PrivateRoute component={EquipLogList} path="/log/EquiplogList" />
        <PrivateRoute component={ConfReSettingAdd} path="autoConfReSetting/ConfReSettingAdd" />
        {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
        <Page404 path="/*" />
      </Router>
    </>
  )
}

export default App
