import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { 
  // SET_WEATHER_FCST, SET_WEATHER_NCST, 
  SET_WEATHER_INFO,
  SET_WORK_CENTER_LIST,

  SET_INFO_MAIN_LIST, 
  SET_INFO_SENSOR_DATA_LIST,  
  SET_SENSOR_DATA_CHART_LIST,

  SET_TODAY_ALARM_INFO,

  // SET_SENSOR_DATA_FLAG 

} = actions.MainAction

// default state value
const initialState = {
  // weatherFcst: {},
  // weatherNcst: {},
  weatherInfo : {
    "address": "서울특별시",
    "fcst": {
        "sky": "1",
        "pty": "0",
        "tmn": "22.0",
        "tmx": "32.0"
    },
    "ncst": {
        "t1h": "23.5",
        "reh": "72"
    }
  },
  workCenterList: [],

  infoMainList: [],
  infoSensorDataList: [],
  sensorDataChartList: [],
  todayAlarmInfo: 0
}

// handle action
export default handleActions(
  {
    // [SET_WEATHER_FCST]: (state, action) => ({
    //   ...state,
    //   weatherFcst: action.payload,
    // }),
    // [SET_WEATHER_NCST]: (state, action) => ({
    //   ...state,
    //   weatherNcst: action.payload,
    // }),

    [SET_WEATHER_INFO]: (state, action) => ({
      ...state,
      weatherInfo: action.payload,
    }),
    [SET_WORK_CENTER_LIST]: (state, action) => ({
      ...state,
      workCenterList: action.payload,
    }),

    [SET_INFO_MAIN_LIST]: (state, action) => ({
      ...state,
      infoMainList: action.payload,
    }),
    [SET_INFO_SENSOR_DATA_LIST]: (state, action) => ({
      ...state,
      infoSensorDataList: action.payload,
    }),
    
    [SET_SENSOR_DATA_CHART_LIST]: (state, action) => ({
      ...state,
      sensorDataChartList: action.payload,
    }),

    [SET_TODAY_ALARM_INFO]: (state, action) => ({
      ...state,
      todayAlarmInfo: action.payload.cnt,
    }),
    
    // [SET_SENSOR_DATA_FLAG]: (state, action) => {
      
    //   const { workCenterSeq, sensorSeq, flag } = action.payload

    //   state = { 
    //     ...state, 
    //     infoMainList : state.infoMainList.map( row => 
          
    //       row.sensorSeq == sensorSeq ? { ...row, flag } : 
    //       row.flag == flag ? { ...row, flag : 0 } : 
    //       row 
          
    //     )
    //   }

    //   return state;

    // },
  },
  initialState
)
