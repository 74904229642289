import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

import Layout from "../../component/Layout"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"

import i18n from "../../../lang/i18n"
import { LANGUAGE_CODE } from "../../../config"

import SignContainer from "../../../containers/SignContainer"
import SettingContainer from "../../../containers/SettingContainer"
import BasicPopup from "../../popup/BasicPopup"

import InputTimeBox from "../../component/common/InputTimeBox"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import CheckBox from "../../component/common/CheckBox"
import Switch from "../../component/common/Switch"

import CommonContainer from "../../../containers/CommonContainer"

const DefaultSetting = (props) => {
  const {
    t,
    setUserInfo,
    userInfo,

    getSettingInfo,
    settingInfo,
    postSettingMod,

    getSMinorComboList,
    getUMinorComboList,
    sminorComboList,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props

  const [value, setValue] = useState(0) //langSetting
  const [curValue, setCurValue] = useState(0) //currency
  const [curNumValue, setCurNumValue] = useState(0) //exchangeRate
  const [alarmValue, setAlarmValue] = useState("00:00") //alarmTime
  const [userList, setUserList] = useState([])
  const [switchPush, setSwitchPush] = useState(false)
  const [switchSnsAlarm, setSwitchSnsAlarm] = useState(false)
  const [centerSeq, setCenterSeq] = useState(0)
  const [popup, setPopup] = useState({ open: false, text: "" })

  const getData = async () => {
    await getSettingInfo()
    await getSMinorComboList()
    await getUMinorComboList()
  }

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/main", { replace: true })
    })
    //

    getTranslationWords()
    getData()
  }, [])

  useEffect(() => {
    if (settingInfo && Object.keys(settingInfo).length > 0) {
      setCenterSeq(settingInfo.settingSeq)
      setUserList(settingInfo.userList)
      setValue(settingInfo.languageSeq)
      setCurValue(settingInfo.currSeq)
      setCurNumValue(settingInfo.exRate)
      setAlarmValue(settingInfo.alarmTime)
      setSwitchPush(settingInfo.isMobileAlarm == 1 ? true : false)
      setSwitchSnsAlarm(settingInfo.isKakaoAlarm == 1 ? true : false)
    }
  }, [settingInfo])

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

   const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onSetLang = (languageSeq) => {
    setValue(languageSeq)

    // const languageCode = LANGUAGE_CODE[languageSeq]

    // i18n.changeLanguage(languageCode)

    // setUserInfo({ ...userInfo, setting: { ...userInfo.setting, languageSeq } })
  }

  const onClickSave = async () => {
    let settingData = {
      settingSeq: centerSeq,
      languageSeq: value,
      currSeq: curValue,
      exRate: curNumValue,
      alarmTime: alarmValue,
      isMobileAlarm: switchPush ? 1 : 0,
      isKakaoAlarm: switchSnsAlarm ? 1 : 0,
      userSeqs: userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq),
    }
    let result = await postSettingMod(settingData)
    if (result) setPopup({ open: true, text: dictCk("saved successfully.") })
  }

  const onClose = async () => {

    setPopup({ open: false, text: "" })

    const languageSeq = value
    const languageCode = LANGUAGE_CODE[languageSeq]

    i18n.changeLanguage(languageCode)

    const data = { ...userInfo, setting: { ...userInfo.setting, languageSeq } }

    setUserInfo( data )
    
    // location.reload();

  }

  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: white, 1: Orange
      title={dictCk("envSetting")}
    >
      <Container>
        <BasicPopup open={popup.open} onClose={() => onClose()} content={popup.text} selectedValue={[dictCk("confirm")]} />
        <ComboPart subject={dictCk("langSetting")} value={value} setValue={onSetLang} ctrlSeq="1001" items={sminorComboList} t={t} dict={dict}/>
        <ComboPart subject={dictCk("currency")} value={curValue} setValue={setCurValue} ctrlSeq="1013" items={sminorComboList} t={t} dict={dict}/>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("exchangeRate")}</DefaltFont>
          </section>

          <InputBox placeholder={dictCk("amt")} type="number" useComma={true} value={curNumValue} onChange={(e) => setCurNumValue(e.target.value)} />
        </SubjectSection>
        <section style={{ marginBottom: `${WidthToDP(30)}px`, marginTop: `${WidthToDP(24)}px` }}>
          <DefaltFont fw="700">{dictCk("alarmSetting")}</DefaltFont>
        </section>
        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("alarmTime")}</DefaltFont>
          </section>

          <InputTimeBox style={{ width: "100%" }} disabled={false} value={alarmValue} setValue={setAlarmValue} />
        </SubjectSection>
        <div style={{ display: "flex", height: `${WidthToDP(100)}px`, marginBottom: `${WidthToDP(35)}px` }}>
          <section style={{ display: "flex", width: `${WidthToDP(402)}px`, height: "100%", alignItems: "center" }}>
            <DefaltFont>{dictCk("pushAlarm")}</DefaltFont>
          </section>
          <section style={{ display: "flex", alignItems: "center" }}>
            <Switch bool={switchPush} setBool={setSwitchPush} />
          </section>
        </div>
        {/* <div style={{ display: "flex", height: `${WidthToDP(100)}px` }}>
          <section style={{ display: "flex", width: `${WidthToDP(402)}px`, height: "100%", alignItems: "center" }}>
            <DefaltFont>{t("kakao")}</DefaltFont>
          </section>
          <section style={{ display: "flex", alignItems: "center" }}>
            <Switch bool={switchSnsAlarm} setBool={setSwitchSnsAlarm} />
          </section>
        </div> */}
        <div style={{ display: "flex", marginTop: `${WidthToDP(66)}px` }}>
          <section style={{ display: "flex", width: `${WidthToDP(193)}px`, height: "100%" }}>
            <DefaltFont>{dictCk("recipient")}</DefaltFont>
          </section>
          <section style={{ alignItems: "center" }}>
            {Object.keys(settingInfo).length > 0 &&
              userList.map((i, idx) => (
                <CheckBox
                  key={idx}
                  text={i.userName}
                  style={{ marginBottom: `${WidthToDP(59)}px` }}
                  check={i.isCheck == 1}
                  setCheck={(bool) => {
                    const temp = [...userList]
                    temp[idx].isCheck = bool ? 1 : 0
                    setUserList(temp)
                  }}
                />
              ))}
          </section>
        </div>

        <div style={{ display: "flex", marginTop: `${WidthToDP(31)}px` }}>
          <CustomBtn width={`100%`} onClick={onClickSave}>
            {dictCk("save")}
          </CustomBtn>
        </div>
      </Container>
    </Layout>
  )
}

export default CommonContainer(SignContainer(SettingContainer(withTranslation()(DefaultSetting))))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(30)}px ${WidthToDP(60)}px;
  background-color: #f6f6f6;
`

const DefaltFont = styled.p`
  font-size: ${WidthToDP(30)}px;
  line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(45)}px`)};
  font-weight: ${(props) => (props.fw ? props.fw : "#555555")};
  color: ${(props) => (props.color ? props.color : "#555555")};
`
const CustomBtn = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${WidthToDP(100)}px;
  border: ${(props) => (props.emty ? "1px solid #c6c6c6" : "none")};
  width: ${(props) => (props.width ? props.width : `${WidthToDP(280)}px`)};
  border-radius: ${WidthToDP(4)}px;
  background-color: ${(props) => (props.emty ? "" : "#52935d")};
  border: ${(props) => (props.emty ? "1px solid #c6c6c6" : "none")};
  color: ${(props) => (props.emty ? "#b4b4b4" : "#ffffff")};
  margin-right: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
  margin-left: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
`

const SubjectSection = styled.section`
  display: flex;
  flex-direction: column;

  /* margin-top: ${(props) => (props.mr ? props.mr : `${WidthToDP(20)}px`)}; */
  margin-bottom: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
`

const ComboPart = (props) => {
  const { t, subject, items, value, setValue, ctrlSeq, dict } = props
  return (
    <>
      <SubjectSection>
        <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
          <DefaltFont>{subject}</DefaltFont>
        </section>
        <ComboBox
          style={{ backgroundColor: "#ffffff", width: "100%" }}
          defaultValue={dict["select"]}
          hasDefault={false}
          items={items && items.filter((row) => row.majorSeq == ctrlSeq).map((row) => ({ value: row.minorSeq, label: row.minorName }))}
          value={value}
          setValue={setValue}
        />
      </SubjectSection>
    </>
  )
}
