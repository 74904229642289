import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"
// import MainApis from "../apis/_MainApis"

/* action type */
// export const SET_WEATHER_FCST = "@elefarm-app/main/SET_WEATHER_FCST"
// export const SET_WEATHER_NCST = "@elefarm-app/main/SET_WEATHER_NCST"

export const SET_WEATHER_INFO = "@elefarm-app/main/SET_WEATHER_INFO"
export const SET_WORK_CENTER_LIST = "@elefarm-app/main/SET_WORK_CENTER_LIST"
export const SET_INFO_MAIN_LIST = "@elefarm-app/main/SET_INFO_MAIN_LIST"
export const SET_INFO_SENSOR_DATA_LIST = "@elefarm-app/main/SET_INFO_SENSOR_DATA_LIST"
export const SET_SENSOR_DATA_CHART_LIST = "@elefarm-app/main/SET_SENSOR_DATA_CHART_LIST"
export const SET_TODAY_ALARM_INFO = "@elefarm-app/main/SET_TODAY_ALARM_INFO"

// export const SET_SENSOR_DATA_FLAG = "@elefarm-app/main/SET_SENSOR_DATA_FLAG"

/* action function */
// export const setWeatherFcst = createAction(SET_WEATHER_FCST)
// export const setWeatherNcst = createAction(SET_WEATHER_NCST)

export const setWeatherInfo = createAction(SET_WEATHER_INFO)
export const setWorkCenterList = createAction(SET_WORK_CENTER_LIST)
export const setInfoMainList = createAction(SET_INFO_MAIN_LIST)
export const setInfoSensorDataList = createAction(SET_INFO_SENSOR_DATA_LIST)
export const setSensorDataChartList = createAction(SET_SENSOR_DATA_CHART_LIST)
export const setTodayAlarmInfo = createAction(SET_TODAY_ALARM_INFO)

// export const setSensorDataFlag = createAction(SET_SENSOR_DATA_FLAG)

/* action api function */

export const getWorkCenterList = (param) => async (dispatch) => {
  // const result = await MainApis.getWorkCenterList(param)
  const result = await CommonApis.getComboList({ queryKind: "WorkCenter" })

  if (result.code == 0) {
    dispatch(setWorkCenterList(result.data.dataList))

    return true
  } else return false
}

export const getWeatherInfo = (param) => async (dispatch) => {
  // const result = await MainApis.getWeatherInfo(param)
  const result = await CommonApis.getApi("/info/main/weather/info", param)

  if (result.code == 0) {
    // dispatch(setWeatherFcst(result.data.fcst))
    // dispatch(setWeatherNcst(result.data.ncst))

    dispatch(setWeatherInfo(result.data.dataList))

    return true
  } else return false
}

export const getInfoMainList = (param) => async (dispatch) => {
  // const result = await MainApis.getInfoMainList(param)
  const result = await CommonApis.getApi("/info/main/list", param)

  if (result.code == 0) {
    dispatch(setInfoMainList(result.data.dataList))

    return true
  } else return false
}

export const postSortNoMod = (param) => async (dispatch) => {
  const workCenterSeq = param.workCenterSeq

  const data = param.dataList.map((row, idx) => ({ sensorSeq: row.sensorSeq, sortNo: idx + 1 }))

  // const result = await MainApis.postSortNoMod(param)
  const result = await CommonApis.postApi("/info/main/sensorSub/mod", { workCenterSeq, subList: data })

  if (result.code == 0) {
    dispatch(setInfoMainList(param.dataList))

    return true
  } else return false
}

export const getInfoSensorDataList = (param) => async (dispatch) => {
  // const result = await MainApis.getInfoSensorDataList(param)
  const result = await CommonApis.getApi("/info/main/sensorData/list", param)

  if (result.code == 0) {
    dispatch(setInfoSensorDataList(result.data.dataList))

    return true
  } else return false
}

export const modSensorDataFlag = (param) => async (dispatch) => {
  const workCenterSeq = param.workCenterSeq

  const data = param.dataList.filter((row) => row.flag > 0).map((row, idx) => ({ sensorSeq: row.sensorSeq, flag: row.flag }))

  // const result = await MainApis.postSortNoMod(param)
  const result = await CommonApis.postApi("/info/main/sensorFlag/mod", { flagList: data, workCenterSeq })

  if (result.code == 0) {
    // dispatch(setSensorDataFlag(param))
    dispatch(setInfoMainList(param.dataList))

    return true
  } else return false
}

export const getSensorDataChartList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/info/main/sensorData/chart", param)

  if (result.code == 0) {
    dispatch(setSensorDataChartList(result.data.dataList))

    return true
  } else return false
}

export const getTodayAlarmInfo = (param) => async (dispatch) => {
  // 오늘의 알림

  const result = await CommonApis.getApi("/info/main/userAlarm/info", param)

  if (result.code == 0) {
    dispatch(setTodayAlarmInfo(result.data.dataList))

    return true
  } else return false
}

export const SENSOR_DATA_CHART_DAY = { uri : '/info/main/sensorData/chart', id : 'sensorDataChartDay', initData : [] }
export const SENSOR_DATA_CHART_MONTH = { uri : '/info/main/sensorData/chart', id : 'sensorDataChartMonth', initData : [] }
