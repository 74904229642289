// 복합제어 - 목표값 조건변경
import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
    //조건제어[복합]-목표값 조건변경
    getConfReSetting,
    addConfReSetting,
    editConfReSetting,
} = actions.EnvControlAction
const { getWorkCenterList } = actions.MainAction
const { getSMinorComboList, getUMinorComboList } = actions.CommonAction 

// redux state > component mapping
const mapStateToProps = (state) => ({

    //조건제어[복합]-목표값 조건변경
    _confReSettingInfo: state.EnvControlReducer._confReSettingInfo,
    workCenterList: state.MainReducer.workCenterList,

    sminorComboList: state.SettingReducer.sminorComboList,
    uminorComboList: state.SettingReducer.uminorComboList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    //조건제어[복합]-목표값 조건변경
    getConfReSetting: (param) => dispatch(getConfReSetting(param)),
    addConfReSetting: (param) => dispatch(addConfReSetting(param)),
    editConfReSetting: (param) => dispatch(editConfReSetting(param)),

    getWorkCenterList: (param) => dispatch(getWorkCenterList(param)),

    getSMinorComboList: (param) => dispatch(getSMinorComboList(param)),
    getUMinorComboList: (param) => dispatch(getUMinorComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
