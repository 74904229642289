// 앱 복합제어 - 목표값 조건변경
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../../component/Layout"
import { createTheme, makeStyles, ThemeProvider, withStyles } from "@material-ui/core/styles"
import { Dialog, Button } from "@material-ui/core"
import { ArrowBackIos, Stop, ArrowForwardIos } from "@material-ui/icons"
import { GreenButton, GrayButton } from "../../component/common/Button"
import RequiredMark from "../../component/common/RequiredMark"
import { withTranslation } from "react-i18next"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"
import InputBox from "../../component/common/InputBox"
import AutoEnvSettingConfContainerComp from "../../../containers/AutoEnvSettingConfContainerComp"
import AlertPopup from "../../popup/AlertPopup"
import CircularIndeterminate from "../../component/common/CircularIndeterminate" // 로딩화면
import ComboBox from "../../component/common/ComboBox"
import CommonContainer from "../../../containers/CommonContainer"

import { SkyButton } from "../../component/common/Button"
// import {BallTriangle} from "react-loader-spinner";

const ConfReSettingAdd = (props) => {
  const {
    t,
    getWorkCenterList,
    workCenterList,

    // 다국어 관련 - 차건담 2023.04.03
    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

    getConfReSetting,
    addConfReSetting,
    editConfReSetting,
    _confReSettingInfo,

    getSMinorComboList, 
    getUMinorComboList,
    sminorComboList,
    uminorComboList,
    
    
  } = props

  const [equipReSettingSeq, setEquipReSettingSeq] = useState(0) // ReSettingSeq 코드
  const [workCenterSeq, setWorkCenterSeq] = useState(0) // 작업장코드
  const [standardKind, setStandardKind] = useState(0) //조건기준
  const [standardKindValue, setStandardKindValue] = useState("") //조건기준 - 센서값
  const [targetValueFr, setTargetValueFr] = useState(0) // 목표 설정값Fr
  const [targetValueTo, setTargetValueTo] = useState(0) // 목표 설정값To
  const [changeValue, setChangeValue] = useState("") // 변경할 값
  let [changeValueDefault, setChangeValueDefault] = useState(0) // 변경할 값 디폴트
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  useEffect(() => {

    getData()
    getTranslationWords() // 다국어 가져오는 함수 - 차건담 2023.04.03
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate("/app/main", { replace: true })
    })
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.03

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async () => {
    await getWorkCenterList();
    // await getConfReSetting({ workCenterSeq : 161, standardKind : 10150001 })

    await getSMinorComboList() //공통콤보
    await getUMinorComboList() //사용자정의콤보
  }

  // 작업장 OR 조건기준 변경시 값 다시 SET
  const onSelectChange = async () => {
    const itemLength = Object.keys(_confReSettingInfo).length
    setEquipReSettingSeq(itemLength > 0 ? _confReSettingInfo.equipReSettingSeq : 0)
    // setWorkCenterSeq(itemLength > 0 ? _confReSettingInfo.workCenterSeq : workCenterSeqs)
    // setStandardKind(itemLength > 0 ? _confReSettingInfo.standardKind : standardKinds)
    setStandardKindValue(itemLength > 0 ? _confReSettingInfo.standardKindValue : "")
    setTargetValueFr(itemLength > 0 ? _confReSettingInfo.targetValueFr : 0)
    setTargetValueTo(itemLength > 0 ? _confReSettingInfo.targetValueTo : 0)
    setChangeValue(itemLength > 0 ? _confReSettingInfo.changeValue : "")
    setChangeValueDefault(itemLength > 0 ? _confReSettingInfo.changeValue : "")
  }

  const fetchData = async () => {
    try {
        const result = await getConfReSetting({ workCenterSeq: workCenterSeq, standardKind: standardKind })
    } catch (error) {
        // 에러 처리
    }
  } 

  // 작업장 OR 조건기준 변경시 값 다시 SET
  useEffect(() => {

      fetchData()
  }, [workCenterSeq, standardKind])

  useEffect(() => {
      onSelectChange()
  }, [_confReSettingInfo])

  const onClickSave = async () => {
    if (workCenterSeq === undefined || workCenterSeq === 0 || workCenterSeq == null)
        return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["workCenter"]) })
    // 작업장
    else if (standardKind === undefined || standardKind === 0 || standardKind == null)
        return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["standardKind"]) })
    //조건기준

    changeValueDefault =
    parseInt(changeValueDefault == "" || undefined || null ? 0 : changeValueDefault) * -1 +
    parseInt(changeValue == "" || undefined || null ? 0 : changeValue)

    let data = {
        // equipReSettingSeq:0,
        workCenterSeq: workCenterSeq,
        standardKind: standardKind,
        targetValueFr: targetValueFr,
        targetValueTo: targetValueTo,
        changeValue: changeValue == "" ? 0 : changeValue,
    }

    let result = false
    if (equipReSettingSeq != 0 && equipReSettingSeq != undefined) {
        data.equipReSettingSeq = equipReSettingSeq
        data.changeValueDefault = changeValueDefault
        result = await editConfReSetting(data)
    } else {
        result = await addConfReSetting(data)
    }
    if (result) {
      window.alert(dictCk("ithasBeenChanged"));
      fetchData()
    }

} // onClickSave end----------------------------------------------------

  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("changeTargetValueCondition")}
    >   
    <AlertPopup
        bg={false}
        open={alertPopup.open}
        onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
        content={alertPopup.text}
    />
      <Container>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("workCenter")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={workCenterList.filter((i) => i.isUse == '1').map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
            value={workCenterSeq}
            setValue={setWorkCenterSeq}
          />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>
              <RequiredMark />
              {dictCk("standardKind")}
            </DefaltFont>
          </section>
          <ComboBox
            style={{ backgroundColor: "#ffffff", width: "100%" }}
            defaultValue={dictCk("select")}
            hasDefault={true}
            items={sminorComboList.filter((i) => i.majorSeq == 1015).map((row) => ({ value: row.minorSeq, label: row.minorName }))}
            value={standardKind}
            setValue={setStandardKind}
          />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("present")+" "+dictCk("sensor")+dictCk("value")}</DefaltFont>
          </section>
          <InputBox style={{ width: "100%" }} type="string" disabled={true} value={standardKindValue} onChange={(e) => setStandardKindValue(e.target.value)} />
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("targetSettingValue")}</DefaltFont>
          </section>
          <div style={{ display:"flex" ,alignItems: "center",}}>
          <InputBox style={{ width: "48%" ,marginLeft:"0px", marginRight:"5px"}} type="number" value={targetValueFr} onChange={(e) => setTargetValueFr(e.target.value)} />
          ~
          <InputBox style={{ width: "48%" ,marginLeft:"5px", marginRight:"0px"}} type="number" value={targetValueTo} onChange={(e) => setTargetValueTo(e.target.value)} />
          </div>
        </SubjectSection>

        <SubjectSection>
          <section style={{ marginBottom: `${WidthToDP(16)}px` }}>
            <DefaltFont>{dictCk("correctionValue")+"(+/-)"}</DefaltFont>
          </section>
          <InputBox style={{ width: "100%" }} type="text" value={changeValue} onChange={(e) => setChangeValue(e.target.value)} />
        </SubjectSection>

        <div style={{ display: "flex", marginTop: `${WidthToDP(54)}px` }}>
          <GreenButton
            width={`100%`}
            style={{}}
            onClick={onClickSave}
          >
            {dictCk("save")}
          </GreenButton>
        </div>
      </Container>
    </Layout>
  )
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(40)}px;
  background-color: #f6f6f6;
`

const DefaltFont = styled.p`
  font-size: ${WidthToDP(30)}px;
  line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(45)}px`)};
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  color: ${(props) => (props.color ? props.color : "#555555")};
`

const SubjectSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.mb ? props.mb : `${WidthToDP(20)}px`)};
`

export default CommonContainer(AutoEnvSettingConfContainerComp(withTranslation()(ConfReSettingAdd)))