import React, { useState } from "react"
import styled from "styled-components"
import { WidthToDP } from "../../../utils/util"

const Footer = (props) => {
  
  const { value, setValue, workCenterList } = props

  return (
    // <div style={{ borderTop: "1px solid #ccc" }}>
    <CategoryDiv
      margin={`${WidthToDP(15)}px ${WidthToDP(33)}px ${WidthToDP(15)}px ${WidthToDP(33)}px`}  
    >
      {workCenterList.map(( row, idx) => (
      <CategoryItem key={idx + 1} onClick={() => setValue(row.workCenterSeq)}>
        <CategoryName 
          color={row.workCenterSeq == value ? '#00C73C' : '#555555'} 
          borderBottom={row.workCenterSeq == value ? `${WidthToDP(10)}px solid #00C73C;` : ''} 
          style={row.workCenterSeq == value ? {} : {opacity: 0.4}} 
        >
          {row.workCenterName}
        </CategoryName>
      </CategoryItem>
      ))}
      <LastMargin>
        <div />
      </LastMargin>
    </CategoryDiv>
    // </div>
  )
}

export default Footer

const CategoryDiv = styled.div`
  display: flex;
  width: ${(props) => props.width};
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: auto;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${WidthToDP(192.8)}px;
  margin-right: ${WidthToDP(15.5)}px;
`

// const CategoryName = styled.div`
//   flex-wrap: wrap;
//   width: ${WidthToDP(200)}px;
//   overflow: hidden;
//   text-overflow: hidden;
//   white-space: nowrap;
//   white-space: normal;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   font-size: ${WidthToDP(29)}px;
//   text-align: center;
//   line-height: ${WidthToDP(43)}px;
//   padding: 10px 0px 26px 0px;
//   border-bottom: 5px solid #52935d;
// `

const CategoryName = styled.div`
  flex-wrap: wrap;

  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-align: center;
  padding: ${WidthToDP(10)}px ${WidthToDP(0)}px ${WidthToDP(50)}px ${WidthToDP(0)}px;
  border-bottom: ${(props) => props.borderBottom};

  width: ${WidthToDP(200)}px;
  height: ${WidthToDP(43)}px;

  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(26)}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color};

`
const LastMargin = styled.div`
  /* 메뉴마진상쇄방지 */
  display: "flex";
  width: ${WidthToDP(14.5)}px;
  height: 100%;
  div {
    width: ${WidthToDP(14.5)}px;
    height: ${WidthToDP(42)}px;
  }
`