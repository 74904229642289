// 앱 메인
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../component/Layout"
import { WidthToDP } from "../../utils/util"
import Switch from "../component/common/Switch"
import CircleCheckBox from "../component/common/CircleCheckBox"
import CheckBox from "../component/common/CheckBox"
import Radio from "../component/common/Radio"
import MainPage from "./mainMenu/MainPage"
// import Footer from "./mainMenu/Footer"

const Main = (props) => {
  // const [switchOn, setSwitchOn] = useState(false)
  // const [check, setCheck] = useState(false)
  // const [radioIndex, setRadioIndex] = useState(0)

  return (
    <Layout
      isHeader={true} // default : false
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      isHam={true} // default : false
      isAlarm={true} // default : false
      alarmHandler={() => console.log("alarm")}
    >
      <Container>
        <MainPage />
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`

export default Main
