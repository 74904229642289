import { createAction } from "redux-actions"
import SettingApis from "../apis/SettingApis"
import CommonApis from "../apis/CommonApis"
import { TOKEN_ID } from "config"

/* action type */
export const SET_SETTING_INFO = "@elefarm-app/setting/SET_SETTING_INFO"

/* action function */
export const setSettingInfo = createAction(SET_SETTING_INFO)

/* action api function */

// 환경설정정보
export const getSettingInfo = (param) => async (dispatch) => {
  const result = await SettingApis.getSettingInfo(param)
  if (result.code == 0) {
    dispatch(setSettingInfo(result.data.settingInfo))
    return true
  } else return false
}
// 환경설정저장
export const postSettingMod = (param) => async (dispatch) => {
  const result = await SettingApis.postSettingMod(param)
  if (result.code == 0) {

    localStorage.setItem(TOKEN_ID, result.data.accessToken)
    
    return true
  } else return false
}
// 콤보박스
export const getComboList = (param) => async (dispatch) => {
  const result = await CommonApis.getComboList(param)
  if (result.code == 0) {
    dispatch(setComboList(result.data.dataList))
    return true
  } else return false
}
