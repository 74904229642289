import { createAction } from "redux-actions"
import AlarmInfoApis from "../apis/AlarmInfoApis"
import CommonApis from "../apis/CommonApis"

/* action type */
export const INIT_INFO_USERALARM_LIST = "@elefarm-app/alarmInfo/INIT_INFO_USERALARM_LIST"
export const SET_INFO_USERALARM_LIST = "@elefarm-app/alarmInfo/SET_INFO_USERALARM_LIST"
export const SET_INFO_USERALARM_LIST_TOTAL = "@elefarm-app/alarmInfo/SET_INFO_USERALARM_LIST_TOTAL"
export const SET_INFO_USERALARM_INFO = "@elefarm-app/alarmInfo/SET_INFO_USERALARM_INFO"

/* action function */
export const setInfoUserAlarmList = createAction(SET_INFO_USERALARM_LIST)
export const initInfoUserAlarmList = createAction(INIT_INFO_USERALARM_LIST)
export const setInfoUserAlarmListTotal = createAction(SET_INFO_USERALARM_LIST_TOTAL)
export const setInfoUserAlarmInfo = createAction(SET_INFO_USERALARM_INFO)

/* action api function */

// 알림조회리스트
export const getInfoUserAlarmList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/info/userAlarm/list", param)
  if (result.code == 0) {
    dispatch(setInfoUserAlarmListTotal(result.data.totalCount))
    dispatch(setInfoUserAlarmList(result.data.dataList))
    return true
  } else return false
}
// 알림조회정보
export const getInfoUserAlarmInfo = (param) => async (dispatch) => {
  const result = await AlarmInfoApis.getInfoUserAlarmInfo(param)
  if (result.code == 0) {
    dispatch(setInfoUserAlarmInfo(result.data.userAlarmInfo))
    return true
  } else return false
}
// 알람조회 삭제(isDel을 mod해서삭제)
export const postInfoUserAlarmMod = (param) => async (dispatch) => {
  const result = await AlarmInfoApis.postInfoUserAlarmMod(param)
  if (result.code == 0) {
    return true
  } else return false
}
