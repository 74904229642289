import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { Dialog } from "@material-ui/core"

import { WidthToDP } from "../../../utils/util"

import SEO from "../../seo"
// import Header from "../../component/Header"
import Layout from "../../component/Layout"

import MainContainer from "../../../containers/MainContainer"

import icon_detail from "../../../images/icon-detail.svg"

import CommonContainer from "../../../containers/CommonContainer"

const SenserData = (props) => {
  const { t, getInfoSensorDataList, infoSensorDataList, workCenterSeq, open, close, boxFlag, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  // const { data, state } = props.location || { data: undefined }

  // const [selectSeq, setSelectSeq] = useState(-1)
  // const [select, setSelect] = useState(-1)
  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  useEffect(() => {
    getTranslationWords()
    if (open) getData(workCenterSeq)
  }, [open])

  const getData = async (workCenterSeq) => {
    await getInfoSensorDataList({ workCenterSeq })
  }
  
  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  return (
    <Dialog open={open} fullScreen>
      <Layout
        isHeader={true} // default : false
        headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
        isBack={true}
        title={dictCk("sensorData")}
        backHandler={(e) => {
          close(0)
        }}
      >
        <Container>
          <SEO title={dictCk("sensorData")} />

          <div style={{ overflow: "scroll", flex: 1, margin: `${WidthToDP(16)}px ${WidthToDP(33)}px ${WidthToDP(0)}px ${WidthToDP(33)}px` }}>
            {infoSensorDataList.map((row, idx) => (
              <div key={idx} onClick={() => (row.flag == 0 || row.flag == boxFlag ? close(row.sensorSeq) : {})}>
                <div
                  style={{
                    position: "relative",
                    marginBottom: `${WidthToDP(16)}px`,
                  }}
                >
                  <FlagItem items={row} idx={idx} boxFlag={boxFlag} />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Layout>
    </Dialog>
  )
}

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
`

export default MainContainer(CommonContainer(withTranslation()(SenserData)))

const FlagItem = (props) => {
  const { items, idx, boxFlag } = props
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: `${WidthToDP(88)}px`,
          width: `100%`,
          border: items.flag > 0 ? `${WidthToDP(1.5)}px solid #52935d` : `${WidthToDP(1.5)}px solid #e1e1e1`,
          padding: `0 ${WidthToDP(24)}px`,
          backgroundColor: items.flag > 0 ? "#edfcf0" : "#ffffff",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", width: `100%` }}>
          <section
            style={{
              padding: `${WidthToDP(3)}px ${WidthToDP(0)}px ${WidthToDP(0)}px ${WidthToDP(0)}px`,
              fontSize: `${WidthToDP(27)}px`,
              color: "#555555",
              lineHeight: `normal`,
            }}
          >
            <p style={{ textDecoration: items.flag == boxFlag ? "underline" : "none" }}>{(items.sensorNodeName+"-"+items.sensorKindName)}</p>
          </section>

          <section style={{ display: "flex" }}>
            <section style={{ fontSize: `${WidthToDP(33)}px`, color: "#022808", fontWeight: "bold", lineHeight: `normal` }}>
              {items.sensorDataFinal}
            </section>
            <section
              style={{
                padding: `${WidthToDP(9)}px ${WidthToDP(0)}px ${WidthToDP(7)}px ${WidthToDP(5)}px`,
                fontSize: `${WidthToDP(25)}px`,
                color: "#555555",
                fontWeight: "normal",
                lineHeight: `normal`,
              }}
            >
              {items.sensorUnitSeqName}
            </section>
          </section>
        </div>
        {/* <section style={{ display: "flex", justifyContent: "flex-end", width: `${WidthToDP(38)}px` }}>
          <img src={icon_detail} style={{ height: `${WidthToDP(34)}px` }}></img>
        </section> */}
      </div>
    </>
  )
}
