import { createAction } from "redux-actions"
import { TOKEN_ID } from "../config"
import SignApis from "../apis/SignApis"

/* action type */
export const IS_LOGIN = "@elefarm-app/sign/IS_LOGIN"
export const SET_USER_INFO = "@elefarm-app/sign/SET_USER_INFO"

/* action function */
export const setIsLogin = createAction(IS_LOGIN)
export const setUserInfo = createAction(SET_USER_INFO)

/* action api function */

// 로그인
export const postSignIn = (param) => async (dispatch) => {
  const result = await SignApis.postSignIn(param)
  if (result.code == 0) {
    dispatch(setIsLogin(true))
    dispatch(setUserInfo(result.data.user))

    localStorage.setItem(TOKEN_ID, result.data.accessToken)
    return true
  } else return false
}

// 로그아웃
export const postSignOut = () => async (dispatch) => {
  const result = await SignApis.postSignOut()
  if (result.code == 0) {
    dispatch(setIsLogin(false))
    // dispatch(setUserInfo({}))

    localStorage.removeItem(TOKEN_ID)

    return true
  } else return false
}

// 로그인 상태 체크
export const checkSignStatus = (param) => async (dispatch) => {
  const result = await SignApis.checkSignStatus(param)
  if (result.code == 0) {
    dispatch(setIsLogin(true))
    dispatch(setUserInfo(result.data.user))

    localStorage.setItem(TOKEN_ID, result.data.accessToken)
    return true
  } else return false
}
