import api from "../utils/api"

// 재배일지 삭제
const postCropReportDel = async (data) => {
  const result = await api.post(`/product/cropReport/del`, {
    body: data,
    token: true,
  })
  return result.data
}
// 재배일지 검색
const getCropReportSearch = async (data) => {
  const result = await api.get(`/product/cropReport/search?word=${data}`, {
    token: true,
  })
  return result.data
}

export default {
  postCropReportDel,
  getCropReportSearch,
}
