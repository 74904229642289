import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout from "../../component/Layout"
import InfiniteScrollView from "../../component/common/infiniteScrollView"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"
import { Dialog } from "@material-ui/core"

import SEO from "../../seo"

import CropContainer from "../../../containers/CropContainer"

import ASInfo from "./ASInfo"

import ICON_WRITE from "../../../images/icon-write.svg"

import CommonContainer from "../../../containers/CommonContainer"

const AS = (props) => {
  const { t, getASList, asList, asListTotal, initASList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const count = 50

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const [openInfo, setOpenInfo] = useState(false)
  const [faultSeq, setFaultSeq] = useState(false)

  const acticeOpenInfo = async (faultSeq) => {
    setOpenInfo(true)
    setFaultSeq(faultSeq)
  }

  const closeOpenInfo = async () => {
    const result = await initASList()
    if (result) {
      setOpenInfo(false)
    }
  }

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await initASList()
    }
  }, [])

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      if (openInfo) closeOpenInfo()
      else navigate("/app/main", { replace: true })
    })
    //
    getData()
  }, [openInfo])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async () => {
    if (asList == undefined || asListTotal <= 0) {
      await getASList({ page: 0, count: count })
    } else {
      await getASList({ page: Math.floor(asList.length / count), count: count })
    }
  }
  return (
    <Layout
      isHeader={true} // default : false
      isHam={true}
      headerBgType={0} // default : 0 => 0: 흰색, 1: 주황색
      title={dictCk("asManage")}
    >
      <Container>
        <SEO title={dictCk("asManage")} />

        {asList && (
          <InfiniteScrollView
            list={asList}
            listView={asList.map((item, index) => (
              <DailyData key={index} item={item} onClick={() => acticeOpenInfo(item.faultSeq)} />
            ))}
            loadFunction={() => {
              if (asListTotal > asList.length && asList.length !== 0) getData()
            }}
          />
        )}
        <div
          style={{
            position: "absolute",
            right: `${WidthToDP(40)}px`,
            bottom: `${WidthToDP(40)}px`,
          }}
        >
          <img style={{ width: `${WidthToDP(114)}px`, height: `${WidthToDP(114)}px` }} src={ICON_WRITE} onClick={() => acticeOpenInfo(0)} />
        </div>

        <Dialog open={openInfo} fullScreen>
          <ASInfo open={openInfo} close={closeOpenInfo} faultSeq={faultSeq} />
        </Dialog>
      </Container>
    </Layout>
  )
}

export default CropContainer(CommonContainer(withTranslation()(AS)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(40)}px;
  background-color: #f6f6f6;
`

const DailyData = (props) => {
  const { item, onClick } = props

  return (
    <>
      {/* 일지 시작*/}
      <div
        onClick={() => onClick(item.faultSeq)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: `${WidthToDP(640)}px`,
          // height: `${WidthToDP(240)}px`,
          padding: `${WidthToDP(40)}px ${WidthToDP(35)}px`,
          boxShadow: "0 2px 5px 1px rgba(0, 0, 0, 0.04)",
          background: "#ffffff",
          marginBottom: `${WidthToDP(16)}px`,
        }}
      >
        {/* 내용란*/}
        <div style={{ display: "flex", flexDirection: "column", width: `${WidthToDP(363)}px`, overflow: "hidden" }}>
          <div style={{ display: "flex" }}>
            <section style={{ display: "flex", marginRight: `${WidthToDP(40)}px` }}>
              <TextDate>{item.regDateTime}</TextDate>
            </section>
            <section>
              <TextStatus>{item.statusKindName}</TextStatus>
            </section>
          </div>
          <div>
            <section style={{ margin: `${WidthToDP(20)}px 0` }}>
              <TextContent>{item.faultContent}</TextContent>
            </section>
          </div>
        </div>
        {/* 그림란 */}
        <div style={{ width: `${WidthToDP(197)}px`, height: `${WidthToDP(170)}px` }}>
          {item.fileUri && item.fileUri != "" ? (
            <img src={item.fileUri} style={{ height: `${WidthToDP(197)}px`, width: `${WidthToDP(197)}px` }} />
          ) : (
            ""
          )}
        </div>
      </div>
      {/* 일지 끝*/}
    </>
  )
}

// const DefaltFont = styled.p`
//   font-size: ${WidthToDP(25)}px;
//   line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(37)}px`)};
//   font-weight: ${(props) => (props.fw ? props.fw : "#555555")};
//   color: ${(props) => (props.color ? props.color : "#555555")};
// `

const TextStatus = styled.p`
  width: ${WidthToDP(100)}px;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`

const TextDate = styled.p`
  opacity: 0.5;
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`

const TextContent = styled.p`
  font-family: NotoSansCJKkr;
  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
