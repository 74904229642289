import api from "../utils/api"

// get샘플
const getSample = async (data) => {
  const result = await api.get(`/sample?query=${data}`, {
    token: true,
  })
  return result.data
}
// post샘플
const postSample = async (data) => {
  const result = await api.post(`/sample`, {
    body: data,
    token: true,
  })
  return result.data
}

export default {
  getSample,
  postSample,
}
