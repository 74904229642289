import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout from "../../component/Layout"
import InfiniteScrollView from "../../component/common/infiniteScrollView"
import { WidthToDP, androidBackHandler } from "../../../utils/util"
import { navigate } from "@reach/router"
import { Dialog } from "@material-ui/core"

import SEO from "../../seo"
import CropContainer from "../../../containers/CropContainer"
import ICON_WRITE from "../../../images/icon-write.svg"

import DiaryAdd from "./DiaryAdd"

import CommonContainer from "../../../containers/CommonContainer"

const Diary = (props) => {
  const {
    t,
    getCropReportList,
    cropReportList,
    getCropReportInfo,
    cropReportInfo,
    initCropReportList,
    cropReportListTotal,
    initCropReportInfo,
    getCropComboList,
    cropComboList,
    getStaffComboList,
    staffComboList,
    getSMinorComboList,
    getUMinorComboList,
    sminorComboList,
    uminorComboList,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props

  const [openSearch, setOpenSearch] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const count = 50

  let dict = _translationWords[0] // 변수에 사전 담기 김한중 2023.04.03 app

  const acticeOpenInfo = async (seq = "") => {
    if (seq !== "" || seq != undefined) {
      await getCropReportInfo({ cropReportSeq: seq })
    } else {
      await initCropReportList()
    }
    setOpenAdd(true)
  }

  const getData = async () => {
    if (cropReportList == undefined || cropReportListTotal <= 0) {
      await getCropReportList({ page: 0, count: count, queryWord: inputValue })
    } else {
      await getCropReportList({ page: Math.floor(cropReportList.length / count), count: count, queryWord: inputValue })
    }
  }

  useEffect(() => {
    getTranslationWords()
    const getCropCombo = async () => {
      await getCropComboList() //작기콤보
      await getSMinorComboList() //공통콤보
      await getUMinorComboList() //사용자정의콤보
    }  
    getCropCombo()

    // 재배인력 - 차건담 2023.04.04
    const getStaffCombo = async () => {
      await getStaffComboList() //재배인력  
    }
    getStaffCombo()

    return async () => await initCropReportList()
  }, [])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const closeOpenInfo = async () => {
    const result = await initCropReportList()
    if (result) {
      setOpenAdd(false)
    }
  }

  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      if (openAdd) {
        closeOpenInfo()
      } else {
        navigate("/app/main", { replace: true })
      }
    })
    //
    getData()
  }, [openAdd])

  return (
    <Layout
      isHeader={true} // default : false
      isHam={openSearch ? false : true}
      headerBgType={0} // default : 0 => 0: white, 1: orange
      isSearch={true}
      title={openSearch ? " " : dictCk("cropReport")}
      openSearch={openSearch}
      searchHandler={() => {
        if (openSearch) {
          getData()
          setInputValue("")
        }
        setOpenSearch(!openSearch)
      }}
      inputValue={inputValue}
      setInputValue={setInputValue}
    >
      <Container>
        <SEO title={dictCk("cropReport")} />

        {cropReportList && (
          <InfiniteScrollView
            list={cropReportList}
            listView={cropReportList.map((item, index) => (
              <DailyData key={index} item={item} close={closeOpenInfo} onClick={(seq) => acticeOpenInfo(seq)} />
            ))}
            loadFunction={() => {
              if (cropReportListTotal > cropReportList.length && cropReportList.length !== 0) getData()
            }}
          />
        )}

        <div
          style={{
            position: "absolute",
            right: `${WidthToDP(40)}px`,
            bottom: `${WidthToDP(40)}px`,
          }}
        >
          <img style={{ width: `${WidthToDP(114)}px`, height: `${WidthToDP(114)}px` }} src={ICON_WRITE} onClick={() => acticeOpenInfo()} />
        </div>
        <Dialog open={openAdd} fullScreen>
          <DiaryAdd
            open={openAdd}
            close={closeOpenInfo}
            infoItem={cropReportInfo}
            cropComboList={cropComboList}
            staffComboList={staffComboList}
            uminorComboList={uminorComboList}
            sminorComboList={sminorComboList}            
          />
        </Dialog>
      </Container>
    </Layout>
  )
}

export default CropContainer(CommonContainer(withTranslation()(Diary)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${WidthToDP(40)}px;
  background-color: #f6f6f6;
`
const TextDate = styled.p`
  opacity: 0.5;

  font-size: ${WidthToDP(25)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #555555;
`
const DefaltFont = styled.p`
  font-size: ${WidthToDP(25)}px;
  line-height: ${(props) => (props.lh ? props.lh : `${WidthToDP(37)}px`)};
  font-weight: ${(props) => (props.fw ? props.fw : "#555555")};
  color: ${(props) => (props.color ? props.color : "#555555")};
`
const DailyData = (props) => {
  const { item, onClick } = props

  return (
    <>
      {/* 일지 시작*/}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: `${WidthToDP(640)}px`,
          // height: `${WidthToDP(240)}px`,
          padding: `${WidthToDP(40)}px ${WidthToDP(35)}px`,
          boxShadow: "0 2px 5px 1px rgba(0, 0, 0, 0.04)",
          background: "#ffffff",
          marginBottom: `${WidthToDP(16)}px`,
        }}
        onClick={() => {
          onClick(item.cropReportSeq)
        }}
      >
        {/* 내용란*/}
        <div style={{ display: "flex", flexDirection: "column", width: `${WidthToDP(363)}px`, overflow: "hidden" }}>
          <div style={{ display: "flex" }}>
            <section style={{ display: "flex", marginRight: `${WidthToDP(40)}px` }}>
              <TextDate>{item.cropReportDate}</TextDate>
            </section>
            <section>
              <DefaltFont fw="700">{item.cropName}</DefaltFont>
            </section>
          </div>
          <div>
            <section style={{ margin: `${WidthToDP(20)}px 0` }}>
              <DefaltFont>{item.growthStepName}</DefaltFont>
            </section>
            <section style={{ height: "100%", overflow: "auto" }}>
              {/* 작기명 */}
              <section style={{ height: `${WidthToDP(61)}px` }}>
                <DefaltFont>{`${item.cropName}`}</DefaltFont>
              </section>
              {/* 재배활동,생육단계, 이벤트 */}
              <DefaltFont>{`${item.growthStepName ? item.growthStepName : ""} ${item.cropActionName ? item.cropActionName : ""}  ${
                item.cropEventName ? item.cropEventName : ""
              }`}</DefaltFont>
              {/* 작업장 */}
              <DefaltFont>{` ${item.workCenterName ? item.workCenterName : ""}`}</DefaltFont>
              {/* 작업내용 */}
              <DefaltFont>{` ${item.work ? item.work : ""}`}</DefaltFont>
            </section>
          </div>
        </div>
        {/* 그림란 */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: `${WidthToDP(197)}px`, height: `${WidthToDP(170)}px` }}>
            {item.imageUri.length > 0 && item.imageUri[0].fileUri != "" ? (
              <img style={{ width: `${WidthToDP(197)}px`, height: `${WidthToDP(170)}px` }} src={item.imageUri[0].fileUri} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* 일지 끝*/}
    </>
  )
}
