import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Select, { components } from 'react-select'

import { WidthToDP } from "../../../utils/util"

import icon_selector from "images/selector.png"

const MultiComboBox = (props) => {
  
  const { style = {}, placeholder, items, value, setValue, width="100%" } = props

  // const [ open, setOpen ] = useState(false);
  // const [ index, setIndex ] = useState(0);
  // const [ dataList, setDataList ] = useState([]);

  const onChangeValue = ( v ) => {

    // setIndex( i )
    setValue( v )

    // console.log(777,v)

  }

  // useEffect(() => {

  //   setDataList( dataList.concat( items ) )
  //   setIndex( value > 0 ? dataList.findIndex(row => row.seq == value) : 0)

  // }, [])

  const styles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      height: `${WidthToDP(100)}px`,
      border: state.menuIsOpen ? `${WidthToDP(1)}px solid #52935d` : `${WidthToDP(1)}px solid #dedede`,
      "&:hover": {
        border: `${WidthToDP(1)}px solid #52935d`,
      },
    }),

    option: base => ({
      ...base,
      width: '100%',
      height: `${WidthToDP(100)}px`,
      textAlign: 'left',
      borderBottom: `${WidthToDP(1)}px solid #dedede`,
      fontSize: `${WidthToDP(27)}px`,
      // border: '1px solid red',
      paddingTop: `${WidthToDP(28)}px`
    }),

    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      margin:0,
    }),

    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
   }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={icon_selector} alt={""} style={{width: `${WidthToDP(50)}px`, height: `${WidthToDP(50)}px`}}/>
      </components.DropdownIndicator>
    );
  };

  return (
    <StyledDropdown width={width} style={{ ...style }} >
      
      <Select 
        placeholder={<div style={{fontSize:`${WidthToDP(30)}px`,color:"#55555566"}}>{placeholder}</div>}
        // defaultValue={dataList[0]}
        // options={dataList} 
        defaultValue={value}
        value={value}
        options={items}
        isMulti 
        // components={animatedComponents} 
        components={{ DropdownIndicator, IndicatorSeparator:() => null }}
        onChange={onChangeValue}
        noOptionsMessage={() => null}

        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: '', // 항목 선택시 
            primary25: '', // 항목 mouse over  
            primary: '', // 선택된 항목 배경 
          },
        })}

      />

    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px; 
`

export default MultiComboBox
