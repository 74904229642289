import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { getInfoUserAlarmList, getInfoUserAlarmInfo, postInfoUserAlarmMod, initInfoUserAlarmList } = actions.AlarmInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  infoUserAlarmList: state.AlarmInfoReducer.infoUserAlarmList,
  infoUserAlarmListTotal: state.AlarmInfoReducer.infoUserAlarmListTotal,
  infoUserAlarmInfo: state.AlarmInfoReducer.infoUserAlarmInfo,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initInfoUserAlarmList: (param) => dispatch(initInfoUserAlarmList(param)),
  getInfoUserAlarmList: (param) => dispatch(getInfoUserAlarmList(param)),
  getInfoUserAlarmInfo: (param) => dispatch(getInfoUserAlarmInfo(param)),
  postInfoUserAlarmMod: (param) => dispatch(postInfoUserAlarmMod(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
