// 모바일 센서데이터 화면에서 사용 중 - 차건담 2023.07.17
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Select, { components } from 'react-select'

import { WidthToDP } from "../../../utils/util"

import icon_selector from "images/downArrow.png"

const MultiComboBox = (props) => {
  
  const { style = {}, placeholder, items, value, setValue, width="100%", defaultValue='', className='' } = props

  // const [ open, setOpen ] = useState(false);
  // const [ index, setIndex ] = useState(0);
  // const [ dataList, setDataList ] = useState([]);

  const onChangeValue = ( v ) => {
    console.log("multiComboBox > value:", value)
    // setIndex( i )
    setValue( v )
    // console.log(777,v)

  }

  // useEffect(() => {

  //   setDataList( dataList.concat( items ) )
  //   setIndex( value > 0 ? dataList.findIndex(row => row.seq == value) : 0)

  // }, [])

  const styles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      height: `55px`,
      //border: `1px solid #8a8e8b`,
      borderRadius: `14px`,
      backgroundColor: `rgba(0,0,0,0)`,
      border: state.menuIsOpen ? `1px solid #1eb544` : `1px solid #8a8e8b`,
      "&:hover": {
        border: `${WidthToDP(1)}px solid #52935d`,
      },
    }),

    option: base => ({
      ...base,
      width: '100%',
      height: `55px`,
      textAlign: 'left',
      borderBottom: `1px solid #dedede`,
      fontSize: `14px`,
      // border: '1px solid red',
      paddingTop: `16px`,
    }),

    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      margin:0,
    }),

    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
   }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={icon_selector} alt={""} style={{width:"23px", height:"24px", marginRight:"10px"}}/>
      </components.DropdownIndicator>
    );
  };

  return (
    <StyledDropdown width={width} style={{ ...style }} className={className == '' ? '' : className}>
      <p style={{position:"absolute", fontSize:"14px", margin:"16px 0 0 26px", color:"#55555566"}}>{value.length > 0 ? '' : defaultValue}</p>
      <Select 
        placeholder={<div style={{fontSize:`14px`,color:"#55555566"}}>{placeholder}</div>}
        // defaultValue={dataList[0]}
        // options={dataList} 
        defaultValue={value}
        value={value}
        options={items}
        isMulti 
        // components={animatedComponents} 
        components={{ DropdownIndicator, IndicatorSeparator:() => null }}
        onChange={onChangeValue}
        noOptionsMessage={() => null}
        styles={styles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: '', // 항목 선택시 
            primary25: '', // 항목 mouse over  
            primary: '', // 선택된 항목 배경 
          },
        })}

      />

    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  width: ${(props) => props.width}; // 147px
  height: ${WidthToDP(100)}px;
`

export default MultiComboBox
