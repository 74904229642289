import api from "../utils/api"

// 알림정보
const getInfoUserAlarmInfo = async (data) => {
  const result = await api.get(`/info/userAlarm/info?userAlarmSeq=${data}`, {
    token: true,
  })
  return result.data
}
// 알람조회 삭제(isDel을 mod해서삭제)
const postInfoUserAlarmMod = async (data) => {
  const result = await api.post(`/info/userAlarm/mod`, {
    body: data,
    token: true,
  })
  return result.data
}

export default {
  getInfoUserAlarmInfo,
  postInfoUserAlarmMod,
}
