import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_INFO_USERALARM_LIST, SET_INFO_USERALARM_INFO, SET_INFO_USERALARM_LIST_TOTAL, INIT_INFO_USERALARM_LIST, init_CROP_REPORT_INFO } =
  actions.AlarmInfoAction

// default state value
const initialState = {
  infoUserAlarmList: [],
  infoUserAlarmListTotal: 0,
  infoUserAlarmInfo: [],
}

// handle action
export default handleActions(
  {
    [INIT_INFO_USERALARM_LIST]: (state, action) => ({
      ...state,
      infoUserAlarmList: [],
    }),
    [SET_INFO_USERALARM_LIST]: (state, action) => ({
      ...state,
      infoUserAlarmList: [...state.infoUserAlarmList, ...action.payload],
      // infoUserAlarmList: action.payload,
    }),
    [SET_INFO_USERALARM_LIST_TOTAL]: (state, action) => ({
      ...state,
      infoUserAlarmListTotal: action.payload,
    }),

    [SET_INFO_USERALARM_INFO]: (state, action) => ({
      ...state,
      infoUserAlarmInfo: action.payload,
    }),

    [init_CROP_REPORT_INFO]: (state, action) => ({
      ...state,
      infoUserAlarmInfo: [],
    }),
  },
  initialState
)
